import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';
import { NavLink } from 'react-router-dom';

import {
  REACT_APP_CONTACT_EMAIL,
  //REACT_APP_ADDRESS_UK,
} from './../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage>
      <Holder className="holder_1">

        <h1 className="h1">Terms</h1>
        <p className="p italic">Effective: September 1, 2022</p>

        <p className="p textPage-item"><span className="strong">Acceptance of Terms; Modifications.</span> These terms of use (the “Terms”) are a binding legal agreement between you (“you,” “End User” or “your”) and Nerdify TM. a [New Jersey] corporation ("[Nerdify],” “we,” “us,” “our” or “Company”). The Terms govern your use of the Company’s online and/or mobile services and Company applications, including but not limited to, the Company’s website and mobile apps, our social media applications, if any, or any other access point we make available to you, including any content, functionality, and services (the “Company Services”) offered on or through the Company (collectively the “Nerdify Platform”). Whether agreeing to these terms during the account sign-up process or using the Nerdify Platform without creating an account as a guest, you accept these terms.</p>
        <p className="p textPage-item">Before you start to use the Nerdify Platform, please carefully read these Terms. By using the Nerdify Platform or by clicking to accept or agree to the Terms when this option is made available to you, you accept and agree to be bound and abide by these Terms and our <NavLink to="/privacy-policy" className="a">Privacy Policy</NavLink>, which is incorporated herein by reference. If you do not want to agree to these Terms or the Privacy Policy, you must not access or use the Nerdify Platform.</p>
        <p className="p textPage-item">We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Nerdify Platform thereafter.</p>
        <p className="p textPage-item">Your continued use of the Nerdify Platform following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
        <p className="p textPage-item">THESE TERMS CONTAIN A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES BETWEEN US AND YOU, RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS.</p>
        <p className="p textPage-item">THE NERDIFY PLATFORM IS AN ONLINE MARKETPLACE THROUGH WHICH YOU SEARCH FOR FREELANCERS IN ORDER TO FULFILL CERTAIN TASKS. YOU UNDERSTAND AND AGREE THAT NERDIFY TM. IS NOT A PARTY TO ANY ENGAGEMENTS AND AGREEMENTS ENTERED INTO BETWEEN FREELANCERS AND END USERS (AS DEFINED BELOW). NERDIFY TM, HAS NO CONTROL OVER THE CONDUCT OF FREELANCERS OR END USERS OF THE NERDIFY PLATFORM OR ANY INFORMATION PROVIDED IN CONNECTION THERETO, AND DISCLAIMS ALL LIABILITY IN THIS REGARD.</p>


        <h2 className="h3 textPage-item">Eligibility.</h2>
        <p className="p textPage-item">By accessing or using the Nerdify Platform, or by clicking to accept or agree to the Terms when this option is made available, you agree to be bound by these Terms.  The Nerdify Platform is offered and available to users who are 18 years of age or older and reside in the United States or any of its territories or possessions. By using the Nerdify Platform, you represent and warrant that you are of legal age to form a binding contract with us and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.</p>


        <h2 className="h3 textPage-item">Service.</h2>
        <p className="p textPage-item"><h3 className="underline inline">Nature of the Service.</h3> The Company is a marketplace only for freelancers (“Freelancers”) and End Users and is not a Freelancer providing any services to End User as a Freelancer.  The Company provides a marketplace to allow End Users to access Freelancers to meet needs for services in connection with different tasks (“Tasks”).    The End User provides a description of the Task including the scope of work, timing and other task-specific information (a “Listing”) and the Company provides matching services to identify and offer the Task to an available Freelancer to fulfill the End User’s Task.  The Company makes no representations or warranties about the quality of any services provided by the Freelancers (“Freelancer Services”). Freelancers are not controlled by the Company, and Freelancers decide in their own discretion how to provide Freelancer Services. We may conduct an initial internal review of Freelancer’s, but the Company does not otherwise screen Freelancers and Company. You are required to use your independent judgment before engaging a Freelancer or otherwise interacting with Freelancers using the Nerdify Platform and you are solely responsible for making decisions when choosing to work with Freelancers and you may not rely on any internal screening process undertaken by Company.</p>
        <p className="p textPage-item"><h3 className="underline inline">Transactions are between End Users and Freelancers.</h3> The Nerdify Platform are used to locate Freelancer Services and to facilitate payments only.  You agree that Company has no liability for damages associated with Freelancer Services or resulting from any other transactions between End Users of the Nerdify Platform and the Freelancers.</p>
        <p className="p textPage-item"><h3 className="underline inline">Engagements.</h3> End Users and Freelancers transact business with each other on the Nerdify Platform when they mutually agree to an “Engagement” that stipulates the scope of service, fees, timing, cancellation policy, and other terms for provision of Freelancer Services via the Engagement mechanism provided on the Nerdify Platform (an “Engagement”). An Engagement is initiated by the End User by selecting submitting a Listing for a Task and then following the steps set forth on the Engagement page. If you are an End User and you initiate an Engagement, you agree to pay for the Freelancer Services described in the Engagement when you click “[Request & Pay].” All requests are subject to acceptance by the Freelancer. The Freelancer is not obligated to accept your request and may, at Freelancer’s discretion, decline for any reason.</p>

        <p className="p textPage-item"><h3 className="strong inline">Prohibited Uses:</h3> You may use the Nerdify Platform only for lawful purposes and in accordance with these Terms. You agree not to use the Nerdify Platform:</p>
        <ul className="ul">
          <li>In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
          <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.</li>
          <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing).</li>
          <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Nerdify Platform, or which, as determined by us, may harm the Company or users of the Nerdify Platform, or expose them to liability.</li>
        </ul>

        <p className="p textPage-item">Additionally, you agree not to:</p>
        <ul className="ul">
          <li>Use the Nerdify Platform in any manner that could disable, overburden, damage, or impair the site or interfere with any other party's use of the Nerdify Platform, including their ability to engage in real time activities through the Nerdify Platform.</li>
          <li>Use any robot, spider, or other automatic device, process, or means to access the Nerdify Platform for any purpose, including monitoring or copying any of the material on the Nerdify Platform.</li>
          <li>Use any manual process to monitor or copy any of the material on the Nerdify Platform, or for any other purpose not expressly authorized in these Terms, without our prior written consent.</li>
          <li>Use any device, software, or routine that interferes with the proper working of the Nerdify Platform.</li>
          <li>Introduce any viruses, Trojan horses, worms, logic bombs, or other material that is malicious or technologically harmful.</li>
          <li>Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Nerdify Platform, the server on which the Nerdify Platform are stored, or any server, computer, or database connected to the Nerdify Platform.</li>
          <li>Attack the Nerdify Platform via a denial-of-service attack or a distributed denial-of-service attack.</li>
          <li>Otherwise attempt to interfere with the proper working of the Nerdify Platform.</li>
        </ul>

        <p className="p textPage-item"><h3 className="strong inline">Suspension and Termination.</h3> You understand and agree that we have no obligation to provide the Nerdify Platform in any specific location or territory, nor to continue providing it once we have begun. We reserve the right to suspend or terminate your access to the Nerdify Platform for any reason or for no reason at all.  You may suspend or terminate, in your sole discretion, your use of the Nerdify Platform at any time and for any reason. If you wish to deactivate your account, please contact Company. Note that all outstanding payment obligations will survive suspension or termination of your account.</p>
        <p className="p textPage-item"><h3 className="strong inline">Registration; Account Security.</h3> To access the Nerdify Platform or some of the resources they offer, you may be asked to provide certain registration details or other information.  Utilizing the Nerdify Platform may require you to register by providing your name and email address, registering by logging in to third-party social media applications or registering through a Google account.  By doing so, you create your user id and password. This can be used by you at any time as long as your account is active/up to date.  At any time, the End User can modify or change or update the information on the Nerdify Platform without any prior consent.</p>
        <p className="p textPage-item">It is a condition of your use of the Nerdify Platform that all the information you provide on the Nerdify Platform are correct, current, and complete. You agree that all information you provide to register with the Nerdify Platform or otherwise, including, but not limited to, through the use of any interactive features on the Nerdify Platform, is governed by our Privacy Policy, and you consent to all actions we take with respect to your information consistent with our Privacy Policy.</p>
        <p className="p textPage-item">If you choose, or are provided with, a username, password, or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to the Nerdify Platform or portions of it using your username, password, or other security information. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
        <p className="p textPage-item">We have the right to disable any username, password, or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms.</p>
        <p className="p textPage-item"><h3 className="strong inline">Privacy.</h3> All information we collect on the Nerdify Platform are subject to our Privacy Policy. By using the Nerdify Platform, you consent to all actions taken by us with respect to your information in compliance with the Privacy Policy.</p>

        <h2 className="h3 textPage-item">Your Content.</h2>
        <p className="p textPage-item">The Nerdify Platform may contain Freelancer reviews, Listings, message boards, chat rooms, and other forums made available from third parties through interactive features (collectively, "Interactive Services") that allow End Users to post, upload, submit, publish, display or transmit information to Freelancers or other persons (hereinafter, "post") content or materials (collectively, "User Contributions") on or through the Nerdify Platform.</p>
        <p className="p textPage-item">Any End User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any End User Contribution on the Nerdify Platform, you grant us and our affiliates, Freelancers using our Nerdify Platform and other service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties and other employees of our employers using the Nerdify Platform any such material for any purpose.</p>
        <p className="p textPage-item">You represent and warrant that:</p>
        <ul className="ul">
          <li>You own or control all rights in and to the End User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns.</li>
          <li>All of your End User Contributions do and will comply with these Terms.</li>
          <li>You understand and acknowledge that you are responsible for any End User Contributions you submit or contribute, and you, not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness.</li>
          <li>We are not responsible or liable to any third party for the content or accuracy of any End User Contributions posted by you or any other user of the Nerdify Platform.</li>
        </ul>

        <h2 className="h3 textPage-item">Phone, Text and Mobile Communications.</h2>
        <p className="p textPage-item"><h3 className="underline inline">Consent to Autodialed Text Messages.</h3> This section applies only to End Users in the United States. You consent to Company communicating with you about the Nerdify Platform by SMS, text message, email and other electronic means, including autodialed text messages containing service information and/or marketing messages, even if your phone number is on the do-not-call list. Your carrier's normal messaging, data and other rates and fees will apply to these communications. You are not required to provide this consent to receive messages as a condition of purchasing anything or using the Nerdify Platform, and you may opt-out of receiving these messages at any time (though you may continue to receive messages while Company processes your Listings and Engagements).</p>
        <p className="p textPage-item"><h3 className="underline inline">Phone Number Changes.</h3> In the event you deactivate a mobile phone number provided to us, you agree to update your Company account information promptly to ensure that messages are not sent to the person who acquires your old number.</p>


        <h2 className="h3 textPage-item">Fees & Payment.</h2>
        <p className="p textPage-item"><h3 className="underline inline">Fees for End Users.</h3> End Users may purchase Freelancer Services through the Nerdify Platform by agreeing to an Engagement. If you are an End User, you enter into a transaction with the Freelancer when you accept an Engagement, and you agree to pay the total fees indicated in the Engagement. The total amount End Users are charged for an Engagement will include a service fee payable to Company. Where required by law, the amount charged will also be inclusive of applicable taxes. The Freelancer, not Company, is responsible for performing the Freelancer Services.</p>
        <p className="p textPage-item"><h3 className="underline inline">Fees for Freelancers.</h3> Freelancers may agree to provide Freelancer Services to an End User by agreeing to an Engagement however, Freelancers must confirm the Engagement before it expires, or the End User will have no obligation to complete the transaction. The purchase of Freelancer Services is a transaction between the End User and the Freelancer. Company’s role is to facilitate the transaction. We will (either directly or indirectly through an authorized third party) collect payment from the End User at the time of Engagement. Where required by law, the amount charged will also be inclusive of applicable taxes.</p>
        <p className="p textPage-item"><h3 className="underline">Cancellations & Refunds.</h3></p>
        <ul className="ul">
          <li><span className="italic">Cancellations by End User.</span> End User must provide at least 24 hours prior written notice to cancel an Engagement prior to the start time of an Engagement of any service period specified in the applicable Engagement to receive a full refund of any fees paid for the cancelled Engagement.</li>
          <li><span className="italic">Force Majeure.</span> The cancellation policies described herein may not apply in the event of certain emergency situations beyond the control of Freelancers and/or End Users that make it impossible or impractical to perform agreed Engagements, such as evacuations resulting from earthquake, hurricane, wildfire, flood, war, riots or other similar disaster. In such cases Company may, in its reasonable discretion, issue refunds under terms that vary from the cancellation policy.</li>
          <li><span className="italic">Refunds for Failure to Perform.</span> If we determine in our reasonable discretion that a Freelancer has failed to provide Freelancer Services as agreed with the End User or is otherwise in breach of these Terms, then we may, in our reasonable discretion, cancel an Engagement and/or issue a full or partial refund to an End User.</li>
          <li><span className="italic">General Terms for Cancellations.</span> If you wish to cancel an Engagement, you should use the mechanisms available through the Nerdify Platform to do so. For purposes of requesting a cancellation, the date of cancellation is the date that an End User cancels through the Nerdify Platform, regardless of any separate communications between End Users outside of the Nerdify Platform.</li>
          <li><span className="italic">Payment Disputes; Payment Outside of the Nerdify Platform.</span> Company initiates payments to Freelancers [48] hours after completion of an Engagement. Once these amounts have been disbursed, any further payment disputes are between the End User and Freelancer, and Company has no obligation to mediate or facilitate any resolution. Further, Company has no responsibility or liability with respect to any tips, bonuses, or other payments made outside of the Nerdify Platform.</li>
        </ul>

        <p className="p textPage-item"><h3 className="underline inline">Authorization to Charge.</h3> When you pay for Freelancer Services or for other services on the Nerdify Platform, you will be required to provide us with valid, up-to-date credit card or other payment information and to maintain that payment information (or an acceptable alternative payment method) on file with your account so long as you have any outstanding, confirmed Engagements. Company’s role is to facilitate payments from End Users to Freelancers as limited payment agent for the Freelancer. You authorize us to charge your credit card or other payment method for fees you incur on the Nerdify Platform as they become due and payable, and to charge any alternative payment method Company has on record for you in the event your primary payment method is expired, invalid, or otherwise not able to be charged. You are responsible for maintaining up-to-date payment information. If we cannot charge you for fees when due because your payment information is no longer valid, or if we do not receive your payment when due, then you understand that neither Company nor the Freelancer will be responsible for any failure to provide services associated with those fees. Except as expressly provided in these Terms, all fees paid via the Nerdify Platform are non-refundable once paid.</p>
        <p className="p textPage-item"><h3 className="underline inline">Taxes.</h3> You acknowledge that you are solely responsible to pay any applicable taxes that arise as a result of your purchase, provision, or use of Freelancer Services via the Nerdify Platform. This includes, without limitation, any form of sales tax on fees paid by you through the Nerdify Platform.</p>
        <p className="p textPage-item"><h3 className="strong inline">Copyright Infringement.</h3>  We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act.  If you believe that any material on the Nerdify Platform infringes upon any copyright which you own or control, you may file a DMCA Notice of Alleged Infringement with the Company at:</p>

        <div className="sub-p">
          <p className="p textPage-item">Nerdify TM<br/>
            Subject Line: DMCA Compliance<br/>
            <a className="a" href={'mailto:' + REACT_APP_CONTACT_EMAIL} target="_blank" rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>
          </p>
        </div>

        <p className="p textPage-item">Please provide the following information:</p>

        <ul className="ul">
          <li>A description of the copyrighted work you believe to have been infringed;</li>
          <li>A description of the URL or other location on our Nerdify Platform of the material you believe to be infringing;</li>
          <li>Your name, mailing address, telephone number and email address;</li>
          <li>A statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law;</li>
          <li>A statement by you, which you make under penalty of perjury, that the above information in your notice is accurate, and that you are the copyright owner or authorized to act on the copyright owner’s behalf; and</li>
          <li>An electronic or physical signature of the person authorized to act on behalf of the copyright owner.</li>
        </ul>

        <p className="p textPage-item"><h3 className="strong inline">Third Party Services, Links.</h3> If the Nerdify Platform contains links to other sites and resources provided by third parties, these links are provided for your convenience only. This includes links contained in advertisements, including banner advertisements and sponsored links. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to the Nerdify Platform, you do so entirely at your own risk and subject to the terms and conditions of use for such Apps.</p>
        <p className="p textPage-item"><h3 className="strong inline">Indemnity.</h3> TO THE MAXIMUM EXTENT PERMITTED UNDER ANY APPLICABLE LAW AND EXCEPT AS OTHERWISE PROHIBITED BY APPLICABLE LAW, YOU AGREE TO DEFEND, INDEMNIFY AND HOLD COMPANY HARMLESS FROM AND AGAINST ANY AND ALL CLAIMS, DEMANDS, CAUSES OF ACTION, LOSSES, EXPENSES, DAMAGES AND/OR LIABILITIES, INCLUDING REASONABLE ATTORNEYS’ FEES AND COURT COSTS, THAT ARE IN ANY WAY RELATED TO YOUR: (1) transactions and interactions, online or offline, with Freelancers you engaged that you discovered on the Nerdify Platform; (2) breach of these Terms; (3) disputes with Freelancers you engaged that you discovered on the Nerdify Platform; (4) your misstatements, misrepresentations, or violation of applicable law; or (5) your Content. YOU FURTHER AGREE THAT YOU WILL COOPERATE WITH US IN THE DEFENSE OF SUCH CLAIMS. WE RESERVE THE RIGHT TO ASSUME THE EXCLUSIVE DEFENSE AND CONTROL OF ANY MATTER SUBJECT TO INDEMNIFICATION UNDER THIS SECTION, AND YOU WILL NOT SETTLE ANY SUCH CLAIM OR MATTER WITHOUT OUR ADVANCE WRITTEN CONSENT.</p>
        <h2 className="h3 textPage-item">Intellectual Property.</h2>
        <p className="p textPage-item"><h3 className="underline inline">Nerdify Platform.</h3> The Nerdify Platform and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
        <p className="p textPage-item"><h3 className="underline inline">Company Trademarks.</h3> Company owns all rights in and to its trademarks, service marks, brand names and logos (“Company Marks”). End User shall not use the Company Marks or any of its affiliates or otherwise, directly or indirectly, refer to Company Marks or any of its affiliates in any publicity release, promotional material, customer or partner list, advertising, marketing or business-generating effort, whether written or oral, without the prior written consent of the Company.</p>
        <p className="p textPage-item"><h3 className="strong inline">Warranty Disclaimer for the Nerdify Platform.</h3> You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</p>
        <p className="p textPage-item">YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
        <p className="p textPage-item">TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
        <p className="p textPage-item">THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>




        <h2 className="h3 textPage-item">Limitation of Liability.</h2>
        <p className="p textPage-item"><h3 className="underline inline">Exclusion of Certain Types of Damages.</h3> To the maximum extent permitted under applicable law, in no event will Company be liable to you for any indirect, special, incidental, or consequential damages, or for any business losses, or loss of profit, revenue, contracts, data, goodwill or other similar losses or expenses that arise out of or relate to the use of or inability to use the Nerdify Platform, including without limitation damages related to any information received from the Nerdify Platform, removal of your profile information or review (or other content) from the Nerdify Platform, any suspension or termination of your access to the Nerdify Platform, or any failure, error, omission, interruption, defect, delay in operation or transmission of the Nerdify Platform, even if we are aware of the possibility of any such damages, losses or expenses. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
        <p className="p textPage-item"><h3 className="underline inline">Limit on Our Liability to You.</h3> EXCEPT WHERE PROHIBITED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY’S AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY IN ANY MATTER ARISING FROM OR RELATING TO THE COMPANY SERVICE OR THESE TERMS EXCEED THE AMOUNTS PAID BY YOU TO COMPANY (SPECIFICALLY EXCLUDING AMOUNTS PAID TO FREELANCERS) DURING THE TWELVE (12) MONTHS PRECEDING THE EVENT THAT GAVE RISE TO LIABLITY OR, IF YOU HAVE NOT PAID COMPANY FOR THE USE OF ANY SERVICES, THE AMOUNT OF $100.00 USD (OR EQUIVALENT IN LOCAL CURRENCY).</p>
        <p className="p textPage-item"><h3 className="underline inline">No Liability for non-Company Actions.</h3> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, AND/OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE COMPANY APPS, INCLUDING WITHOUT LIMITATION, BODILY INJURY, DEATH, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES RESULTING FROM RELIANCE ON INFORMATION OR CONTENT POSTED ON OR TRANSMITTED THROUGH THE COMPANY APPS, OR FOR ANY INTERACTIONS WITH OTHER USERS OF THE COMPANY APPS, WHETHER ONLINE OR OFFLINE. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF USERS OR FREELANCERS WHO ATTEMPT TO DEFRAUD OR HARM YOU.</p>
        <p className="p textPage-item">IF YOU HAVE A DISPUTE WITH A FREELANCER, YOU AGREE TO RELEASE COMPANY FROM ALL CLAIMS, DEMANDS AND DAMAGES OF EVERY NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.  IN NO EVENT WILL COMPANY BE LIABLE FOR DIRECT OR INDIRECT CONSEQUENCES OF AN END USER OR FREELANCER FAILING TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.</p>

        <h2 className="h3 textPage-item underline">Geographic Restrictions</h2>
        <p className="p textPage-item">The owner of the Nerdify Platform is based in the State of New Jersey in the United States. We make no claims that the Platform or any of its content is accessible or appropriate outside of the United States. Access to the Platform may not be legal by certain persons or in certain countries. If you access the Platform from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>

        <p className="p textPage-item"><h3 className="strong inline">Dispute Resolution.</h3> <span className="italic">Please read this Arbitration Agreement carefully.  It is part of your contract with Company and affects your rights.  It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.</span></p>

        <div className="sub-p">

          <p className="p textPage-item"><span className="underline">Applicability of Arbitration Agreement.</span> All claims and disputes (excluding claims for injunctive or other equitable relief as set forth below) in connection with the Terms or the use of the Nerdify Platform that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement.  Unless otherwise agreed to, all arbitration proceedings shall be held in English.  This Arbitration Agreement applies to you and Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns.</p>

          <p className="p textPage-item"><span className="underline">Notice Requirement and Informal Dispute Resolution.</span> Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute (“Notice”) describing the nature and basis of the claim or dispute, and the requested relief.  A Notice to Company should be emailed to <a className="a" href={'mailto:' + REACT_APP_CONTACT_EMAIL} target="_blank" rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>. After the Notice is received, you and Company may attempt to resolve the claim or dispute informally.  If you and Company do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.  The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.</p>

          <p className="p textPage-item"><span className="underline">Arbitration Rules.</span> Arbitration shall be initiated through the American Arbitration Association (“AAA”), an established alternative dispute resolution provider (“ADR Provider”) that offers arbitration as set forth in this section.  If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider.  The rules of the ADR Provider shall govern all aspects of the arbitration, including but not limited to the method of initiating and/or demanding arbitration, except to the extent such rules are in conflict with the Terms. The arbitration shall be conducted by a single, neutral arbitrator.  Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief.  For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules.  Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.</p>

          <p className="p textPage-item"><span className="underline">Additional Rules for Non-Appearance Based Arbitration.</span> If non-appearance-based arbitration is elected, the arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration.  The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.</p>

          <p className="p textPage-item"><span className="underline">Time Limits.</span> If you or Company pursue arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations (i.e., the legal deadline for filing a claim) and within any deadline imposed under the AAA Rules for the pertinent claim.</p>

          <p className="p textPage-item"><span className="underline">Authority of Arbitrator.</span> If arbitration is initiated, the arbitrator will decide the rights and liabilities, if any, of you and Company, and the dispute will not be consolidated with any other matters or joined with any other cases or parties.  The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.  The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms.  The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.  The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.  The award of the arbitrator is final and binding upon you and Company.</p>

          <p className="p textPage-item"><span className="underline">Waiver of Jury Trial.</span> THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement.  Arbitration procedures are typically more limited, more efficient and less costly than rules applicable in a court and are subject to very limited review by a court.  In the event any litigation should arise between you and Company in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND GEXPR NOW WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.</p>

          <p className="p textPage-item"><span className="underline">Waiver of Class or Consolidated Actions.</span> ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER USER.</p>

          <p className="p textPage-item"><span className="underline">Confidentiality.</span> All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance therewith, shall be strictly confidential.  The parties agree to maintain confidentiality unless otherwise required by law.  This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.</p>

          <p className="p textPage-item"><span className="underline">Severability.</span> If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.</p>

          <p className="p textPage-item"><span className="underline">Right to Waive.</span> Any or all of the rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted.  Such waiver shall not waive or affect any other portion of this Arbitration Agreement.</p>

          <p className="p textPage-item"><span className="underline">Survival of Agreement.</span> This Arbitration Agreement will survive the termination of your relationship with Company.</p>

          <p className="p textPage-item"><span className="underline">Emergency Equitable Relief.</span> Notwithstanding the foregoing, either party may seek emergency equitable relief before a state or federal court in order to maintain the status quo pending arbitration.  A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.</p>

          <p className="p textPage-item"><span className="underline">Courts.</span> In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within New York City, New York, for such purpose.</p>
        </div>


        <h2 className="h3 textPage-item underline">Miscellaneous</h2>
        <p className="p textPage-item">The invalidity, illegality or unenforceability of any term or provision of this Agreement shall in no way affect the validity, legality or enforceability of any other term or provision of this Agreement. In the event a term or provision is determined to be invalid or unenforceable, the parties agree to replace such term or provision with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the invalid or unenforceable term or provision, and this Agreement shall be enforceable as so modified.</p>
        <p className="p textPage-item">The waiver by either party of a breach of or a default under any provision of this Agreement shall not be effective unless in writing and shall not be construed as a waiver of any subsequent breach of or default under the same or any other provision of this Agreement, nor shall any delay or omission on the part of either party to exercise or avail itself of any right or remedy that it has or may have hereunder operate as a waiver of any right or remedy.</p>
        <p className="p textPage-item">This Agreement constitutes the entire agreement between Company and you and supersedes any prior verbal or written agreements regarding the subject matter hereof.</p>
        <br/>
        <p className="p textPage-item">If you have any questions regarding the NERDIFY Platform, or any related Services, please contact us at <a className="a" href={'mailto:' + REACT_APP_CONTACT_EMAIL} target="_blank" rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>. For information about how we collect, use, share and otherwise process information about you, please see our <NavLink to="/privacy-policy" className="a">Privacy Policy</NavLink>.</p>

      </Holder>
    </TextPage>
  </LocalesPage>
);
