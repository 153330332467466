import React from 'react';
// import { Link } from 'react-router-dom';

import MathonLpHeader from '../blocks/MathonLpHeader';
import MathonLpFooter from '../blocks/MathonLpFooter';

import '../content.css';

const MathonPrivacyPolicy = () => {
  return (
    <>
      <MathonLpHeader />
      <div className="mathon-content">

        <h1 className="mathon-h1">Privacy Policy</h1>

        <h2 className="mathon-h2">What information does MathOn collect if I decide to use the MathOn Online Math School services?</h2>
        <p className="mathon-p">MathOn is an online math school designed to connect students with experienced math tutors. To facilitate this connection and ensure a seamless tutoring experience, when a parent visits our website, they are prompted to input their phone number. Subsequently, an academic personal assistant reaches out and gathers information about the child in need of tutoring. This process helps us understand the student's needs and match them with an appropriate math tutor.</p>
        <p className="mathon-p">To join MathOn and utilize our tutoring services, you will be required to provide certain information such as the student's name, your email address, phone number, and any other relevant details. This data collection ensures that the tutoring services are tailored to the student's requirements and allows our academic personal assistants to manage schedules, grant parents access to the student dashboard, and send periodic updates regarding the student’s progress.</p>
        <p className="mathon-p">Registration information: When you sign up for MathOn’s services, we require details like the student's name, parent’s email address, and phone number.</p>
        <p className="mathon-p">Other information: As part of our commitment to providing a comprehensive tutoring service, we may also request or collect additional information related to the student's academic needs, preferred learning styles, and past academic performances.</p>

        <h2 className="mathon-h2">Does MathOn collect my personal information if I am not a member and just visit the site?</h2>
        <p className="mathon-p">No. We don’t collect personal information about site visitors passively. However, with your consent, we might place session ID cookies on your computer for better site functionality.</p>
        <p className="mathon-p">Other information we receive about you:</p>
        <p className="mathon-p">Occasionally, we might receive feedback, additional data, or testimonials from satisfied customers. This data helps us refine and enhance our services, improve the user experience, and ensure the effectiveness of our matching process between students and tutors. We always ensure any data shared with potential partners or advertising platforms is anonymized to maintain your privacy.</p>
        <p className="mathon-p">Your data's protection is our priority. Any data we share with our partners or third-party entities is either anonymized or aggregated to safeguard your identity and privacy.</p>

        <h2 className="mathon-h2">What does MathOn do with the information it receives?</h2>
        <p className="mathon-p">The information we gather primarily serves to improve our tutoring services, ensuring students are matched with suitable tutors, facilitating efficient scheduling, and enabling transparent communication between parents, students, and tutors.</p>

        <h2 className="mathon-h2">Cookies, Pixels, and other technologies we use:</h2>
        <p className="mathon-p">We employ technologies like cookies, pixels, and local storage to provide a smoother browsing experience, understand user preferences, and continuously enhance our platform's functionality.</p>

        <h2 className="mathon-h2">Does MathOn disclose my information to other parties?</h2>
        <p className="mathon-p">We might share aggregated, non-identifying data with third parties for analysis, quality assurance, or service improvement purposes. However, we strictly limit the sharing of personally identifiable information and always prioritize our users' privacy.</p>
        <p className="mathon-p">MathOn is committed to maintaining a secure and respectful platform. We will cooperate with all law enforcement inquiries and third parties to protect rights and ensure user safety. We may disclose personal information when legally compelled or when we believe it’s necessary to protect our users' rights and safety.</p>

      </div>
      <MathonLpFooter />
    </>
  );
};

export default MathonPrivacyPolicy;
