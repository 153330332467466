import React, {Component} from 'react';
import classnames from "classnames";
import './index.css';

export const BetterWayImg = ({img,alt,className}) =>
  <div className="better_way__img_wrapper">
    <img className={classnames('better_way__img', className)} src={require(`./img/${img}`)} alt={alt} />
  </div>

export default class BetterWay extends Component {
  render () {
    const {className, children} = this.props;

    return (
      <div className={classnames('better_way', className)}>
        <div className='holder'>
          {children}
        </div>
      </div>
    )
  }
}
