import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import './index.css';

import {
  REACT_APP_CONTACT_EMAIL,
} from '../../../../config';

export default (props) => (
  <LocalesPage {...props}>
	  <TextPage>
		<Holder className="holder_1">

			<h1 className="h1">Cookie Policy</h1>
      <p className='h6'>We and our affiliates, third parties, and other partners use cookies and other identification technologies on our websites, mobile applications, electronic communications, advertisements, and other online services (collectively, the "Services") for a number of purposes, including: authenticating users, remembering user preferences and settings, determining the popularity of content, delivering personalized and more relevant advertisements, measuring the effectiveness of advertising campaigns, analyzing website traffic and trends, and generally understanding the online behaviors and interests of people who interact with our Services. You can read more here about the types of cookies we use, why we use them, and how you can exercise your choices.</p>

      <div className="textPage-item">
        <h2 className='h3'>Cookies Overview</h2>
        <p className="p">Cookies are small bits of data that are stored on your browser or device (personal computer, tablet, mobile phone etc.) that are used to access websites in order to recognize repeat users. There are different types of cookies we use depending on their purpose. Cookies served by the entity that operates the domain you are visiting are called “first-party cookies.” Nerdify uses exactly this type while you are on https://nerdifyit.com. Cookies served by companies that are not operating the domain you’re visiting are called “third-party cookies.” It happens, for example, when you allow Google to set a cookie on your browser while you visit https://nerdifyit.com, so it is performed by a server located outside the domain of our website. Cookies may also endure for different periods of time. “Session cookies” are temporary and are deleted automatically upon closing the browser. The last type is “persistent cookie” which survives after your browser is closed, but can be deleted manually. They may recognize your device when you reopen your browser and browse the internet again.</p>
      </div>

      <div className="textPage-item">
        <h2 className='h3'>Other Identification Technologies</h2>
        <p className="p">We call this a Cookie Policy, but the statement addresses cookies and other identification technologies we may use or permit on our Services. Among other identification technologies we use are pixel tags, local storage, and software development kits. </p>
        <p className="p">“Pixel tags” (also known as pixels or beacons) are small blocks of code installed on (or called by) a webpage, app, or ad which can access certain information about your device and browser, for example: device type, operating system, browser type and version, website visited, time of visit, referring website, IP address, and other similar information, including the cookie that identifies the device. Pixels provide the means by which third parties can set and read browser cookies from a domain that they do not themselves operate and collect information about visitors of that domain, typically with the permission of the domain owner.</p>
        <p className="p">“Local storage” refers generally to other places on a browser or device where information can be stored by websites, ads, or the third parties (such as HTML5 local storage and browser cache). </p>
        <p className="p">“Software Development Kits” (also called SDKs) function like pixels and cookies, but operate in the mobile app context where pixels and cookies cannot always function. The primary app developer can install pieces of code (the SDK) from partners in the app, and in such a way allows the partner to acquire certain information about user interaction with the app, user device, and network.</p>
      </div>

      <div className="textPage-item">
        <h2 className='h3'>Your Choices</h2>
        <p className="p">You, as a user, have the right to choose whether to accept cookies or not. However, they are an important part of how our Services work. You should be aware that a refusal to install or removal of cookies may affect the availability and functionality of the Services.</p>
        <p className="p">Most web browsers are set to accept cookies by default. If you prefer, you can change the settings so that the browser removes or rejects cookies. Please, visit the "Help" or “Preferences” menu of your browser for more information on how to manage this option. Some third parties also provide the ability to reject their cookies directly by clicking on an opt-out link. We have indicated in which cases this is possible in the table below.</p>
        <p className="p">Removing or rejecting browser cookies does not necessarily affect the third-party flash cookies which may be used by us or our partners in connection with our Services. You can get more information on cookies, check what cookies have been set on your device and how to manage them, here: <a className='a' href="http://allaboutcookies.org" target="_blank" rel="noopener noreferrer">www.allaboutcookies.org</a> or <a className='a' href="http://youronlinechoices.eu" target="_blank" rel="noopener noreferrer">www.youronlinechoices.eu</a>. If you want to opt out of Google's use of cookies or device identifiers, please visit <a className='a' href="https://adssettings.google.com/" target="_blank" rel="noopener noreferrer">Google's Ads Settings</a>.</p>
      </div>

      <div className="textPage-item">
        <h2 className='h3'>Types and Purposes of Cookies</h2>
        <p className="p">The following table presents different kinds of cookies that our Services use and explains why we use them.</p>
      </div>

      <div className="textPage-item p">
        <table className='table'>

          <thead>
          <tr>
            <th width="17%">Type of Cookie</th>
            <th>Purpose</th>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td data-label="Type of Cookie">Essentials</td>
            <td data-label="Purpose">Provide functionality that help us deliver products and Services. For example, cookies may store login information that helps users enter the website easily. We may also use cookies and similar technologies to help us provide you with social plugins, other customized content and experiences, such as making suggestions to you and others.</td>
          </tr>
          <tr>
            <td data-label="Type of Cookie">Marketing</td>
            <td data-label="Purpose">Cookies and pixels are used to provide relevant ads, track ad campaign performance and the effectiveness of our marketing efforts. For example, we and our ad partners may rely on information collected via these cookies to show you ads that may interest you on other websites. Similarly, our partners may use cookies, attribution service or another similar technology to determine whether we’ve served an ad and how it performed.</td>
          </tr>
          <tr>
            <td data-label="Type of Cookie">Performance</td>
            <td data-label="Purpose">Help research, understand and improve products and Services. For example, when you access the Nerdify website or other related websites and apps from a computer or a mobile device. We may use cookies to understand your user experience on our platform. The collected data are used to improve and understand how you use websites, apps, products, services and ads.</td>
          </tr>
          <tr>
            <td data-label="Type of Cookie">Functional</td>
            <td data-label="Purpose">These cookies and similar technologies remember choices you make such as language or search parameters. We use these cookies to provide you with an experience more appropriate with your selections and to make your use of the Services more tailored.</td>
          </tr>
          </tbody>

        </table>
      </div>

      <div className="textPage-item">
      <h2 className='h3'>Contact us</h2>
        <p className="p">If you have any questions about our cookie policy, please, contact us at <a className='a' href={'mailto:' + REACT_APP_CONTACT_EMAIL} target='_blank' rel='noopener noreferrer'>{REACT_APP_CONTACT_EMAIL}</a>. We are always glad to improve your user experience on our website.</p>
      </div>
		</Holder>
	</TextPage>
  </LocalesPage>
)
