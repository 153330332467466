import React, {useRef, useState, useEffect} from 'react';
import Slider from 'react-slick';
import { get } from 'lodash';

import classnames from 'classnames';
import {NavLink} from "react-router-dom";
import LazyLoad from 'react-lazyload';
import { useMobile } from '../../../../hooks';

import './sliderReviews.css';
import imgRecommendActive from "./img/recommend-active.png";
import imgRecommend from "./img/recommend.png";
import imgEarth from "./img/earth.png";

const Collapsable = ({trigger, children, isExpanded, visibleLines = 0, onShow}) => {
  const [containerMaxPossibleHeight, setContainerMaxPossibleHeight] = useState(null); //height visibleLines
  const [containerCollapsedHeight, setContainerCollapsedHeight] = useState(null); //height visibleLines-1
  const [containerExpandedHeight, setContainerExpandedHeight] = useState(null);

  const [containerCollapsedStyle, setContainerCollapsedStyle] = useState({});
  const containerRef = useRef(null);

  function getHeightOfFirstElementOrLetter(el) {
    const firstChild = el.firstChild;

    if (firstChild.nodeType === Node.TEXT_NODE) {
      const lineHeight = getComputedStyle(el).lineHeight;
      const lineHeightValue = parseFloat(lineHeight);
      return lineHeightValue;
    } else {
      return firstChild.offsetHeight + 8; //margin:4px for each inline-block element
    }
  }

  useEffect(() => {
    const container = containerRef.current;
    setContainerCollapsedHeight(getHeightOfFirstElementOrLetter(container) * (visibleLines - 1));
    setContainerMaxPossibleHeight(getHeightOfFirstElementOrLetter(container) * visibleLines);
    setContainerExpandedHeight(container.clientHeight);

    setContainerCollapsedStyle({
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: visibleLines - 1,
    })
  }, []);

  useEffect(() => {
    //setIsOutOfBounds(containerExpandedHeight > containerMaxPossibleHeight);
    if (!isExpanded) {
      setTimeout(() => {
        setContainerCollapsedStyle({
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: visibleLines - 1,
        })
      }, 500);
    } else {
      setContainerCollapsedStyle({})
    }
  }, [isExpanded]);

  return <div className="collapsable">
    <div className={"collapsable_wrapper"}
         style={{ maxHeight: containerExpandedHeight > containerMaxPossibleHeight ? (isExpanded ? containerExpandedHeight : containerCollapsedHeight) : 'none'}}
    >
      <div className={"collapsable_inner"} ref={containerRef}
           style={containerExpandedHeight > containerMaxPossibleHeight ? containerCollapsedStyle: {}}
      >
        {children}
      </div>
    </div>

    {containerExpandedHeight > containerMaxPossibleHeight &&
    <button
      className="collapsable_trigger"
      onClick={onShow}
    >
      {isExpanded ? trigger[1] : trigger[0]}
    </button>
    }
  </div>
};


function InnerNextArrow(props) {
  const { onClick } = props;
  return (
    <button className="inner-arrow next" onClick={onClick}>
      <img width={'48'} height={'48'}
        className={'inner-arrow-img'}
        src={require(`./img/arrow-next.svg`)}
        alt=""/>
    </button>
  );
}

function InnerPrevArrow(props) {
  const { onClick } = props;
  return (
    <button className="inner-arrow prev" onClick={onClick}>
      <img width={'48'} height={'48'}
        className={'inner-arrow-img'}
        src={require(`./img/arrow-prev.svg`)}
        alt=""/>
    </button>
  );
}

const SliderReviewsMobile = (props) => {
  const [expandedItemId, setExpandedItemId] = useState();
  //const []
  const handleShow = id => {
    if (id === expandedItemId) {
      setExpandedItemId(); // show less
    } else {
      setExpandedItemId(id);  // show more
    }
  };

  const isMobile = useMobile();
  const {items} = props;
  const keys = Object.keys(items);
  const activeItem = get(props, 'pageCfg.reviewActiveTab', undefined) || props.activeItem || 'Facebook';
  const activeIndex = keys.findIndex(item => item === activeItem);
  const itemsKeys = Object.keys(items);

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  return (
    <Slider
      className={'main-slider'}
      useTransform={false}
      infinite={false}
      fade={true}
      //adaptiveHeight={true}
      slidesToShow={1}
      slidesToScroll={1}
      swipe={false}
      arrows={false}
      afterChange={() => setExpandedItemId(undefined)}  /*(current, next)*/
      initialSlide={activeIndex}
      dots={true}
      dotsClass={'main-switch-buttons holder'}
      customPaging={(i) => {
        const switchBtnImg =
          itemsKeys[i] === "Trustpilot" ? 'trustpilot.svg'
            : itemsKeys[i] === "Google" ? isMobile ? 'google.svg' : 'google.png'
            : itemsKeys[i] === "Facebook" ? 'fb.svg'
              : 'sitejabber.svg';
        return (
          <div className="main-switch-btn">
            <LazyLoad>
              <img key={itemsKeys[i]}
                   className={classnames("main-switch-btn-img", itemsKeys[i].toLowerCase())}
                   src={require(`./img/logo/${isMobile ? 'mobile':'desktop'}/${switchBtnImg}`)}
                   alt={itemsKeys[i]}
              />
            </LazyLoad>
          </div>
        )
      }}
    >
      {itemsKeys.map((i) => {
        return (
          <div key={`list-item-holder-${i}`}>
            <Slider variableWidth
                    className={'inner-slider'}
                    useTransform={false}
                    centerMode={true}
                    centerPadding={0}
                    infinite={true}
                    slidesToShow={1}
                    slidesToScroll={1}
                    dots={true}
                    dotsClass={'inner-switch-buttons'}
                    customPaging={(i) => {
                      return (
                        <button className="inner-switch-btn" />
                      )
                    }}
                    arrows={!isMobile}
                    nextArrow={<InnerNextArrow />}
                    prevArrow={<InnerPrevArrow />}
                    beforeChange={() => setExpandedItemId(undefined)}  /*(current, next)*/
            >
              {items[i].map((item, index) => {
                return (
                  <div className="slider-item-wrapper" key={`slider-item-wrapper-${i}`}>

                    <div className={classnames('slider-item',`slider-item_${i.toLowerCase()}`)}>

                      {i === 'Trustpilot' ?
                      <>

                        <div className="item-top">
                          <div className={`item-avatar type${index}`}>{item.name.charAt(0)}</div>

                          <div className="item-info">
                            <div className="item-name-row">
                              <div className="item-name">{item.name}</div>
                              <div className="item-date">{item.date}</div>
                            </div>

                            <img
                              className="mark-icon"
                              src={require('./img/5-stars.png')}
                              alt={item.name}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="review-title">{item.title}</div>
                          <Collapsable
                            trigger={['Show more', 'Show less']}
                            visibleLines={isMobile ? 6 : 3}
                            onShow={() => handleShow(`item${i}${index}`)} // e.g. itemTrustpilot0
                            isExpanded={expandedItemId === `item${i}${index}`}
                          >
                            {item.review}
                          </Collapsable>
                        </div>
                      </>

/*
                      : i === 'Google' ?
                      <>

                        <div className="item-top">
                          <div className={`item-avatar type${getRandomInt(9)}`}>{item.name.charAt(0)}</div>

                          <div className="item-info">
                            <div className="item-name">{item.name}</div>

                            <div className="item-date-row">
                              <img
                                className="mark-icon"
                                src={require('./img/google-mark-5.svg')}
                                alt={item.name}
                              />
                              <div className="item-date">{item.date}</div>
                            </div>

                          </div>
                        </div>

                        <div>
                          <Collapsable
                            trigger={['Show more', 'Show less']}
                            visibleLines={isMobile ? 7 : 4}
                            onShow={() => handleShow(`item${i}${index}`)}
                            isExpanded={expandedItemId === `item${i}${index}`}
                          >
                            {item.review}
                          </Collapsable>
                        </div>
                      </>
*/

                      : i === 'Facebook' ?
                        <>
                          <div className="item-top">
                            <LazyLoad height={47}>
                              <img
                                className="item-avatar"
                                src={require(`./img/avatar/facebook/${item.avatar}`)}
                                alt={item.name}
                              />
                            </LazyLoad>
                            <div className="item-info">
                              <div className="item-name-row">
                                <span className="item-name strong">{item.name}</span>
                                <img
                                  src={item.recommends ? imgRecommendActive : imgRecommend}
                                  className="item-icon1"
                                  alt=""
                                />
                                <span className="item-name ellipsis-line">
                {item.recommends ? 'recommends' : "doesn't recommend"}{' '}
                                  <span className="strong">Nerdify</span>
              </span>
                              </div>
                              <div className="item-date">
                                {item.date}
                                {' • '}
                                <img src={imgEarth} className="item-icon2" alt=""/>
                              </div>
                            </div>
                          </div>

                          <Collapsable
                            trigger={['Show more', 'Show less']}
                            visibleLines={isMobile ? 7 : 4}
                            onShow={() => handleShow(`item${i}${index}`)}
                            isExpanded={expandedItemId === `item${i}${index}`}
                          >
                            {item.review}
                          </Collapsable>
                        </>


                      : i === 'Sitejabber' ?
                            <>

                              <div className="item-top">
                                <LazyLoad height={47}>
                                  <img
                                    className="item-avatar"
                                    src={require(`./img/avatar/sitejabber/${item.avatar}`)}
                                    alt={item.name}
                                  />
                                </LazyLoad>

                                <div className="item-info">
                                  <div className="item-name-row">
                                    <div className="item-name">{item.name}</div>
                                    <div className="item-date">{item.date}</div>
                                  </div>

                                  <img
                                    className="mark-icon"
                                    src={require('./img/sitejabber-mark-5.svg')}
                                    alt={item.name}
                                  />
                                </div>
                              </div>

                              <div>
                                <div className="review-title">{item.title}</div>
                                <Collapsable
                                  trigger={['Show more', 'Show less']}
                                  visibleLines={isMobile ? 6 : 3}
                                  onShow={() => handleShow(`item${i}${index}`)} // e.g. itemTrustpilot0
                                  isExpanded={expandedItemId === `item${i}${index}`}
                                >
                                  {item.review}
                                </Collapsable>
                              </div>
                            </>
                            : <></>
                      }

                      <div className="verified-message">
                        <img
                          className="verified-icon"
                          src={require('./img/i-check.svg')}
                          alt={item.name}
                        />
                        Verified user
                      </div>

                    </div>


                  </div>
                )
              })}
            </Slider>
          </div>
        )
      })}
    </Slider>
  )
};


const SliderReviews = (props) => {
  const isMobile = useMobile();
  const { titleTag, title, theme, indent } = props;
  const TitleTag = titleTag ? titleTag : 'div';
  return (
    <div className={classnames('slider-reviews', {[`slider-reviews_${theme}`]: theme}, indent)}>
      <div className='holder'>
        <TitleTag className="h3 title">{title || <>Check out the latest reviews from verified users</>}</TitleTag>
      </div>
      {isMobile ? SliderReviewsMobile(props)
        : (
          <div className="slick-static">
            {SliderReviewsMobile(props)}
          </div>
        )}

      <div className="holder read-more-line">
        <NavLink to="/nerdify-reviews" className="read-more-link">
          Read more reviews
          <img
            className="open-in-new-icon"
            src={require('./img/i-open-in-new.svg')}
            alt={''}
          />
        </NavLink>
      </div>
    </div>
  )
};

export default SliderReviews;
