import * as actionTypes from './../actionTypes';
import { localesData } from '../../utils/locales';
import { getUserIpInfo } from './../../api';
import * as utils from '../../utils';
import moment from 'moment-timezone';
import _ from 'lodash';
//import { useLocation } from 'react-router-dom';
import { parseQuery } from '../../utils';

import {
  checkLeadExistUrl,
  get,
  post,
  patch,
  validatePhoneUrl,
  updateUserUrl,
  createLeadUrl,
  getUserDataUrl,
  makeFormData,
  sentTextBackUrl,
  healthCheckUrl,
} from './../../api';

import {
  REACT_APP_SITE_ID,
  //REACT_APP_SMS_TOOL_NAME,
  REACT_APP_CHANGE_LOCALE,
  REACT_APP_LOCALES,
} from '../../config';
import { useLocalesModal } from '../../base_components/Locales';

export const setLocaleData =
  ({ locale, defaultPhone, landingGuid }) =>
  (dispatch) => {
    if (defaultPhone) {
      const phoneLocale = Object.keys(defaultPhone)[0];
      localesData[phoneLocale]['phone'] = defaultPhone[phoneLocale];
      localesData[phoneLocale]['phoneText'] = defaultPhone[phoneLocale];
    }
    if (landingGuid) {
      const landingLocale = Object.keys(landingGuid)[0]; //us, ca
      localesData[landingLocale]['landingGuid'] = landingGuid[landingLocale];
    }
    dispatch({
      type: actionTypes.SET_LOCALE_DATA,
      data: localesData[locale],
    });
  };

export const initLocale =
  ({ locale, defaultPhone, landingGuid }) =>
  (dispatch) => {
    let currentLocale = locale;
    const storageLocale = localStorage.getItem('locale');

    if (!storageLocale || !REACT_APP_LOCALES.includes(storageLocale)) {
      //no saved locale in the storage or the saved locale is no longer supported
      if (REACT_APP_CHANGE_LOCALE === 'geoip_silent') {
        getUserIpInfo()
          .then((resp) => {
            //console.log("getUserIpInfo resp:",resp)
            const countryCode = resp.country_code.toLowerCase();
            if (Object.keys(localesData).indexOf(countryCode) !== -1) {
              //if the country code (determined by the ip from which the page was loaded) matches the list of site countries
              currentLocale = countryCode;
            }
            dispatch(
              setLocale({
                locale: currentLocale,
                defaultPhone,
                landingGuid,
              })
            );
          })
          .catch((error) => error);
      } else {
        dispatch(
          setLocale({
            locale: currentLocale,
            defaultPhone,
            landingGuid,
          })
        );
        if (REACT_APP_CHANGE_LOCALE === 'geoip_modal') {
          dispatch(getUser(locale));
        }
      }
    } else {
      // есть сохраненная локаль в сторедж
      currentLocale = storageLocale;
      dispatch(
        setLocale({
          locale: currentLocale,
          defaultPhone,
          landingGuid,
        })
      );
    }
  };

export const setLocale =
  ({ locale, defaultPhone, landingGuid }) =>
  (dispatch) => {
    if (!locale) {
      return false;
    }

    localStorage.setItem('locale', locale);
    dispatch({ type: actionTypes.SET_LOCALE, data: locale });
    dispatch(
      setLocaleData({
        locale,
        defaultPhone,
        landingGuid,
      })
    );
  };

export const getUser = (locale) => (dispatch, getState) => {
  dispatch({ type: actionTypes.GET_USER_REQUEST });

  return get('https://ana.dsh-agency.com/ipinfo/me?geo=1')
    .then((resp) => {
      const countryCode = resp.country_code.toLowerCase();
      const isSiteLocales =
        Object.keys(localesData).indexOf(countryCode) !== -1;
      const userLocale = isSiteLocales
        ? countryCode
        : getState().locales.locale;

      dispatch({
        type: actionTypes.GET_USER_RECEIVE,
        data: userLocale,
      });

      if (isSiteLocales && locale !== countryCode) {
        dispatch(showModal(true));
      }
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_USER_ERROR,
        error,
      });

      throw error;
    });
};

export const showModal = (bool) => (dispatch) => {
  dispatch({
    type: actionTypes.SHOW_MODAL,
    data: bool,
  });
};

export const checkLeadExists = (phone) => (dispatch) => {
  return post(checkLeadExistUrl, makeFormData({ phone, get_id: true }))
    .then((resp) => {
      return dispatch({
        type: actionTypes.CHECK_LEAD_EXISTS,
        data: resp,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CHECK_LEAD_EXISTS_ERROR,
        error: error,
      });

      throw error;
    });
};

export const getUserData = (phone) => (dispatch, getState) => {
  return get(getUserDataUrl)
    .then((resp) => {
      dispatch({
        type: actionTypes.GET_USER_DATA,
        data: resp,
      });

      const query = utils.getLocalStorageQuery();

      /*const clearedPhone = phone.replace("+", "");*/
      const localeData = getState().locales.data;

      return {
        phone: phone,
        phone_ref: utils.clearPhone(localeData.phone),
        timezone_str: moment.tz.guess(),
        country: resp.country_name,
        geoip_country_code: resp.country_code,
        geoip_country_name: resp.country_name,
        profile_landing: query.path,
        profile_landing_query: utils.parseQuery(query.search),
        host: REACT_APP_SITE_ID,
        ip: resp.ip_address,
        /*name: clearedPhone,*/
        /*email: clearedPhone + "@qavls.com",*/
        referral_info: 'Direct',
      };
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.GET_USER_DATA_ERROR,
        error: error,
      });
      throw error;
    });
};

export const createLead = (data) => (dispatch) => {
  return post(createLeadUrl, JSON.stringify(data), {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((resp) => {
      return dispatch({
        type: actionTypes.CREATE_LEAD,
        data: resp,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.CREATE_LEAD_ERROR,
        error: error,
      });
      throw error;
    });
};

export const sentTextBack =
  (phone, source_url, textBackBody, userId) => (dispatch, getState) => {
    const localeData = getState().locales.data;
    let landingGuid;

    if (typeof window !== 'undefined') {
      landingGuid = _.get(
        parseQuery(window.location.search),
        'landing_guid',
        ''
      );
    }

    const data = {
      //from_num: utils.clearPhone(localeData.phone),
      to_num: phone,
      landing_guid: landingGuid || localeData.landingGuid,
      source_url: source_url,
      body: textBackBody,
      //smstool_name: REACT_APP_SMS_TOOL_NAME,
    };

    if (userId.length) {
      data.client_id = userId;
    }

    return post(sentTextBackUrl, makeFormData(data))
      .then((resp) => {
        return dispatch({
          type: actionTypes.SENT_TEXT_BACK,
          data: resp,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SENT_TEXT_BACK_ERROR,
          error: error,
        });
        throw error;
      });
  };

export const validatePhone = (phone) => (dispatch) => {
  dispatch({ type: actionTypes.VALIDATE_PHONE_REQUEST });

  return post(validatePhoneUrl, JSON.stringify({ phone }), {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((resp) => {
      return dispatch({
        type: actionTypes.VALIDATE_PHONE_RECEIVE,
        data: resp,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.VALIDATE_PHONE_ERROR,
        error: error,
      });
      throw error;
    });
};

export const updateUser = (id, email) => (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_USER_REQUEST });

  return patch(updateUserUrl(id), { email })
    .then((resp) => {
      return dispatch({
        type: actionTypes.UPDATE_USER_RECEIVE,
        data: resp,
      });
    })
    .catch((error) => {
      dispatch({
        type: actionTypes.UPDATE_USER_ERROR,
        error,
      });

      throw error;
    });
};

export const healthCheck = () => (dispatch) => {
  dispatch({ type: actionTypes.HEALTH_CHECK });
  return get(healthCheckUrl())
    .then((resp) => {
      dispatch({ type: actionTypes.HEALTH_CHECK_SUCCESS });
      return resp;
    })
    .catch((error) => {
      dispatch({ type: actionTypes.HEALTH_CHECK_ERROR });
      throw error;
    });
};
