import React, { Component } from 'react'

import ReactSwipe from 'react-swipe'
import classnames from 'classnames';

import './index.css'


export const SliderNav = (props) => {
  const {items} = props;

  return (
    <div className='slider__nav'>
      {items.map((item, key) => {
        return <div
          key={`slider__nav-item-${key}`}
          onClick={() => props.scrollTo(key)}
          className={classnames('slider__nav-item', {
          'slider__nav-item_active': (props.activeIndex) === key
        })}>{item}</div>
      })}
    </div>
  );
};

export class SliderItem extends Component {
  render () {
    const {children} = this.props;

    return (
      <div className='slider__item'>
        {children}
      </div>
    )
  }
}

export class SliderArrowLeft extends Component {
  render () {
    const {children, ...rest} = this.props;

    return (
      <div
        onClick={rest.onClickPrev}
        className={classnames('carousel-control left', {})}>
        {children}
      </div>
    )
  }
}

export class SliderArrowRight extends Component {
  render () {
    const {children, ...rest} = this.props;

    return (
      <div
        onClick={rest.onClickNext}
        className={classnames('carousel-control right', {})}>
        {children}
      </div>
    )
  }
}

export class SliderList extends Component {
  render () {
    const {children, ...rest} = this.props;

    const options = {
      speed: 800,
      continuous: true,
      auto: 9000,
      disableScroll: false,
      stopPropagation: true,
      callback: rest.setActiveIndex
    };

    return (
      <div className='slider__list'>
        <ReactSwipe
          ref={rest.carouselRef}
          swipeOptions={options}
          childCount={React.Children.count(children)}
        >
          {children}
        </ReactSwipe>
      </div>
    )
  }
}

SliderList.displayName = 'SliderList';

/**
 * @class Slider
 * @memberOf Components
 * @param {Object} props
 */

export class SliderCarousel extends Component {
  constructor (props) {
    super(props)

    this.onClickNext = this.onClickNext.bind(this);
    this.onClickPrev = this.onClickPrev.bind(this);
  }

  onClickPrev () {
    this.carouselRef.prev();
  }

  onClickNext () {
    this.carouselRef.next();
  }

  render () {
    const {children} = this.props;

    return (
      <div id='myCarousel' className='slider__carousel carousel slide'>
        {React.Children.map(children, (child) => {
          switch (child.type.displayName) {
            case 'SliderArrowLeft':
              return React.cloneElement(child, {
                onClickPrev: this.onClickPrev,
              });
            case 'SliderArrowRight':
              return React.cloneElement(child, {
                onClickNext: this.onClickNext,
              });
            case 'SliderList':
              return React.cloneElement(child, {
                carouselRef: (el) => {
                  this.carouselRef = el;
                  this.props.carouselRef(el);
                },
                setActiveIndex: this.props.setActiveIndex,
              });
            default:
              return React.cloneElement(child);
          }
        })}
      </div>
    )
  }
}


/**
 * @param {Object} props
 * @param {Array} props.indicators
 */

export default class Slider extends Component {
  constructor (props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.setActiveIndex = this.setActiveIndex.bind(this);
    this.scrollTo = this.scrollTo.bind(this);
  }

  setActiveIndex (index) {
    this.setState({
      activeIndex: index,
    })
  }

  scrollTo (index) {
    this.carouselRef.slide(index);
  }

  render () {
    const {className, children} = this.props;
    const { activeIndex } = this.state;

    return (
      <div className={classnames('slider', className)}>
        {React.Children.map(children, (child) => {
          switch (child.type) {
            case SliderNav:
              return React.cloneElement(child, {
                activeIndex,
                scrollTo: this.scrollTo,
              });
            default:
              return React.cloneElement(child, {
                setActiveIndex: this.setActiveIndex,
                carouselRef: (el) => this.carouselRef = el,
              });
          }
        })}
      </div>
    )
  }
};
