import React from 'react';
import LazyLoad from 'react-lazyload';
import Icon from './../Icons';

import Slider, {
  SliderItem as SliderItemBase,
  SliderCarousel,
  SliderList,
  SliderArrowLeft as SliderArrowLeftBase,
  SliderArrowRight as SliderArrowRightBase,
  SliderNav,
} from '../../../../base_components/Slider';

import './index.css';

export const Slider1 = (props) => <Slider {...props} className={'slider_1'} />;
export const Slider2 = (props) => <Slider {...props} className={'slider_2'} />;

export const SliderItem = (props) => (
  <SliderItemBase>
    <LazyLoad>
      <img
      src={require(`./img/${props.img}.png`)}
      srcSet={require(`./img/${props.img}.png`) +` 1x,` + require(`./img/${props.img}@2x.png`) + ` 2x`}
      alt={props.alt} />
    </LazyLoad>
  </SliderItemBase>
);

export const SliderArrowLeft = ({iconName, iconNameMob, ...rest}) => {
  return (
    <SliderArrowLeftBase {...rest}>
      <LazyLoad>
        <Icon
          className={`svg-icon slider__icon-desk`}
          iconName={`${iconName}`}
        />
        <Icon
          className={`svg-icon slider__icon-mob`}
          iconName={`${iconNameMob}`}
        />
      </LazyLoad>
    </SliderArrowLeftBase>
  );
};

SliderArrowLeft.displayName = 'SliderArrowLeft';

export const SliderArrowRight = ({iconName, iconNameMob, ...rest}) => (
  <SliderArrowRightBase {...rest}>
    <LazyLoad>
      <Icon
        className={`svg-icon slider__icon-desk`}
        iconName={`${iconName}`}
      />
      <Icon
        className={`svg-icon slider__icon-mob`}
        iconName={`${iconNameMob}`}
      />
    </LazyLoad>
  </SliderArrowRightBase>
);

SliderArrowRight.displayName = 'SliderArrowRight';

export {
  SliderCarousel,
  SliderList,
  SliderNav
}
