import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useMobile } from '../../../../../../hooks';
import Holder from "../Holder";
import './index.css';

const MathonLpFooter = () => {
  return (
    <div className="mathonlp-footer">
      <Holder type={2} className={"mathonlp-footer_holder"}>
        <img src={require('../img/logo_math.svg')} width="166" height="48" alt="" />

        <div className="mathonlp-footer__links">
          <Link to={'/mathon/privacy-policy'} className="mathonlp-footer__link">
            Privacy Policy
          </Link>
          <Link
            to={'/mathon/terms-and-conditions'}
            className="mathonlp-footer__link"
          >
            Terms and Conditions
          </Link>
        </div>

        <div className="mathonlp-footer__copy">
          &copy; {moment().year()} MathOn - All Rights Reserved
        </div>
      </Holder>
    </div>
  );
};

export default MathonLpFooter;
