import React, {Component} from 'react';
import Slider from "react-slick";
import Text from "../../../../base_components/Text";
import classnames from 'classnames';
//import { isMobile } from "../../../../utils";
import VideoModal from '../VideoModal';
import { useMobile } from '../../../../hooks';

import './slickSliderClp4.css';

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class SlickSliderClp4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVideoModalOpen: false,
      videoId: ''
    };
    this.openVideoModal = this.openVideoModal.bind(this);
  }

  openVideoModal(videoId) {
    this.setState({ isVideoModalOpen: true, videoId: videoId });
  }

  render() {
    const {isVideoModalOpen, videoId} = this.state;

    const renderItems = () => {
      return [
        <div className="slick-item" key="1">
          <div className = "item-title">Economics</div>
          <button className="video-preview-btn" onClick={() => this.openVideoModal(472545481)}>
            <img className="video-preview-img" src={require("./img/economics.png")}
                 srcSet={require(`./img/economics@2x.png`) + ' 2x'} alt=""/>
            <div className="play-btn">
              <img className="play-btn-img" src={require("./img/play-icon.svg")} alt=""/>
            </div>
          </button>
        </div>
        ,
        <div className="slick-item" key="2">
          <div className = "item-title">Calculus</div>
          <button className="video-preview-btn" onClick={() => this.openVideoModal(483972404)}>
            <img className="video-preview-img" src={require("./img/calculus.png")}
                 srcSet={require('./img/calculus@2x.png') + ' 2x'} alt=""/>
            <div className="play-btn">
              <img className="play-btn-img" src={require("./img/play-icon.svg")} alt=""/>
            </div>
          </button>
        </div>
        ,
        <div className="slick-item" key="3">
          <div className = "item-title">Geometry</div>
          <button className="video-preview-btn" onClick={() => this.openVideoModal(483973177)}>
            <img className="video-preview-img" src={require("./img/geometry.png")}
                 srcSet={require('./img/geometry@2x.png') + ' 2x'} alt=""/>
            <div className="play-btn">
              <img className="play-btn-img" src={require("./img/play-icon.svg")} alt=""/>
            </div>
          </button>
        </div>
        ,
        <div className="slick-item" key="4">
          <div className = "item-title">Statistics</div>
          <button className="video-preview-btn" onClick={() => this.openVideoModal(472545514)}>
            <img className="video-preview-img" src={require("./img/statistics.png")}
                 srcSet={require('./img/statistics@2x.png') + ' 2x'} alt=""/>
            <div className="play-btn">
              <img className="play-btn-img" src={require("./img/play-icon.svg")} alt=""/>
            </div>
          </button>
        </div>
        ,
        <div className="slick-item" key="5">
          <div className = "item-title">Chemistry</div>
          <button className="video-preview-btn" onClick={() => this.openVideoModal(472545430)}>
            <img className="video-preview-img" src={require("./img/chemistry.png")}
                 srcSet={require('./img/chemistry@2x.png') + ' 2x'} alt=""/>
            <div className="play-btn">
              <img className="play-btn-img" src={require("./img/play-icon.svg")} alt=""/>
            </div>
          </button>
        </div>
        ,
      ]
    };

    return (
      <WithHook>
        {({
            isMobile
          }) => {
          return (
            <div className={classnames("slick-slider_clp4",this.props.className)}>
              <div className="holder">
                <h3 className="slick-title">{this.props.title || <Text>In the last 24 hours our Nerds have recorded over 140 videos<br className="desktop-only"/>
                  of how to solve different problems</Text>}</h3>
              </div>
              <div className="slick-wrapper">
                {isMobile && (
                  <Slider variableWidth
                          useTransform={false}
                          infinite={true}
                          slidesToShow={1}
                          dots={false}
                          arrows={false}
                  >
                    {renderItems()}
                  </Slider>
                )}
                {!isMobile && (
                  <Slider variableWidth
                          useTransform={false}
                          infinite={true}
                          slidesToShow={1}
                          dots={false}
                          arrows={true}
                  >
                    {renderItems()}
                  </Slider>
                )}
              </div>

              {isVideoModalOpen &&
                <VideoModal type='1' videoId={videoId} onClose={() => this.setState({ isVideoModalOpen: false })} />
              }
            </div>
          )
        }}
      </WithHook>
    )
  }
}
