import React, { Component } from 'react'
import { Link } from "react-scroll";

import Icon from './../Icons';
import classnames from 'classnames';
import './index.css';

export default class ReviewBlock extends Component {
  constructor(props) {
    super(props);
    this.onIndicatorHandleClick = this.onIndicatorHandleClick.bind(this);
    this.onMoreReview = this.onMoreReview.bind(this);
    this.getRandomInt = this.getRandomInt.bind(this);
    //const activeItem = (props.pageCfg && props.pageCfg.activeItem) || props.activeItem || 'Students';
    this.state = {
      activeTabIndex: 0,
      maxItemHeight: 800,
      btnScrollUpVisible: false,
      btnMoreRewiewVisible: true,
      height: 0
    };
  }

  onIndicatorHandleClick(e) {
    this.setState({ activeTabIndex: e });
    this.setState({ maxItemHeight: 800 });
    this.setState({ btnScrollUpVisible: false });
    this.setState({ btnMoreRewiewVisible: true });
  }
  onMoreReview(index) {
    let height;
    if (index === 0) {
      height = this.trustpilotBlock.clientHeight;
    }
    if (index === 1) {
      height = this.sitejabberBlock.clientHeight;
    }
    if (index === 2) {
      height = this.fbBlock.clientHeight;
    }
    if (index === 3) {
      height = this.googleBlock.clientHeight;
    }
    this.setState({ btnScrollUpVisible: true });
    if (this.state.maxItemHeight + 800 >= height) {
      this.setState({ btnMoreRewiewVisible: false });
      this.setState({ maxItemHeight: height });
    } else {
      this.setState({ maxItemHeight: this.state.maxItemHeight + 800 });
    }
  }
  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  render() {
    const { items } = this.props;
    //const keys = Object.keys(items);

    return <div className={classnames('review-block' /*, {[`review-block_${this.props.theme}`]: theme}*/)}>
      <div className="holder">
        <h2 className="title">More than 4,800 positive reviews since 2015 on:</h2>

        <div className="carousel-nr">
          <div className="carousel-indicators">
            <button className={`carousel-indicator-item trustpilot-btn ${this.state.activeTabIndex === 0 ? 'active' : ''}`}
                    data-slide-to="0" onClick={(e) => this.onIndicatorHandleClick(0)}>
              <Icon className={`item-img trustpilot-logo mobile-only`} iconName="trustpilot-logo-mob"/>
              <Icon className={`item-img trustpilot-logo desktop-only`} iconName="trustpilot-logo"/>
            </button>
            <button className={`carousel-indicator-item sitejabber-btn ${this.state.activeTabIndex === 1 ? 'active' : ''}`}
                    data-slide-to="1" onClick={(e) => this.onIndicatorHandleClick(1)}>
              <Icon className={`item-img sitejabber-logo mobile-only`} iconName="sitejabber-logo-mob"/>
              <Icon className={`item-img sitejabber-logo desktop-only`} iconName="sitejabber-logo"/>
            </button>
            <button className={`carousel-indicator-item fb-btn ${this.state.activeTabIndex === 2 ? 'active' : ''}`}
                    data-slide-to="2" onClick={(e) => this.onIndicatorHandleClick(2)}>
              <Icon className={`item-img fb-logo mobile-only`} iconName="fb-logo-mob"/>
              <Icon className={`item-img fb-logo desktop-only`} iconName="fb-logo"/>
            </button>
            <button className={`carousel-indicator-item google-btn ${this.state.activeTabIndex === 3 ? 'active' : ''}`}
                    data-slide-to="3" onClick={(e) => this.onIndicatorHandleClick(3)}>
              <Icon className={`item-img google-logo mobile-only`} iconName="google-logo-mob"/>
              <Icon className={`item-img google-logo desktop-only`} iconName="google-logo"/>
            </button>
          </div>


          <Link to="carousel-nr"
                smooth={true}
                //offset={-70}
                duration= {500}
                className={`btn-up ${this.state.btnScrollUpVisible ? 'active':''} `}>
            <Icon className={`btn-up-img`} iconName="arrow-up"/>
          </Link>

          <div className="carousel-inner">

            <div id="trustpilot-item-block" className={`item ${this.state.activeTabIndex === 0 ? 'active':''}  ${this.state.btnMoreRewiewVisible ? '':'full'}`} style={{ maxHeight: this.state.maxItemHeight,}}>
              <button className={`button__type16 more-rewiew-btn ${this.state.btnMoreRewiewVisible ? 'active':''}`} data-target="#trustpilot-item-block" onClick={()=> this.onMoreReview(0)}>Show more reviews</button>

              <div className="carousel-list" ref={(trustpilotBlock) => { this.trustpilotBlock = trustpilotBlock }}>

                {items.trustpilot.map((item,key) => (
                  <div className="carousel-list-item trustpilot-item" key={`trustpilot-${key}`}>
                    <div className="review-part">
                      <div className="review-part-top">
                        <div className={`review-mark review-mark-${item.score}`}>
                          <Icon className={`review-mark-img`} iconName="trustpilot-mark"/>
                          <Icon className={`review-mark-img`} iconName="trustpilot-mark"/>
                          <Icon className={`review-mark-img`} iconName="trustpilot-mark"/>
                          <Icon className={`review-mark-img`} iconName="trustpilot-mark"/>
                          <Icon className={`review-mark-img`} iconName="trustpilot-mark"/>
                        </div>
                        <div className="review-date">{item.date}</div>
                      </div>
                      <div className="review-name">{item.name}</div>
                      <div className="review-title">{item.title}</div>
                      <div className="review-message">{item.review}</div>
                      <div className="review-check">
                        <Icon className="review-check-img" iconName="check-round2"/>
                        <div className="review-check-msg">Verified purchase</div>
                      </div>
                    </div>
                    {item.nerdify &&
                    <div className="answer-part">
                      <div className="answer-name">Nerdify:</div>
                      <div className="answer-message">{item.nerdify}</div>
                    </div>
                    }
                  </div>
                ))}
              </div>
            </div>


            <div id="sitejabber-item-block" className={`item ${this.state.activeTabIndex === 1 ? 'active' : ''} ${this.state.btnMoreRewiewVisible ? '':'full'}`} style={{ maxHeight: this.state.maxItemHeight}}>
              <button className={`button__type16 more-rewiew-btn ${this.state.btnMoreRewiewVisible ? 'active':''}`} onClick={()=> this.onMoreReview(1)}>Show more reviews</button>

              <div className="carousel-list" ref={(sitejabberBlock) => { this.sitejabberBlock = sitejabberBlock }}>

                {items.sitejabber.map((item,key) => (
                  <div className="carousel-list-item sitejabber-item" key={`sitejabber-${key}`}>
                    <div className="review-part">
                      <div className="review-title">{item.title}</div>
                      <div className="review-part-top">
                        <div className={`review-mark review-mark-${item.score}`}>
                          <Icon className={`review-mark-img`} iconName="sitejabber-mark"/>
                          <Icon className={`review-mark-img`} iconName="sitejabber-mark"/>
                          <Icon className={`review-mark-img`} iconName="sitejabber-mark"/>
                          <Icon className={`review-mark-img`} iconName="sitejabber-mark"/>
                          <Icon className={`review-mark-img`} iconName="sitejabber-mark"/>
                        </div>
                        <div className="review-name">{item.name}</div>
                        <div className="review-date">{item.date}</div>
                      </div>
                      <div className="review-message">{item.review}</div>
                      <div className="review-check">
                        <Icon className="review-check-img" iconName="check-round2"/>
                        <div className="review-check-msg">Verified purchase</div>
                      </div>
                    </div>
                    {item.nerdify &&
                    <div className="answer-part">
                      <div className="answer-name">Nerdify:</div>
                      <div className="answer-message">{item.nerdify}
                      </div>
                    </div>
                    }
                  </div>
                ))}

              </div>
            </div>


            <div id="fb-item-block" className={`item ${this.state.activeTabIndex === 2 ? 'active' : ''} ${this.state.btnMoreRewiewVisible ? '':'full'}`} style={{ maxHeight: this.state.maxItemHeight}}>
              <button className={`button__type16 more-rewiew-btn ${this.state.btnMoreRewiewVisible ? 'active':''}`} onClick={()=> this.onMoreReview(2)}>Show more reviews</button>
              <div className="carousel-list" ref={(fbBlock) => { this.fbBlock = fbBlock }}>

                {items.facebook.map((item,key) => (
                  <div className="carousel-list-item fb-item" key={`facebook-${key}`}>
                    <div className="review-part">
                      <div className="review-name-line">
                        <div className="review-name">{item.name}</div>
                        <Icon className={`fb-bubble ${item.recommends ? 'recommend': ''} `} iconName="fb-bubble"/>
                        <div className="review-recommend">{item.recommends ? 'recommends' : 'doesn\'t recommend'} <span className="strong">Nerdify</span></div>
                      </div>
                      <div className="review-date-line">
                        <div className="review-date">{item.date}&nbsp;&nbsp;•</div>
                        <Icon className={`earth-icon`} iconName="earth-icon"/>
                      </div>
                      <div className="review-message">{item.review}</div>
                      <div className="review-check">
                        <Icon className="review-check-img" iconName="check-round2"/>
                        <div className="review-check-msg">Verified purchase</div>
                      </div>
                    </div>
                    {item.nerdify &&
                    <div className="fb-answer-part">
                      <img className="fb-answer-img" src={require(`./img/ava@2x.png`)} alt={'Nerdify reply'}/>
                        <div className="fb-answer-message-part">
                          <div className="fb-answer-name">Nerdify:</div>
                          <div className="fb-answer-message">{item.nerdify}</div>
                        </div>
                    </div>
                    }
                  </div>
                ))}

              </div>
            </div>



            <div id="google-item-block" className={`item ${this.state.activeTabIndex === 3 ? 'active' : ''} ${this.state.btnMoreRewiewVisible ? '':'full'}`} style={{ maxHeight: this.state.maxItemHeight}}>
              <button className={`button__type16 more-rewiew-btn ${this.state.btnMoreRewiewVisible ? 'active':''}`} onClick={()=> this.onMoreReview(3)}>Show more reviews</button>
              <div className="carousel-list" ref={(googleBlock) => { this.googleBlock = googleBlock }}>

                {items.google.map((item,key) => (
                  <div className="carousel-list-item google-item" key={`google-${key}`}>
                    <div className="review-part">
                      <div className="review-name-line">
                        <div className={`review-name-capital type${this.getRandomInt(9)}`}>{item.name.charAt(0)}</div>
                        <div className="review-name">{item.name}</div>
                      </div>
                      <div className="review-mark-line">
                        <div className={`review-mark review-mark-${item.score}`}>
                          <Icon className={`review-mark-img`} iconName="google-star"/>
                          <Icon className={`review-mark-img`} iconName="google-star"/>
                          <Icon className={`review-mark-img`} iconName="google-star"/>
                          <Icon className={`review-mark-img`} iconName="google-star"/>
                          <Icon className={`review-mark-img`} iconName="google-star"/>
                        </div>
                        <div className="review-date">{item.date}</div>
                      </div>
                      <div className="review-message">{item.review}</div>
                      <div className="review-check">
                        <Icon className="review-check-img" iconName="check-round2"/>
                        <div className="review-check-msg">Verified purchase</div>
                      </div>
                    </div>
                    {item.nerdify &&
                    <div className="answer-part">
                      <div className="answer-name">Nerdify:</div>
                      <div className="answer-message">{item.nerdify}</div>
                    </div>
                    }
                  </div>
                ))}

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  }
}

export {
  ReviewBlock
}
