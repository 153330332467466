import React from "react";
import Text from "../../../../base_components/Text";

export const InvestTitle = <Text>Invest efforts in a final result, while<br className="desktop-only"/> Nerds help you get prepared:</Text>;

export const InvestItems = [
    {
      title: "Academia",
      icon: {
        name: "invest1-icon",
        width: 23,
        height: 27,
      },
      list: [
        "Collecting data for analysis",
        "Conducting interviews and surveys",
        "Analyzing the existing research"
      ],
      linkMessage: "Learn more →",
      linkUrl: "/for-academia",
    },
    {
      title: "Students",
      icon: {
        name: "invest2-icon",
        width: 23,
        height: 27,
      },
      list: [
        "Explaining difficult topics",
        "Preparing for classes and tests",
        "Advising on research methodology"
      ],
      linkMessage: "Learn more →",
      linkUrl: "/for-students",
    },
    {
      title: "Businesses",
      icon: {
        name: "invest3-icon",
        width: 23,
        height: 27,
      },
      list: [
        "Putting together presentations",
        "Preparing reports & documentation",
        "Conducting market research"
      ],
      linkMessage: "Learn more →",
      linkUrl: "/for-businesses",
    }
  ];
