import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

//import Content from '../../../../themes/nerdify/components/Content';
import { Section2 } from '../../../../themes/nerdify/components/Section';
import Holder from '../../../../themes/nerdify/components/Holder';
import Attach from '../../../../themes/nerdify/components/Attach';
import InputError from '../../../../themes/nerdify/components/Form/InputError';
import Icon from '../../../../themes/nerdify/components/Icons';
import { Header1, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import { Hnav3 } from "../../blocks/Hnav";
import { HnavMob2 } from '../../blocks/HnavMob';
import InputSelect from './../../../../themes/nerdify/components/Form/InputSelect';
import { Hero2 } from '../../../../themes/nerdify/components/Hero';
import { Hiw3, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw';
import { ModalLoginForm, ModalApplicationForm } from './../../../../themes/nerdify/components/Modal';
import { Intro2, Intro4 } from '../../../../themes/nerdify/components/Intro';
import { Form1 } from '../../../../themes/nerdify/components/Form';
import { Footer2 } from '../../blocks/Footer'

import { trackClicks } from '../../../../lrtracker';
import regexps from '../../../../utils/regexps';
import { LEVELS, YEARS, COUNTRIES } from '../../../../config';
//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import LocalesPage from './../LocalesPage';
import {useMobile} from "../../../../hooks";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

class BecomeANerd extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      name: '',
      phone: '',
      email: '',
      level: '',
      country: '',
      college: '',
      major: '',
      year: '',
      fields: '',
      about: '',
      url: '',
      agree: false,
      errors: {},
      isHasClick: false,
      isDisabled: false,
      isSuccess: false,
      isLogin: false,
      isClear: false,
    };

    this.state = this.initialState;

    this.onMakeLogin = this.onMakeLogin.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.validate = this.validate.bind(this);
  }

  onMakeLogin() {
    this.setState({ isLogin: !this.state.isLogin });
  }

  onClose() {
    this.setState({
      isClear: true,
    }, () => {
      this.setState({...this.initialState});
    });
  }

  onChangeCheckbox(e) {
    const { isHasClick } = this.state;
    this.setState({
      agree: e.target.checked
    }, () => {
      if (isHasClick) {
        this.validate();
      }
    })
  }

  onChangeInput(e) {
    const { name, value } = e.target;
    const { isHasClick } = this.state;

    this.setState({
      [name]: value
    }, () => {
      if (isHasClick) {
        this.validate();
      }
    });
  }

  validate() {
    const {
      name,
      phone,
      email,
      level,
      country,
      college,
      major,
      year,
      fields,
      about,
      url,
      agree,
    } = this.state;

    const errors = {
      name: !name.trim().length ? 'Required field' : null,
      phone: !regexps.phone(phone.trim()) ? 'Required field' : null,
      email: !regexps.email(email.trim()) ? 'Required field' : null,
      level: !level.trim().length ? 'Required field' : null,
      country: !country.trim().length ? 'Required field' : null,
      college: !college.trim().length ? 'Required field' : null,
      major: !major.trim().length ? 'Required field' : null,
      year: !year.trim().length ? 'Required field' : null,
      fields: !fields.trim().length ? 'Required field' : null,
      about: !about.trim().length ? 'Required field' : null,
      url: !url.trim().length ? 'Required field' : null,
      agree: !agree ? 'Required field' : null,
    };

    const isError = Object.keys(errors).some(item => errors[item]);

    this.setState({
      errors,
      isDisabled: isError,
    });

    return isError;
  }

  onSubmit() {
    this.setState({ isHasClick: true });

    const isError = this.validate();
    if (!isError) {
      trackClicks('target', 'submitNewNerd', {context: {is_successfull: true}})();
      this.setState({ isSuccess: true });
    }
  }

  render() {
    const {
      name,
      phone,
      email,
      level,
      country,
      college,
      major,
      year,
      fields,
      about,
      url,
      agree,
      errors,
      isDisabled,
      isSuccess,
      isLogin,
      isClear,
    } = this.state;

    return (
      <WithHook>
        {({
            isMobile
          }) => {

    return (
      <LocalesPage {...this.props}>
       <div className="become-a-nerd">
        <Header1>
          <Holder>
            <NavLink className='logo-link' to='/'>
              <HeaderIcon iconName='logo-dark' />
            </NavLink>
            {isMobile ? <HnavMob2 /> : <Hnav3 />}  {/*theme='light'*/}
{/*
            <div className="header-wrap">
              <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
              <HnavMob2 />
              <InputSelectLocales
                className='input-select__short'
                name='country'
                isError={false}
              />
            </div>
*/}
          </Holder>
        </Header1>

        <Hero2 key='becomeANerd-hero'>
          <h1 className="h0 hero__title">Interested<br className="mobile-only"/> in<br className="desktop-only"/> becoming a Nerd?</h1>
          <hr className="hero__hr desktop-only" />
          <div className="h5 hero__subtitle">Already a freelance Nerd?</div>
          <button className="button__type10" onClick={this.onMakeLogin}>Log in</button>
        </Hero2>

        <Intro2>
          <Holder className="holder_1">
            <div className="intro__msg1">We are constantly looking for talented people from the highly rated colleges to join Nerdify.</div>
            <div className="intro__msg1">Our platform is a network connecting the brightest individuals with clients.</div>
            <div className="intro__msg2">Are you a 3rd-year student or older? <br className="desktop-only"/>
              You are welcome to apply!</div>
          </Holder>
        </Intro2>

         <div className="hr_6" />

         <Intro4 indent={"pt40 pb40m pb60d"}>
           <Holder className={"holder_3"}>
             <div className="intro__title">Disclaimer</div>
             <div className="intro__msg">Please note that applying to become a Nerd means you agree with our Honor Code that prohibits being engaged in any kind of activity not aligned with university, company or any other policies. Please read our <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink> and <NavLink className="a__underline" to="/honor-code#prohibited-tasks">the list</NavLink> of prohibited tasks carefully.</div>
           </Holder>
         </Intro4>

         <div className="hr_6" />

          <Hiw3>
            <h2 className='h3'>How to join our team:</h2>

            <HiwList>
              <HiwItem>
                <HiwImg img={'hiw7'} />
                <HiwText>
                  <p className="p">Submit an Application Form</p>
                </HiwText>
              </HiwItem>
              <HiwItem>
                <HiwImg img={'hiw8'} />
                <HiwText>
                  <p className="p">Pass the Examination</p>
                </HiwText>
              </HiwItem>
              <HiwItem>
                <HiwImg img={'hiw9'} />
                <HiwText>
                  <p className="p">Get Approved & Get Started</p>
                </HiwText>
              </HiwItem>
              <HiwItem>
                <HiwImg img={'hiw10'} />
                <HiwText>
                  <p className="p">Earn up to $20 per Hour</p>
                </HiwText>
              </HiwItem>
            </HiwList>

            <p className="p p__tiny hiw__text">
              Please allow a couple of weeks to contact you back.
              <br />
              We are accepting applications but currently the demand for freelance Nerds is fully met.
            </p>
          </Hiw3>

          <Holder className="holder_1 mobile-only">
            <hr className="hr" />
          </Holder>

          <Section2 items={6}>
            <Holder className="holder_1">
              <h2 className="h2">Fill Out an Application Form</h2>
            </Holder>
            <Holder>
              <Form1>
                <fieldset className="fieldset fieldset__mobile-padding">
                  <label className="label">Your name and surname</label>
                  <input
                    className={classnames("input", { "input__error": errors.name })}
                    type="text"
                    name="name"
                    value={name}
                    placeholder=""
                    autoCapitalize="off"
                    autoCorrect="off"
                    autoComplete="off"
                    onChange={this.onChangeInput}
                  />
                  {!!errors.name &&
                  <InputError>{errors.name}</InputError>
                  }
                </fieldset>
                <fieldset className="fieldset fieldset__mobile-padding">
                  <div className="fieldset-item">
                    <label className="label">Your phone number</label>
                    <div className="input-wrapper">
                      <input
                        className={classnames("input", { "input__error": errors.phone })}
                        type="tel"
                        name="phone"
                        value={phone}
                        placeholder=""
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />
                      {!!errors.phone &&
                      <InputError>{errors.phone}</InputError>
                      }
                    </div>
                  </div>
                  <div className="fieldset-item">
                    <label className="label">Your email</label>
                    <div className="input-wrapper">
                      <input
                        className={classnames("input", { "input__error": errors.email })}
                        type="email"
                        name="email"
                        value={email}
                        placeholder=""
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        onChange={this.onChangeInput}
                      />
                      {!!errors.email &&
                      <InputError>{errors.email}</InputError>
                      }
                    </div>
                  </div>
                </fieldset>
                <fieldset className="fieldset fieldset__mobile-padding">
                  <Attach id="become-a-nerd-file-1" clear={isClear}>Upload your photo</Attach>
                </fieldset>
                <div className="form-item fieldset__mobile-padding">
                  <fieldset className="fieldset">
                    <label className="label">Your level of education</label>
                    <div className="input-wrapper">
                      {/*<input
                      className={classnames("input", { "input__error": errors.level })}
                      type="text"
                      name="level"
                      value={level}
                      placeholder=""
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="off"
                      onChange={this.onChangeInput}
                    />*/}
                      <InputSelect
                        name='level'
                        items={LEVELS}
                        value={level}
                        onChange={this.onChangeInput}
                        isError={errors.level}
                      />
                      {!!errors.level &&
                      <InputError>{errors.level}</InputError>
                      }
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <div className="fieldset-item">
                      <label className="label">Your country of residence</label>
                      <div className="input-wrapper">
                        {/*<input
                        className={classnames("input", { "input__error": errors.country })}
                        type="text"
                        name="country"
                        value={country}
                        placeholder=""
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />*/}
                        <InputSelect
                          name='country'
                          items={COUNTRIES}
                          value={country}
                          onChange={this.onChangeInput}
                          isError={errors.country}
                        />
                        {!!errors.country &&
                        <InputError>{errors.country}</InputError>
                        }
                      </div>
                    </div>
                    <div className="fieldset-item">
                      <label className="label">Your university/college name</label>
                      <div className="input-wrapper">
                        <input
                          className={classnames("input", { "input__error": errors.college })}
                          type="text"
                          name="college"
                          value={college}
                          placeholder=""
                          autoCapitalize="off"
                          autoCorrect="off"
                          autoComplete="off"
                          onChange={this.onChangeInput}
                        />
                        {!!errors.college &&
                        <InputError>{errors.college}</InputError>
                        }
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <div className="fieldset-item">
                      <label className="label">Your Major(s)</label>
                      <div className="input-wrapper">
                        <input
                          className={classnames("input", { "input__error": errors.major })}
                          type="text"
                          name="major"
                          value={major}
                          placeholder=""
                          autoCapitalize="off"
                          autoCorrect="off"
                          autoComplete="off"
                          onChange={this.onChangeInput}
                        />
                        {!!errors.major &&
                        <InputError>{errors.major}</InputError>
                        }
                      </div>
                    </div>
                    <div className="fieldset-item">
                      <label className="label">Your graduation year</label>
                      <div className="input-wrapper">
                        {/*<input
                        className={classnames("input", { "input__error": errors.year })}
                        type="text"
                        name="year"
                        value={year}
                        placeholder="2019"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />*/}
                        <InputSelect
                          name='year'
                          items={YEARS}
                          value={year}
                          onChange={this.onChangeInput}
                          isError={errors.year}
                        />
                        {!!errors.year &&
                        <InputError>{errors.year}</InputError>
                        }
                      </div>
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <Attach id="become-a-nerd-file-2" clear={isClear}>Attach a scan of your diploma</Attach>
                    <p className="p p__tiny">*we ask you to provide a school verification in order to confirm that you are currently or have once been enrolled in a university.</p>
                  </fieldset>
                </div>
                <div className="form-item fieldset__mobile-padding">
                  <fieldset className="fieldset">
                    <label className="label">What are the fields you specialize in?</label>
                    <div className="input-wrapper">
                    <textarea
                      className={classnames("textarea", { "textarea__error": errors.fields })}
                      name="fields"
                      value={fields}
                      placeholder="Enter fields separated by comma"
                      onChange={this.onChangeInput}
                    />
                      {!!errors.fields &&
                      <InputError>{errors.fields}</InputError>
                      }
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <label className="label">Tell us more about yourself</label>
                    <div className="input-wrapper">
                    <textarea
                      className={classnames("textarea", { "textarea__error": errors.about })}
                      name="about"
                      value={about}
                      placeholder="A few words, just to know you better"
                      onChange={this.onChangeInput}
                    />
                      {!!errors.about &&
                      <InputError>{errors.about}</InputError>
                      }
                    </div>
                  </fieldset>
                  <fieldset className="fieldset">
                    <Attach id="become-a-nerd-file-3" clear={isClear}>Attach your CV</Attach>
                  </fieldset>
                  <fieldset className="fieldset">
                    <label className="p">Verify your identity by sharing <strong>either Facebook or LinkedIn</strong> profile URLs:</label>
                    <div className="input-wrapper">
                      <input
                        className={classnames("input", { "input__error": errors.url })}
                        type="text"
                        name="url"
                        value={url}
                        placeholder="Paste URL"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="off"
                        onChange={this.onChangeInput}
                      />
                      {!!errors.url &&
                      <InputError>{errors.url}</InputError>
                      }
                    </div>
                  </fieldset>
                </div>
                <fieldset className="fieldset">
                  <div className="agree">
                    <p className="p p__tiny">Nerdify was created as a cutting-edge solution for students, academia and businesses seeking for the top-notch personalized assistance in data research. We highly appreciate the trust of our clients and constantly strive to bring only positive experience. Quality and punctuality are meant to be top priorities for every Nerd that works with us. We are strongly against exposing personal information, promoting gambling, cheating or drug use. By applying to join our team you agree to work due to company's guidelines.</p>
                    <fieldset className="fieldset">
                      <input
                        id="agree"
                        className={classnames("checkbox", { "checkbox__error": errors.agree })}
                        type="checkbox"
                        name="agree"
                        checked={agree}
                        onChange={this.onChangeCheckbox}
                      />
                      <label className="label" htmlFor="agree">
                        I agree to
                        {' '}
                        <NavLink className="a__underline" to="/terms-and-conditions">Terms of Use</NavLink>,
                        {' '}
                        <NavLink className="a__underline" to="/privacy-policy">Privacy Policy</NavLink>,
                        {' '}
                        <NavLink className="a__underline" to="/cookies">Cookie Policy</NavLink>,
                        {' '}
                        <NavLink className="a__underline" to="/honor-code">Honor Code</NavLink>
                        {' '}
                        and processing of my personal data.
                      </label>
                      <Icon iconName="check" className="icon-checkbox" />
                    </fieldset>
                  </div>
                </fieldset>
                <div className="fieldset">
                  <button type="button" className="button button__type6 form__btn" onClick={this.onSubmit} disabled={isDisabled}>Submit Application Form</button>
                </div>
              </Form1>
            </Holder>
          </Section2>

          {isSuccess &&
          <ModalApplicationForm isOpen={isSuccess} onClose={this.onClose} />
          }

          {isLogin &&
          <ModalLoginForm isOpen={isLogin} onClose={this.onMakeLogin} />
          }

         <Footer2 />
      </div>
      </LocalesPage>
    )
        }}
      </WithHook>
    )
  }
}

export default BecomeANerd;
