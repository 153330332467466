import React from "react";
import Text from "../../../../base_components/Text";
//import { Link } from 'react-router-dom'

export const HIWItems = [
  {
    img: 'hiw1',
    step: "Step 1",
    message: <Text>Message us and share the details of your request: snap a photo or tell us in your own words what you need.</Text>
  },
  {
    img: 'hiw2',
    step: "Step 2",
    message: <Text>Our platform matches you with the best-fit freelance Nerd based on your requirements.</Text>
  },
  {
    img: 'hiw5',
    step: "Step 3",
    message: <Text>Get and agree the quote based on the amount of hours a freelance Nerd will spend working on your request.</Text>
  },
  {
    img: 'hiw3',
    step: "Step 4",
    message: <Text>Make a prepayment: this money is safe with us and will be released to a Nerd once the request is completed.</Text>
  },
  {
    img: 'hiw6',
    step: "Step 5",
    message: <Text>Collaborate with the Nerd working on your request in the most convenient format for you at any time of the day.</Text>
  },
  {
    img: 'hiw4',
    step: "Step 6",
    message: <Text>Enjoy on-demand personalized assistance from Nerds and get the desired result on time, every time.</Text>
  },
];
