import React from 'react';
import { LocalesHOC } from '../../../../base_components/Locales';
import { ModalChangeLocales } from './../../../../themes/nerdify/components/Modal';

const LocalesPage = (props) => {
  return (
    <div className="localized">
      {props.children}
      <ModalChangeLocales />
    </div>
  );
};

export default LocalesHOC(LocalesPage);
