import React from 'react';
import classnames from "classnames";

import How, {
  HowDots,
  HowList,
} from '../../../../base_components/How';

import './index.css';

export default (props) => <How {...props} className={'how_1'} />
export const HowImg = ({img}) => <img src={require(`./img/${img}.svg`)} alt={''} />

export const How2 = (props) => <How {...props} className={'how_2'} />

export const HowImg2 = ({img}) => <img src={require(`./img/avatar/${img}.png`)} srcSet={require(`./img/avatar/${img}.png`) + ' 1x,' + require(`./img/avatar/${img}@2x.png`) + ' 2x'} alt={img} />

export const HowClp3 = ({ className, children }) => {
  return  <How className={classnames("how_clp3",className)}>
    {children}
  </How>
};

export {
  HowDots,
  HowList,
}
