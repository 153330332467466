export default [
  {
    title: 'Tutoring',
    msg: 'Please help me understand standard normal distribution! I need to find the probability using Z-score.'
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Math Nerd to help me get ready for my Algebra test.'
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!'
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?"
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my research materials and provide feedback. This task is very important for my class!'
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly."
  }
];
