import React, { useState } from 'react';
import classnames from "classnames";
import { Link } from 'react-router-dom';
import { isClient } from '../../../../utils'

import './dropdown.css';

const isActive = (link) => {
  return link === isClient && window.location.pathname;
};

const DropdownItem = ({ name, link, isActive, ...rest }) => {
  return (
    <li className={classnames('dropdown-list-item',
      {'dropdown-list-item__active': isActive}
    )}>
      <Link to={link} className='dropdown-list-item-link'>{ name }</Link>
    </li>
  );
}

export default({ items, ...rest }) => {
  const isItemsIsset = items && items.length;
  const activeItem = items.find(item => isActive(item.link));
  const activeItemName = (activeItem && activeItem.name) || items[0].name;
  if (!isItemsIsset) {
    return null;
  }
  return (
    <div className='dropdown'>
      <div className='dropdown-title'>{ activeItemName }</div>
      <ul className='dropdown-list'>
        {items.map((item, index) => {
          return (
            <DropdownItem
              {...item}
              isActive={activeItemName === item.name}
              key={`DropdownItem-${index}`}
            />
          );
        })}
      </ul>
    </div>
  )
}

const MenuDropdown = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const activeItem = items.find(item => isActive(item.path));
  // const activeItemName = (activeItem && activeItem.label);
  if (!(items && items.length)) {
    return null;
  }

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="menuDropdown">
      <button className={classnames('title', {'open': isOpen})} onClick={handleDropdownToggle}>
        {title}
      </button>
      {isOpen && (
        <ul className="menu">
          {items.map((item) => (
            <li key={item.path} className="item">
            {/* <li key={item.path} className={classnames('item', {'active': activeItemName === item.label})}> */}
              <Link
                to={item.path}
                className='menuDropdown-item-link'
                onClick={() => setIsOpen(false)}
              >
                { item.label }
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { MenuDropdown };
