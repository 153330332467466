import React from 'react';
import { commonReviews } from './commonReviews';

// /tr-business-help
export const sliderReviewsItems1 = {
  Trustpilot: [
    {
      name: 'Cutie S.',
      mark: '5',
      date: 'Jul 6, 2022',
      title: 'MY EXPERIENCE',
      review:
        'They are very helpful, fast, and diligent with their work. I had an amazing experience with Business nerds and would recommend it to any college student. I wish to be using them again in the near future!',
    },
    {
      name: 'Kirian B.',
      mark: '5',
      date: 'Oct 11, 2022',
      title: 'QUICK SERVICE',
      review:
        "Very quick and helpful responses, they don’t waste your time and get right on your project asap. I've requested help with checking my management assignment and all went through just perfectly. Very highly recommended service!",
    },
    {
      name: 'Alfreta S.',
      mark: '5',
      date: 'Mar 28, 2022',
      title: 'BEST HELP',
      review:
        "I've been stuck while writing my business report and needed some inspiration, new sources, or something to finish my work. I reached out to Nerdify and they gave me useful tips and suggested new sources. I would highly recommend them in a tight spot which is what I found myself in. Thanks, Nerdify!",
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      name: 'Justine P.',
      avatar: 'Justine.png',
      recommends: true,
      date: 'May 12, 2022',
      review:
        'Very excellent service! They helped me immediately with no hold-ups or hassles. The framework they suggested for my marketing presentation was just perfect. Great business and fast service! Cooperation with Nerdify is a great decision. You won’t regret it.',
    },
    {
      name: 'Jake P.',
      avatar: 'Jake.png',
      recommends: true,
      date: 'Apr 15, 2022',
      review:
        'Nerdify was effective, efficient, and appropriate with its service. As always, Nerdify delivers excellence through its quick response time and last-minute ability to help. Whether I need them to help with data collection for business research or a short management project, Nerdify never fails!',
    },
    {
      name: 'Dana M.',
      avatar: 'Dana.png',
      recommends: true,
      date: 'Aug 21, 2022',
      review:
        "When I thought I wouldn't make it in my Business classes and starting my new career, I had to contact Nerdify. Nerdify was the best thing that happened to me. I received professional help, and tears of joy came running down my face after the final project. That extra tutoring help changed my worries. Thank you!",
    },
    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will get the job done. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};


// /tr-english-literature-help
export const sliderReviewsItems2 = {
  Trustpilot: [
    {
      name: 'Monica P.',
      mark: '5',
      date: 'Apr 8, 2022',
      title: 'LOVE NERDIFY',
      review:
        'I have used this service three times now for help with Literature and I love it! The nerds are amazing at what they do and you can tell that they put so much effort into what they do. I can’t say how much I appreciate them for being here when I needed it and they are so understanding about what I need. Thank you so much!',
    },
    {
      name: 'Quito S.',
      mark: '5',
      date: 'Oct 4, 2022',
      title: 'VERY HELPFUL',
      review:
        "Nerdify is really amazing! This is my first time to have used it. I had to make a speech outline. English is not really my language. So I'm having a hard time understanding everything. The fact that I have to deliver a speech in front of a lot of people, will make me panic so badly. I’m glad Nerdify was able to help me with practicing my speech. Thank you to my Nerd and to Nerdify!",
    },
    {
      name: 'Kelsi D.',
      mark: '5',
      date: 'Mar 4, 2022',
      title: 'THEY REALLY CARE',
      review:
        "I’m honestly the worst at writing a Literature review and I kept putting it off. Finally, I decided to get started and I couldn’t piece it together so I searched online and found Nerdify! I was very impressed with the level of care they have and really loved how they took on board every piece of information I provided. I completed the review in a couple of hours using tutor's advices. It’s such a great service and has told all my friends!",
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time to find sources for my task but you saved me! I appreciate your service and I have a presentation coming up in a week and I’ll definitely ask you guys for help! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick proofreading of one final paper that I didn’t have time for myself and they were super considerate and fast! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      avatar: 'Katy W.png',
      recommends: true,
      date: 'Sep 29, 2022',
      name: 'Katy W.',
      review:
        'Got some tutoring help for my English class. They are great! Very patient and helpful. I’m very glad I found them since it’s kind of hard to deal with several classes at the same time. 100% recommend it!',
    },
    {
      avatar: 'Marcello L..png',
      recommends: true,
      date: 'Apr 9, 2022',
      name: 'Marcello L.',
      review:
        'I love Nerdify! These people have been nothing but a true help to me as I struggle with English writing. They helped me improve my grammar and develop good attention to detail. Your staff is a hard worker and a good communicator. They come in handy every single time!',
    },
    {
      avatar: 'Jay B.png',
      recommends: true,
      date: 'Jul 14, 2022',
      name: 'Jay B.',
      review:
        'Nerdify is the best! They talk to you personally and make sure you have everything you need. I used them to get some tips for the literature review. They not only help you improve your writing skills they will help you with anything they possibly can provide for any subject. Very highly recommended!',
    },

    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they do everything on time. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will get the job done. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my tasks. I have been working with Nerds for help for quite a while now and must admit that they provide the best service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};

// /tr-finance-accounting-help
export const sliderReviewsItems3 = {
  Trustpilot: [
    {
      mark: '5',
      date: 'May 16, 2022',
      name: 'Simone G.',
      title: 'BEST EXPERIENCE',
      review:
        'The best service ever! A friend recommended Nerdify to me because I was struggling in economics. I texted the number and they immediately responded within 2 minutes. Super polite, professional, and organized. I recommend Nerdify to all college students!',
    },
    {
      mark: '5',
      date: 'Sep 20, 2022',
      name: 'Sarah P.',
      title: 'GODSEND SERVICE',
      review:
        "Nerdify has been nothing short of a complete godsend and lifesaver for me this term with passing my accounting class. The agents who assisted with setting me up with the right nerd to guide me on my task were quick to respond within minutes or less. They were always kind and effective with communication and answered all my questions patiently. I can't recommend their services enough! Just try it!",
    },
    {
      mark: '5',
      date: 'Jul 7, 2022',
      name: 'Frank G.',
      title: 'EXCELLENT SERVICE',
      review:
        'Excellent customer service, polite, professional. This service has lifted the burden from my shoulders and assisted me a lot with checking my finance task. I have a career and a second job, and along with being a student, my life is too hectic to stress over homework. Thanks a million, times over Nerdify for always being so responsive!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      avatar: 'Thomas.png',
      recommends: true,
      date: 'Jun 19, 2022',
      name: 'Thomas C.',
      review:
        "I was skeptical at first, I've been so busy at work and helping with my family. I barely had time to do my economics homework. But they put the effort into guiding me as long as I'm willing to compensate which seems fair. Their response time is good and their replies are acceptable. I would recommend it to everyone!",
    },
    {
      avatar: 'Laura.png',
      recommends: true,
      date: 'Apr 5, 2022',
      name: 'Laura M.',
      review:
        "I wish I found this website sooner! I'm not a huge fan of accounting and needed help to complete a project. Nerdify made it very easy and helped me a lot. They stay in constant contact with you from the very beginning till the moment you understand everything. I highly recommend this service! Be specific with requesting the nerd you need (economics, accounting, finance, etc.), and include as many details as you can. Take a chance to try Nerdify!",
    },
    {
      avatar: 'Joseph C.png',
      recommends: true,
      date: 'Sep 26, 2022',
      name: 'Joseph C.',
      review:
        'Very good experience, with quick replies, friendly staff, and hasty solutions. Highly recommend it to anyone that is in need of assistance with finance homework or anything of caliber.',
    },
    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};

// /tr-humanities-help
export const sliderReviewsItems4 = {
  Trustpilot: [
    {
      mark: '5',
      date: 'Sep 29, 2022',
      name: 'Krish F.',
      title: 'CONVENIENT AND FAST',
      review:
        'It was literally the fastest and most convenient service I have gotten in my entire life. The assistance was perfectly unique and provided details for my ethics topic. Just what I needed. Thank you!',
    },
    {
      mark: '5',
      date: 'Apr 26, 2022',
      name: 'Grace J.',
      title: 'AMAZING',
      review:
        'It was such a pleasure having this experience. Especially along with the study stress I know some college students are going through. Surpassed my expectations and made me even understand the healthcare case study on a different level. Amazing, I highly recommend it!',
    },
    {
      mark: '5',
      date: 'Jun 7, 2022',
      name: 'Alyssa D.',
      title: 'GREAT EXPERIENCE',
      review:
        'I have a great experience with Nerdify, I have struggled to revise and check my culture paper and Nerdify helped me with that in a very short time. Definitely recommend it and I’ll need their help again in the future.',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      avatar: 'Kay W.png',
      recommends: true,
      date: 'Mar 19, 2022',
      name: 'Kay W.',
      review:
        'Excellent! Obviously, I can’t speak for every nerd but the nerd that helped me to prepare for History class did an amazing job. Will work with the Nerds again for sure!',
    },
    {
      avatar: 'Medylin K.png',
      recommends: true,
      date: 'Jul 8, 2022',
      name: 'Medylin K.',
      review:
        'Well, I can’t find words to express how happy and satisfied I am with such professionalism, and reasonably Nerdify has been in tutoring me on my nursing task. Honesty and being able to work with their customer is what really impressed me about Nerdify. I will recommend Nerdify to anyone out there seeking study help. I’m pretty sure they are smart in every other subject so feel free to give them the opportunity to assist you. God bless them all!',
    },
    {
      avatar: 'Stacy P.png',
      recommends: true,
      date: 'May 18, 2022',
      name: 'Stacy P.',
      review:
        'Very professional service! I struggled with philosophy assignment and work full time, I did not have the time to polish homework I had due soon. Nerds helped me to check the paper just perfectly, as well as provided special writing tips. I recommend Nerdify 100%!',
    },

    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};

// /tr-math-help
export const sliderReviewsItems5 = {
  Trustpilot: [
    {
      mark: '5',
      date: 'Jun 14, 2022',
      name: 'Jayb N.',
      title: 'EXCELLENT JOB',
      review:
        'They did an excellent job tutoring me in Geometry. I needed help with an assignment and they walked me through it the same day. The person I communicated with was kind, attentive, and professional! I have no complaints! I would highly highly recommend using Nerdify!',
    },
    {
      mark: '5',
      date: 'May 25, 2022',
      name: 'Omar',
      title: 'AMAZING SERVICE',
      review:
        'I’ve never seen such an amazing service when it comes to finding solutions for any subject, especially math. They explain your task and really go into detail. The response was very fast and the communication was very friendly. I would absolutely recommend them!',
    },
    {
      mark: '5',
      date: 'Sep 19, 2022',
      name: 'Angel M.',
      title: 'GREAT AND EASY SERVICE',
      review:
        'This is my first time using this service. The customer service was excellent and very thorough. They explained the process and always gave me feedback. My son needed help with a math assignment and got all questions well explained. I had no problem with the process. Super easy and the service overall was great. I will use them again!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      avatar: 'Sandra P.png',
      recommends: true,
      date: 'Apr 4, 2022',
      name: 'Sandra P.',
      review:
        'The second time I hire Nerdify. They have the best Math Nerds to explain Geometry topics and homework. Very professional, always on time, very quick on responding, and best prices available. I would recommend it to everyone!',
    },
    {
      avatar: 'Amanda.png',
      recommends: true,
      date: 'Jun 20, 2022',
      name: 'Amanda S.',
      review:
        'The communication is effective and polite. They texted me back instantly! Nerd has explained me how to solve my math problems. Will definitely use it later in the future when I have more math questions!!',
    },
    {
      avatar: 'Preya.png',
      recommends: true,
      date: 'Nov 11, 2022',
      name: 'Preya T.',
      review:
        'The best site ever! Nerdify saved me from failing my math class. I have been struggling with calculations for the longest and instead of stressing myself out, I went to Nerdify for a consultation with a tutor. The customer service was extremely helpful and considerate as well. I highly recommend them!',
    },

    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};


// /tr-natural-sciences-help
export const sliderReviewsItems6 = {
  Trustpilot: [
    {
      mark: '5',
      date: 'May 16, 2022',
      name: 'Lia F.',
      title: 'SAVE YOUR TIME',
      review:
        'I have used Nerdify over 3 times and haven’t been disappointed once! They have tutored me through the Science classes that aren’t the main focus of my major and freed up my time to put into my Business classes. So glad I found Nerdify!',
    },
    {
      mark: '5',
      date: 'Apr 8, 2022',
      name: 'Lina L.',
      title: 'EXCELLENT SERVICE',
      review:
        "I was skeptical at first but my friend's classmate said she used it for checking her biology lab reports and they did great so I used it for my microbiology lab reports. I requested to check my assignment and they saved me from 2 mistakes and provided tips on how to fix them! Excellent work, guys! Will use such service in future.",
    },
    {
      mark: '5',
      date: 'Aug 5, 2022',
      name: 'Nikki L.',
      title: 'BEST HELP',
      review:
        'I was struggling with chemistry homework and I have been out of college for a while. I was grateful to have found a Chemistry nerd to help me prepare for my class. They were quick to respond and pricing was extremely reasonable. I absolutely can recommend Nerdify!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      avatar: 'Jorge.png',
      recommends: true,
      date: 'Sep 19, 2022',
      name: 'Jorge S.',
      review:
        "I've heard a lot of good things about Nerdify and tried it on my own for Biology class. The quality of their work is university level. You will get help and then can solve any problem by yourself just on time. You got everything (quality, time, and most important chat live 24/7). Thanks, Nerdify Team!",
    },
    {
      avatar: 'Rasay.png',
      recommends: true,
      date: 'Apr 16, 2022',
      name: 'Rasay D.',
      review:
        'I needed help in my Chemistry class this semester to understand and submit great assignment. I liked their services, they were very prompt and professional. I’d be using them again for sure. I’d definitely recommend them to any student who needs guidance and tips on completing projects, lab reports, etc. Thank you!',
    },
    {
      avatar: 'Charla.png',
      recommends: true,
      date: 'May 23, 2022',
      name: 'Charla P.',
      review:
        'I had such an amazing experience they saved me from the stress of working and working on my Physics assignment. They responded quickly and were so pleasant. They teached me how to use Physics software efficiently so I could speed up my calculations. I would recommend Nerdify to some people who need help with any kind of homework problem!',
    },

    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};

// /tr-social-sciences-help
export const sliderReviewsItems7 = {
  Trustpilot: [
    {
      mark: '5',
      date: 'Sep 14, 2022',
      name: 'Alex D.',
      title: 'LIFESAVERS',
      review:
        "This has been an absolute lifesaver! They were so prompt in getting back to me and tutor explained me the law task at hand. I fell short on time for an assignment that I had to turn in while working on two other projects and couldn't juggle all three in time for the deadline and they stepped up and helped me out with data collection in a pinch! I will continue to use this service and absolutely recommend this to all of my friends!",
    },
    {
      mark: '5',
      date: 'Aug 7, 2022',
      name: 'Lia V.',
      title: 'USER EXPERIENCE',
      review:
        'I needed last-minute assistance polishing a paper for a sociology class and I was beyond pleased with the recommendations I received, especially in such a short amount of time. The nerd was super friendly with quick replies and was able to answer all of my questions. Very good first experience using this resource! Highly recommend.',
    },
    {
      mark: '5',
      date: 'May 12, 2022',
      name: 'William E.',
      title: 'TRUSTWORTHY SERVICE',
      review:
        'Seriously, these guys are good! They have helped me pass political science class. I have full trust in their tutors. They have not let me down as of yet. Plus they are super quick to respond and are very engaging and asked all the right questions to prepare for the next session with me. Top-notch!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      avatar: 'Sharon.png',
      recommends: true,
      date: 'May 16, 2022',
      name: 'Sharon R.',
      review:
        'Nerdify tutors have always been extremely helpful with my history and psychology tasks. 24/7 customer service is a bonus, especially if you need last-minute help. I would recommend it to everyone!',
    },
    {
      avatar: 'Luis.png',
      recommends: true,
      date: 'Apr 19, 2022',
      name: 'Luis W.',
      review:
        'I have used this company twice now - one for an English class, the other for sociology and both results by far exceeded my expectations. The quality of writing tips and advice on paper structuring was well worth the cost. I truly value my privacy and the process from start to finish was extremely confidential, personal, and easy. I would absolutely recommend Nerdify!',
    },
    {
      avatar: 'Diana.png',
      recommends: true,
      date: 'Sep 5, 2022',
      name: 'Diana O.',
      review:
        'Everyone that I talked to was super friendly and helpful. The turor did everything in a timely manner and were open to listening to any concerns, answered all questions that I had with my political science task. Although we only communicated through text it felt like I was talking to a real person. I’m so glad I found this site to help in hard study times. Highly recommend!',
    },

    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};

// /tr-statistics-help
export const sliderReviewsItems8 = {
  Trustpilot: [
    {
      mark: '5',
      date: 'Sep 9, 2022',
      name: 'Scott F.',
      title: 'WONDERFUL SERVICE',
      review:
        'Nerdify is a wonderful website to use if you need extra help. I am very satisfied with the work I have done on my statistics project under their guidance! I refer them to everyone I know who needs some help completing projects in a timely manner!',
    },
    {
      mark: '5',
      date: 'Apr 29, 2022',
      name: 'Hanna M.',
      title: 'UNSTUCK WITH NERDIFY',
      review:
        'Nerdify is seriously one of the best things I’ve ever used! My first time using Nerdify, they tutored me on my statistics excel and answered every question I had. They even included discounts! Highly recommend using Nerdify if you ever get stuck with your statistics!',
    },
    {
      mark: '5',
      date: 'May 12, 2022',
      name: 'Michelle W.',
      title: 'MY EXPERIENCE',
      review:
        "My overall experience with Nerdify was excellent! My Nerd's response was always on time and he explained to me how to solve my statistics problem. The communication was excellent. I would surely remember Nerdify was my friends and family.",
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  /*
  Google: [
    {
      "mark": "5",
      "date": "Mar 20, 2023",
      "name": "Dave Latchman",
      "review": "I love Nerdify. They are very polite and quickly reply to any questions. They provide efficient help with any tasks on the agreed time."
    },
    {
      "mark": "5",
      "date": "Mar 7, 2023",
      "name": "Melody Bony",
      "review": "My communication with Nerdify from beginning to end was amazing! I’ve received overall great customer service and quick feedback from my Nerd and task manager. The communication was clear and effective. I've bought a premium subscription as a result and will continue to use this service."
    },
    {
      "mark": "5",
      "date": "Feb 28, 2023",
      "name": "Morgan Appel",
      "review": "Don’t get me wrong, but I'm thankful to these guys for helping me boost my study results. Their support helped me to pull myself together and get things done. I understood my Math course better which helped me to complete it and graduate. I will recommend this service to everyone."
    },
    {
      "mark": "5",
      "date": "Feb 10, 2023",
      "name": "Faila Theodord",
      "review": "They are my lifesavers! Thanks for helping me out!"
    },
    {
      "mark": "5",
      "date": "Feb 4, 2023",
      "name": "Ava Kayes",
      "review": "Nerdify is one of the best websites I have used. Due to health issues, I had some problems with my study process, so I reached out to a few websites that didn’t help me. Then I reached out to Nerdify and they did help! They not only provided me with guidelines and examples that helped me to complete my tasks, but also they were very kind to me, and offered me a discount too, just to help me out. I completed everything on time. I can’t thank them enough. Thank you, guys!"
    },
    {
      "mark": "5",
      "date": "Jan 19, 2023",
      "name": "Catherine Cornwell",
      "review": "Nerdify is an extremely helpful service for students. Communication is personalized, timely, and specific. Great job!"
    },
    {
      "mark": "5",
      "date": "Jan 11, 2023",
      "name": "Laylo Mac",
      "review": "They did amazing. At first, I was scared that it was just a scam. But when I gave it a try and started working with the Nerd they assigned to me, I was impressed. The service is absolutely wonderful. Very fast, efficient, and concise. Exactly what I needed."
    },
    {
      "mark": "5",
      "date": "Dec 21, 2022",
      "name": "Samantha Cavaliere",
      "review": "Honestly, my experience is 10/10. Very helpful when I was struggling with my task and was overwhelmed. The quality of work is worth the price. And they are a very good place when you need help with your studies."
    }
  ],
*/

  Facebook: [
    {
      avatar: 'Kliber.png',
      recommends: true,
      date: 'Sep 21, 2022',
      name: 'Kliber S.',
      review:
        'First, they were very quick to reply, very polite, and very professional. I am thankful they were able to help me out with my statistics problem and check calculations. They met and exceeded my expectations. Thank you.',
    },
    {
      avatar: 'Sara.png',
      recommends: true,
      date: 'Apr 12, 2022',
      name: 'Sara M.',
      review:
        'I have had a great experience with Nerdify, I have struggled to revise and check my statistics homework task and Nerdify helped me with that. Definitely recommend it and I’ll need their help again in the future!',
    },
    {
      avatar: 'Nicole.png',
      recommends: true,
      date: 'Mar 3, 2022',
      name: 'Nicole S.',
      review:
        'I had a professional service and I got what I asked. Communication was so quick and breezy. I would recommend it to some people who need help with their statistics assignments. But overall amazing service, will use it again!',
    },

    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};

// /tr-students-1
export const sliderReviewsItems9 = {
  ...commonReviews,

  Trustpilot: [
    {
      mark: "5",
      "date": "Sep 18, 2022",
      "name": "Berry G.",
      "title": "MY BEST EXPERIENCE SO FAR",
      review: "I was very skeptical about this site at first but honestly, I could not be happier. I decided to take a chance because I was struggling with an essay and time was passing fast. I found Nerdify and it was a quick and easy tutoring session. All of my questions were answered promptly and the payment process was easy and affordable.",
    },
    {
      mark: "5",
      "date": "Apr 6, 2022",
      "name": "Julie C.",
      "title": "PROFESSIONAL SERVICE",
      review: "One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school essays, job, and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.",
    },
    {
      mark: "5",
      "date": "Jun 12, 2022",
      "name": "Nerd Lover",
      "title": "5 OUT OF 5 FROM ME!",
      review: "Nerdify has so far helped me with checking 2 writing tasks and both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!",
    },
    {
      name: "Yusraa K.",
      mark: "5",
      date: "Mar 11, 2020",
      title: "HAPPY WITH THE RESULTS",
      review: "It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!",
    },
    {
      name: "Wolfy",
      mark: "5",
      date: "Feb 28, 2020",
      title: "WOULD RECOMMEND",
      review: "Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.",
    },
    {
      name: "Kye N.",
      mark: "5",
      date: "Feb 20, 2020",
      title: "SIMPLY GREAT",
      review: "I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.",
    },
    {
      name: "B. Martinez",
      mark: "5",
      date: "Feb 4, 2020",
      title: "NEVER FAILED ME",
      review: "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: "Daniel",
      mark: "5",
      date: "Jan 30, 2020",
      title: "TIMELY AND FRIENDLY",
      review: "Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!",
    },
  ],

  Facebook: [
    {
      "name": "Mariana F.",
      "avatar": "10@2x.jpg",
      recommends: true,
      "date": "Apr 14, 2022",
      "review": "Nerdify is the best thing I found during my college years. They are always there to help you with any essay issue. Nerds make everything possible to guide you to a prompt solution and never disappoint. I would recommend it to everyone!"
    },
    {
      "name": "Mark C.",
      "avatar": "01@2x.jpg",
      recommends: true,
      "date": "May 10, 2022",
      "review": "I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize my essay. I contacted them via an ad I saw on Instagram and they were happy to help. I had some trouble with the instructions followed, as they were not initially full, but they gladly helped me to understand it all within 12 hours of my request. It is perfect. Thank you so much again. You get what you pay for!"
    },
    {
      "name": "Eric S.",
      "avatar": "02@2x.jpg",
      recommends: true,
      "date": "July 20, 2022",
      "review": "What an incredible service. Nerdify has gotten me out of two really tight spots! Writing tips they provided were of really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!"
    },
    {
      "name": "Tom D.",
      "avatar": "03@2x.jpg",
      recommends: true,
      "date": "Mar 28, 2020",
      "review": "I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.",
    },
    {
      "name": "Reyansh C.",
      "avatar": "04@2x.jpg",
      recommends: true,
      "date": "Mar 25, 2020",
      "review": "I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!",
    },
    {
      "name": "Anita T.",
      "avatar": "06@2x.jpg",
      recommends: true,
      "date": "Mar 3, 2020",
      "review": "No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.",
    },
    {
      "name": "Karen G.",
      "avatar": "07@2x.jpg",
      recommends: true,
      "date": "Feb 27, 2020",
      "review": "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      "name": "Pamela T.",
      "avatar": "08@2x.jpg",
      recommends: true,
      "date": "Feb 23, 2020",
      "review": "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],
};

// /tr-students-2
export const sliderReviewsItems10 = {
  ...commonReviews,

  Trustpilot: [
    {
      name: 'Isaac N.',
      mark: '5',
      date: 'Sep 27, 2022',
      title: 'MY BEST EXPERIENCE SO FAR',
      review:
        'I was skeptical at first when I heard about this website but honestly, I could not be happier. All I had to do was send them a screenshot, and they immediately responded and explained the topic. Anyone who’s in a bind and needs some help could seriously use this website and benefit from it.',
    },
    {
      name: 'Julie C.',
      mark: '5',
      date: 'Apr 6, 2022',
      title: 'PROFESSIONAL SERVICE',
      review:
        'One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school papers, job, and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
    },
    {
      name: 'Nerd Lover',
      mark: '5',
      date: 'Jun 12, 2022',
      title: '5 OUT OF 5 FROM ME!',
      review:
        'Nerdify has so far helped me with checking 2 writing tasks and both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  Facebook: [
    {
      name: 'Darius S.',
      avatar: 'Darius.png',
      recommends: true,
      date: 'Sep 13, 2022',
      review:
        'Nerdify helped me in a real crunch time situation. I told them my problem with the paper I was writing and they basically explained me how to fix it in a short time. I highly recommend people use Nerdify guidance when they are struggling with papers or any other tasks as well.',
    },
    {
      name: 'Nick T.',
      avatar: 'Nick.png',
      recommends: true,
      date: 'Apr 19, 2022',
      review:
        'At first, I was hesitant to use Nerdify but they have been such a great help. Being a first-time college student I was overwhelmed by the amount of work from each class. Nerdify did a great job with every paper question I had and provided high-quality advice. I would highly recommend this service!',
    },
    {
      name: 'Eric S.',
      avatar: '02@2x.jpg',
      recommends: true,
      date: 'July 20, 2022',
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! Writing tips they provided were of really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],
};

// /tr-students-3
export const sliderReviewsItems11 = {
  ...commonReviews,

  Trustpilot: [
    {
      name: 'Saida S.',
      mark: '5',
      date: 'Sep 19, 2022',
      title: 'MY EXPERIENCE',
      review:
        'My experience with Nerdify has been great from the jump. They find a tutor to explain your homework task, and are ready to assist in any way needed. It really is a stress reliever to know that I can count on the help of Nerdify. Thank you so much!',
    },
    {
      name: 'Mari M.',
      mark: '5',
      date: 'May 9, 2022',
      title: 'THEY ARE PROFESSIONALS',
      review:
        'This site was recommended to me but I was still a little hesitant at first. Surprisingly the entire experience was quite lovely. They were very clear and quick responses. All my homework questions were addressed and explained quickly, professionally, and very friendly. I will absolutely recommend this to everyone who needs help with homework!',
    },
    {
      name: 'Ali A.',
      mark: '5',
      date: 'Jun 20, 2022',
      title: 'ANY TYPE OF HELP',
      review:
        'I’ve been using Nerdify a few times and I have no complaints so far! They answer pretty quickly, the price is reasonable and I am definitely going to use their help in the future. If you need someone to clarify how to do presentation, discussion, assignment, article, or any homework, you could rely on Nerdify!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  Facebook: [
    {
      name: 'Dee T.',
      avatar: 'Dee.png',
      recommends: true,
      date: 'May 19, 2022',
      review:
        'Nerdify is truly the best, very helpful fast replies, and always tries to guide you to the solution of your homework task as quickly as they can, it’s definitely a quality experience that you will enjoy and won’t regret!',
    },
    {
      name: 'Brittany D.',
      avatar: 'Brittany.png',
      recommends: true,
      date: 'Oct 10, 2022',
      review:
        'What I really like about Nerdify is that they are always friendly and do their best to help me out with my homework. They are very understanding and response times are very quick. Prices and affordable as well. Should you be looking for homework guidance - hire the best Nerds here to get reliable help with tasks.',
    },
    {
      name: 'Sundus A.',
      avatar: 'Sundus.png',
      recommends: true,
      date: 'Apr 20, 2022',
      review:
        'Lifesavers! You get to be very specific about the homework topic you need help with! They reply really quickly and do their best to schedule tutoring session asap! Highly would recommend it if you have difficult professors and tasks! Love them!',
    },
    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};

// /tr-students-4
export const sliderReviewsItems12 = {
  ...commonReviews,

  Trustpilot: [
    {
      name: 'Amaris M.',
      mark: '5',
      date: 'Jun 8, 2022',
      title: 'MY BEST EXPERIENCE',
      review:
        'I had one of the largest assignments due that I have ever written. The window to get it done was very short in addition to working full-time with young children I was exhausted. I reached Nerdify for help with preliminary research and data collection to save time. They were very quick in their responsiveness in addition to being very professional. Anytime I reached out via text there was again a quick answer. I recommend anyone who is overwhelmed with tasks.',
    },
    {
      name: 'Siyanne F.',
      mark: '5',
      date: 'Apr 24, 2022',
      title: 'SERVICE EXCELLENCE',
      review:
        'I am extremely happy with the service I have received. Nerdify was really helpful and the responses to my questions were all answered in the quickest time. Very professional, very efficient, and well organized. After all, I finished my assignment perfectly I’m beyond grateful. Thank you, guys!',
    },
    {
      name: 'Ali A.',
      mark: '5',
      date: 'Jun 20, 2022',
      title: 'ANY TYPE OF HELP',
      review:
        'I’ve been using Nerdify a few times and I have no complaints so far! They answer pretty quickly, the price is reasonable and I am definitely going to use their help in the future. If you need someone to clarify how to do presentation, discussion, assignment, article, or any homework, you could rely on Nerdify!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  Facebook: [
    {
      name: 'Heather D.',
      avatar: 'Heather.png',
      recommends: true,
      date: 'July 18, 2022',
      review:
        "Had an assignment where I was in a pinch and needed input so I Googled who may be able to help. Nerdify popped up and I went for it! The nerd assigned to my task did a great job explaining and interpreting concepts. I will most definitely use this service again. I'm sure if others are in the same boat they may not be aware of it either, and may even be apprehensive, but you will not regret it!",
    },
    {
      name: 'Kathy G.',
      avatar: 'Kathy.png',
      recommends: true,
      date: 'Nov 14, 2022',
      review:
        'I would definitely recommend Nerdify. They were polite and helped me understand my assignment before my deadline. I loved how they incorporated every little detail and requirement I specified in. They were a great help and will be using their services again.',
    },
    {
      name: 'Joseph A.',
      avatar: 'Joseph A..png',
      recommends: true,
      date: 'Sep 21, 2022',
      review:
        'I literally had to do an assignment and was on a deadline. They helped me with data collection and research so that I could focus on the analysis itself. I would recommend them to any struggling student that needs extra help to collect data for their assignments. Thank you so much!',
    },
    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],
};



// /tr-students-5
export const sliderReviewsItems13 = {
  ...commonReviews,

  Trustpilot: [
    {
      name: 'Elaine C.',
      mark: '5',
      date: 'Apr 4, 2022',
      title: 'MY FIRST EXPERIENCE',
      review:
        'This is the first time I’ve ever used this website! Thank you so much for helping me, I was struggling and nervous about how to get my project done. I’m so happy to be able to find this website because Nerds explained everything to me perfectly and helped with initial research!',
    },
    {
      name: 'Nick P.',
      mark: '5',
      date: 'Oct 6, 2022',
      title: 'THEY WORTH YOUR TIME',
      review:
        'People if you want trustworthy, fast, and intelligent people to help you improve your writing, then this site you can trust. They really worth your time and they will provide quality work valuing your money spent. Their price is reasonable. I would absolutely recommend Nerdify!',
    },
    {
      name: 'Ali A.',
      mark: '5',
      date: 'Jun 20, 2022',
      title: 'ANY TYPE OF HELP',
      review:
        'I’ve been using Nerdify a few times and I have no complaints so far! They answer pretty quickly, the price is reasonable and I am definitely going to use their help in the future. If you need someone to clarify how to do presentation, discussion, assignment, article, or any homework, you could rely on Nerdify!',
    },
    ...commonReviews.Trustpilot,
  ],

  Facebook: [
    {
      name: 'Rebeca G.',
      avatar: 'Rebecca.png',
      recommends: true,
      date: 'May 14, 2022',
      review:
        'They’re the best! They respond to you back within minutes. I’m super happy that I received writing tips while summarizing my article. It was hard for me not to write my opinion on the topic but really write a summary. Their nerds are responsible and professional. Definitely will contact them back!',
    },
    {
      name: 'Jaya L.',
      avatar: 'Jaya.png',
      recommends: true,
      date: 'Sep 5, 2022',
      review:
        "Nerdify came in very handy. Was really struggling to keep up with my deadlines. I wasn't sure how these guys could help me with my project, but Nerds have greatly assisted me with the choice of research methodology and frameworks for analysis. They were so helpful throughout the whole process. I would recommend it to everyone!",
    },
    {
      name: 'Kain B.',
      avatar: 'Kain.png',
      recommends: true,
      date: 'Mar 26, 2022',
      review:
        'This service has the best customer service I have ever experienced. I feel like I am talking to a friend that knows me and my writing needs. The first time I used Nerdify was when I was struggling with annotated bibliography, and the second time with the thesis statement. I had a million questions and the person answered each of those questions with patience and care. I am so happy that you guys care so much for your customers. Thank you!',
    },
    ...commonReviews.Facebook,
  ],
};

// /tr
export const sliderReviewsItems14 = {
  ...commonReviews,

  Trustpilot: [
    {
      mark: '5',
      date: 'Sep 18, 2022',
      name: 'Berry G.',
      title: 'MY BEST EXPERIENCE SO FAR',
      review:
        'I was very skeptical about this site at first but honestly, I could not be happier. I decided to take a chance because I was struggling with an essay and time was passing fast. I found Nerdify and it was a quick and easy process. All of my questions were answered promptly and the payment process was easy and affordable. I was able to give them notes and pictures of exactly what I needed and they delivered!',
    },
    {
      mark: '5',
      date: 'Apr 6, 2022',
      name: 'Julie C.',
      title: 'PROFESSIONAL SERVICE',
      review:
        'One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school, job and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
    },
    {
      mark: '5',
      date: 'Jun 12, 2022',
      name: 'Nerd Lover',
      title: '5 OUT OF 5 FROM ME!',
      review:
        'Nerdify has so far helped me with checking 2 writing tasks and both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
    },
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      mark: '5',
      name: 'Wolfy',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  Facebook: [
    {
      name: 'Mariana F.',
      avatar: '10@2x.jpg',
      recommends: true,
      date: 'Apr 14, 2022',
      review:
        'Nerdify is the best thing I found during my college years. They are always there to help you and they never disappoint. Even if I have a trouble, they make everything possible to provide a prompt issue resolution. I would recommend it to everyone!',
    },
    {
      name: 'Mark C.',
      avatar: '01@2x.jpg',
      recommends: true,
      date: 'May 10, 2022',
      review:
        'I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize my essay. I contacted them via an ad I saw on Instagram and they were happy to help. I had some trouble with the instructions followed, as they were not initially full, but they gladly helped me to understand it all within 12 hours of my request. It is perfect. Thank you so much again. You get what you pay for!',
    },
    {
      name: 'Eric S.',
      avatar: '02@2x.jpg',
      recommends: true,
      date: 'July 20, 2022',
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! Writing tips they provided were of really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },

  ],
};
