import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import NotFound, { NotFoundIcon } from './../../../../themes/nerdify/components/NotFound';
import { NavLink } from 'react-router-dom'

import TextPage from './../TextPage';
import {REACT_APP_CONTACT_EMAIL} from "../../../../config";

export default () => (
  <TextPage className="reportAbuse" noWrap>
    <NotFound>
      <Holder className={'holder_1 not-found'}>
        <h1 className="h2">Oops..</h1>
        <h6 className="h6">We can't find the page you're looking for, please text us via <a className="a" href={`mailto:${REACT_APP_CONTACT_EMAIL}`}>{REACT_APP_CONTACT_EMAIL}</a>.</h6>
        <NotFoundIcon img={'404.svg'} />
        <NavLink className="button button__type5" to='/'>Back to homepage</NavLink>
      </Holder>
    </NotFound>
  </TextPage>
)
