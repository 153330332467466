import React, { Fragment } from 'react';

export { default as slider1Items_main } from './items/main';
export { default as slider1Items_for_students } from './items/for-students';
export { default as slider1Items_math_help } from './items/math-help';
export { default as slider1Items_statistics_help } from './items/statistics-help';
export { default as slider1Items_natural_sciences_help} from './items/natural-sciences-help';
export { default as slider1Items_business_help} from './items/business-help';
export { default as slider1Items_social_science_help} from './items/social-sciences-help';
export { default as slider1Items_finance_accounting_help} from './items/finance-accounting-help';
export { default as slider1Items_humanities_help} from './items/humanities-help';
export { default as slider1Items_english_literature_help} from './items/english-literature-help';
export { default as slider1Items_students_1} from './items/students-1';
export { default as slider1Items_students_2} from './items/students-2';
export { default as slider1Items_students_3} from './items/students-3';
export { default as slider1Items_students_4} from './items/students-4';
export { default as slider1Items_students_5} from './items/students-5';
export { default as slider1Items_students_6} from './items/students-6';
export { default as slider1Items_rt_students} from './items/rt-students';
export { default as slider1Items_cbl_math_help} from './items/cbl-math-help';

export const slider1Items1main_page = [
  {
    title: 'Market research',
    msg: 'I need a market research of metallurgy industry in Mexico.',
  },
  {
    title: 'Competitor analysis',
    msg: 'Could you please conduct a competitor analysis for my online shop? I need a full info on assortment, shipping, price, etc.',
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on Metal Fatigue in Aircraft Components',
  },
  {
    title: 'Consultation',
    msg: (
      <Fragment>
        Can someone advise me on the conceptual framework I need to use for my
        research paper?
      </Fragment>
    ),
  },
  {
    title: 'Expert feedback',
    msg: (
      <Fragment>
        I want your best Nerd to check my research materials and provide
        feedback. This task is very important for my class!
      </Fragment>
    ),
  },
  {
    title: 'SWOT analysis',
    msg: (
      <Fragment>
        Please put together a SWOT analysis for my product as soon as possible.
      </Fragment>
    ),
  },
];

export const slider1Items1 = [
  {
    title: 'Tutoring',
    msg: 'Please help me understand standard normal distribution! I need to find the probability using Z-score',
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Math Nerd to help me get ready for my Algebra test.',
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on Metal Fatigue in Aircraft Components',
  },
  {
    title: 'Consultation',
    msg: (
      <Fragment>
        Can someone advise me on the conceptual framework I need to use for my
        research paper?
      </Fragment>
    ),
  },
  {
    title: 'Expert feedback',
    msg: (
      <Fragment>
        I want your best Nerd to check my English paper and provide feedback.
        This task is very important for my class!
      </Fragment>
    ),
  },
  {
    title: 'Formatting advice',
    msg: (
      <Fragment>
        I'm almost done writing my research and need someone to check if I
        formatted it properly.
      </Fragment>
    ),
  },
];

export const slider1Items3 = [
  {
    title: 'Proofreading',
    msg: 'Need someone to check my language and punctuation!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting',
    msg: 'I am almost done writing my research paper and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, is it possible for you?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.',
  },
  {
    title: 'Proofreading',
    msg: 'I want your best Nerd to proofread an English paper for me. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const slider1Items4 = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand behavioral economics! This is the topic of my midterm paper.',
  },
  {
    title: 'Preparing for Class',
    msg: 'I really need a skilled tutor to help me customize survey templates for my research homework task.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?",
  },
  {
    title: 'Find a book',
    msg: 'Can someone advise me a list of literature that I can use for my research paper in Biology?',
  },
  {
    title: 'CV guidance',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Coaching',
    msg: 'I need to create slides for my TED talk on the importance of Philosophy. Do you have someone who can coach me?',
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Nerd to help me get ready for my writing test next week.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: 'I\'m almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const slider1Items5 = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand behavioral economics! This is the topic of my midterm paper.',
  },
  {
    title: 'Editing service',
    msg: 'I really need a skilled editor to help me customize survey templates for my research.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?",
  },
  {
    title: 'Find a book',
    msg: 'Can someone make a list of literature that I can use for my research paper in Biology?',
  },
  {
    title: 'Proofreading',
    msg: 'Need someone to check my language and punctuation!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting',
    msg: 'I am almost done writing my research paper and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, is it possible for you?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Proofreading',
    msg: 'I want your best Nerd to proofread an English paper for me. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const slider1Items5a = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand behavioral economics! This is the topic of my midterm paper.',
  },
  {
    title: 'Editing service',
    msg: 'I really need a skilled editor to help me customize survey templates for my research.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?",
  },
  {
    title: 'Find a book',
    msg: 'Can someone make a list of literature that I can use for my research paper in Biology?',
  },
  {
    title: 'Proofreading',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk on the importance of Philosophy edu by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting',
    msg: 'I am almost done writing my research paper and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, is it possible for you?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Proofreading',
    msg: 'I want your best Nerd to proofread an English paper for me. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems6 = [
  {
    title: 'Essay topic explanation',
    msg: 'Please help me understand Behavioral Economics! This is the topic of my midterm essay.',
  },
  {
    title: 'Preparing for Class',
    msg: 'I really need a skilled tutor to help me customize survey templates for my research paper.',
  },
  {
    title: 'Writing tips',
    msg: 'I need to format my History essay according to MLA. Can you show me example how to do that?',
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use for my Psychology essay?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk on the importance of Philosophy edu by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly.",
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems7 = [
  {
    title: 'Paper topic explanation',
    msg: 'Please help me understand Behavioral Economics! This is the topic of my midterm paper.',
  },
  {
    title: 'Preparing for Class',
    msg: 'I really need a skilled tutor to help me customize survey templates for my research paper.',
  },
  {
    title: 'Writing tips',
    msg: 'I need to format my History paper according to MLA. Can you show me example how to do that?',
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use for my research paper in Psychology?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk on the importance of Philosophy edu by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly.",
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems8 = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand Behavioral Economics! This is the topic of my homework assignment.',
  },
  {
    title: 'Preparing for Class',
    msg: 'I really need a skilled tutor to help me customize survey templates for my research homework task.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business assignment. Can you help me with the framework?",
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I should study to solve my homework problem?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk on the importance of Philosophy edu by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly.",
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems9 = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand Behavioral Economics! This is the topic of my midterm project.',
  },
  {
    title: 'Writing Tips',
    msg: 'I really need a skilled writer to explain me how to present ideas in a speech and check it.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business report. Can you help me with the framework?",
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature with the best practices that I can use for writing my thesis statement?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk on the importance of Philosophy edu by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly.",
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems10 = [
  {
    title: 'Math tutoring',
    msg: 'Please help me understand differential calculus! I need to find the derivative of a function.',
  },
  {
    title: 'On-demand Math help',
    msg: 'I really need a skilled Math Nerd to help me get ready for my Algebra test.',
  },
  {
    title: 'Math problems',
    msg: 'I need someone to explain to me how to solve my Math problem. I got stuck halfway through my calculations.',
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to complete my Math paper?"',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: 'I\'m almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems11 = [
  {
    title: 'Statistics tutoring',
    msg: 'Please help me understand standard normal distribution! I need to find the probability using Z-score.',
  },
  {
    title: 'On-demand Stats help',
    msg: 'I really need a skilled Nerd to help me get ready for my Statistics test.',
  },
  {
    title: 'Statistics problems',
    msg: 'I need someone to explain to me how to solve my Statistics problem. I got stuck halfway through my calculations.',
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to complete my Statistics assignment?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly.",
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems_natural_sciences_help = [
  {
    title: 'Physics tutoring',
    msg: 'Please help me understand the second law of thermodynamics. I need to predict the direction of processes.',
  },
  {
    title: 'Help with Biology',
    msg: 'I really need a skilled Nerd to help me get ready for my Biology test.',
  },
  {
    title: 'Chemistry problems',
    msg: 'I need someone to explain to me how to solve my Chemistry problem. I got stuck halfway through my calculations.',
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to finish writing my Science assignment?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: 'I\'m almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems_business_help = [
  {
    title: 'Topic explanation',
    msg: "Please help me understand the alternative dispute resolution from Business Law. Why it doesn't work and why it does.",
  },
  {
    title: 'Strategic analysis help',
    msg: 'I need someone to help me with SWOT and PESTEL analysis. I got stuck halfway through my Management task.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?",
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to finish writing my Marketing research?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation!',
  },
  {
    title: 'Coaching',
    msg: 'I need to create slides for my TED talk on the importance of Philosophy. Do you have someone who can coach me?',
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Nerd to help me get ready for my Business test next week.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV guidance',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: 'I\'m almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];


export const sliderItems_social_sciences_help = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand the applicable regulatory laws in my Law case study.',
  },
  {
    title: 'Preparing for Class',
    msg: 'I really need a skilled tutor to help me customize survey templates for my Psychology research.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Political Science class. Can you help me with the framework?",
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to finish writing my Sociology paper?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation!',
  },
  {
    title: 'Coaching',
    msg: 'I need to create slides for my TED talk on the importance of Philosophy. Do you have someone who can coach me?',
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Nerd to help me get ready for my Business test next week.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV guidance',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: 'I\'m almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems_finance_accounting_help = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand Behavioral Economics! This is the topic of my midterm paper.',
  },
  {
    title: 'Accounting problems',
    msg: 'I need someone to explain to me how to solve my Accounting problem. I got stuck halfway through my calculations.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Finance class. Can you help me with the framework?",
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to finish my Economics project?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Coaching',
    msg: 'I need to create slides for my TED talk on the importance of Philosophy. Do you have someone who can coach me?',
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Nerd to help me get ready for my Business test next week.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV guidance',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: 'I\'m almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems_humanities_help = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand Philosophical problem analysis in Religion & Theology! This is the topic of my midterm paper.',
  },
  {
    title: 'Writing tips',
    msg: 'I need to format my History essay according to MLA. Can you show me example how to do that?',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Ethics class. Can you help me with the framework?",
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to finish writing my Nursing paper?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Coaching',
    msg: 'I need to create slides for my TED talk on the importance of Philosophy. Do you have someone who can coach me?',
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Nerd to help me get ready for my Business test next week.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV guidance',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly.",
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const sliderItems_english_literature_help = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand the language evaluation of the text! I need to use it in my literature review.',
  },
  {
    title: 'Writing Tips',
    msg: 'I really need a skilled writer to explain me how to present ideas in a speech and check it.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Linguistics class. Can you help me with the framework?",
  },
  {
    title: 'Help find sources',
    msg: 'Can someone advise me a list of literature that I can use to finish writing my English paper?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly.",
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

export const slider1ForBusinessesItems = [
  {
    title: 'Market research',
    msg: 'I need a barbershop services research in Nebraska with a detailed study of the competitive landscape.',
  },
  {
    title: 'Elevator speech',
    msg: 'Can someone prepare an elevator speech for my startup? It should cover all benefits and highlight growth potential for investors.',
  },
  {
    title: 'Competitor analysis',
    msg: 'Could you please conduct a competitor analysis for my online shop? I need a full info on assortment, shipping, price, etc.',
  },
  {
    title: 'Registration Guidance',
    msg: 'I want someone to help me make a monthly content plan for social media for my brand.',
  },
  {
    title: 'Presentation slides',
    msg: 'I got stuck picking the right format for my presentation, do you have someone who can help?',
  },
  {
    title: 'Survey results',
    msg: 'Please find me a Nerd who can showcase the survey findings in a visual format!',
  },
];

export const slider1ForAcademiaItems = [
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on metal fatigue in aircraft components.',
  },
  {
    title: 'Find science magazine',
    msg: 'I need to have the list of Astrophysics Magazines where I can publish results of my comet trajectory research.',
  },
  {
    title: 'Proofread the text',
    msg: 'Can someone proofread my article and make sure there are no grammar mistakes?',
  },
  {
    title: 'Consultation',
    msg: 'I want to conduct an email-survey for my study. I need someone to advise me how to find people who will agree to participate.',
  },
  {
    title: 'Topic list',
    msg: 'I want someone to help me complete a topic list for my undergraduate students.',
  },
  {
    title: 'Checking',
    msg: 'I made a speech draft for an international conference and I need a Nerd to check it.',
  },
];

export const slider1ItemsForTr = [
  {
    title: 'Tutoring',
    msg: 'Please help me understand standard normal distribution! I need to find the probability using Z-score'
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Math Nerd to help me get ready for my Algebra test.'
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on Metal Fatigue in Aircraft Components'
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on the conceptual framework I need to use for my research paper?'
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my research materials and provide feedback. This task is very important for my class!'
  },
  {
    title: 'Formatting advice',
    msg: 'I\'m almost done writing my research and need someone to check if I formatted it properly.'
  },
];

export const slider1ItemsRtStudents = [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand behavioral economics! This is the topic of my midterm paper.',
  },
  {
    title: 'Preparing for Class',
    msg: 'I really need a skilled tutor to help me customize survey templates for my research homework task.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?",
  },
  {
    title: 'Find a book',
    msg: 'Can someone advise me a list of literature that I can use for my research paper in Biology?',
  },
  {
    title: 'Checking',
    msg: 'Need someone to check my language and punctuation in a paper for my Sociology class!',
  },
  {
    title: 'Coaching',
    msg: 'I need to create slides for my TED talk on the importance of Philosophy. Do you have someone who can coach me?',
  },
  {
    title: 'Test preparation',
    msg: 'I really need a skilled Nerd to help me get ready for my writing test next week.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <Fragment>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </Fragment>
    ),
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <Fragment>
        Need an accounting homework help: a tutor to go though my calculations
        with me to make sure they're correct.
      </Fragment>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV guidance',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting advice',
    msg: 'I am almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <Fragment>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </Fragment>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, can you help me to find them?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <Fragment>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </Fragment>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];
