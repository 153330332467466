import React from 'react';
import classnames from 'classnames';

import Partners, {
  PartnersItem,
  PartnersList,
} from '../../../../base_components/Partners';

import './index.css';

export default ({ className, children }) => {
  return <Partners className={classnames('partners_1', className)}>
    {children}
  </Partners>
}

export {
  PartnersItem,
  PartnersList
}
