import React, { Component } from 'react';
import { useSelector } from 'react-redux';
import Icon from './../Icons';

import classnames from 'classnames';

import './header.css';

export default class Header extends Component {
  render() {
    const { className, ...rest } = this.props;

    return (
      <header className={classnames('header-main', className)} {...rest} />
    );
  }
}

export const HeaderIcon = (props) => {
  //const locale = useSelector((state) => state.locales.locale);
  //const iconName = locale === 'au' ? 'logo-au' : 'logo-us';
  const iconName = 'logo-us';
  const newProps = { ...props, iconName };

  return (
    <Icon
      className={classnames('svg-icon logo-icon', {
        [`logo-icon_${props.theme}`]: props.theme,
      })}
      {...newProps}
    />
  );
};

export const Header1 = (props) => <Header className={'header_1'} {...props} />;
export const Header2 = (props) => <Header className={'header_2'} {...props} />;
export const Header3 = (props) => <Header className={'header_3'} {...props} />;
export const Header4 = (props) => <Header className={'header_4'} {...props} />;
export const Header5 = (props) => <Header className={'header_5'} {...props} />;
export const HeaderClp3 = (props) => <Header className={'headerClp3'} {...props} />;
export const HeaderClp4 = (props) => <Header className={'headerClp4'} {...props} />;

export const HeaderBlog = (props) => (
  <Header className={'header_blog'} {...props} />
);



export const HeaderIconCA = () => {
  return (
    <img className="logo-icon" src={require('./img/logo-ca.svg')} alt={''}/>
  );
};
export const HeaderIconCALight = () => {
  return (
    <img className="logo-ca-icon-light" src={require('./img/logo-ca-light.svg')} alt={''}/>
  );
};


export const HeaderCA = (props) => <div className={classnames('header_ca',{ [`header_ca_${props.type}`]: props.type })} {...props} />;

