import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import { Header1, HeaderIcon } from '../../../../themes/nerdify/components/Header/index';
import Holder from '../../../../themes/nerdify/components/Holder/index';
import Content from '../../../../themes/nerdify/components/Content/index'

import { Hero5 } from '../../../../themes/nerdify/components/Hero/index';

import {Hiw1, HiwImg, HiwList, HiwItem, HiwText } from '../../../../themes/nerdify/components/Hiw/index';
import { Footer2 } from './../../blocks/Footer';
import {Slider2, SliderItem, SliderCarousel, SliderList, SliderArrowLeft, SliderArrowRight, SliderNav } from '../../../../themes/nerdify/components/Slider/index';
import { FacebookButton } from '../../../../themes/nerdify/components/Facebook/index'
import { Section3} from '../../../../themes/nerdify/components/Section/index';
import { Promo1 } from '../../../../themes/nerdify/components/Promo/index';
import { Info2 } from '../../../../themes/nerdify/components/Info/index';
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';

import { HnavMob2 } from '../../blocks/HnavMob';
import { Hnav3 } from "../../blocks/Hnav";

//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';

import LocalesPage from './../LocalesPage';
import {useMobile} from "../../../../hooks";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class NerdyBot extends Component {
  render() {
    return (
      <WithHook>
        {({
            isMobile
          }) => {

    return <LocalesPage {...this.props}>
      <div className="nerdy-bot">
      <Header1>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='nerdibot' />
          </NavLink>
          {isMobile ? <HnavMob2 /> : <Hnav3 />}
{/*
          <InputSelectLocales
            className='input-select__short'
            name='country'
            isError={false}
          />
*/}
        </Holder>
      </Header1>

      <Hero5 key='hero5'>
        <h1 className="h0 hero__title">Meet<br className='mobile-only'/> the Nerdy Bot</h1>
        <h2 className="h5 hero__subtitle">Get instant answers for your study questions from our AI-powered Bot for FREE</h2>
        <div><FacebookButton>Message Nerdy Bot</FacebookButton></div>
      </Hero5>

      <Content>
        <Info2>
          <p className="h5">
            All you need is  <span>LOVE</span> <br className="mobile-only"/>  Facebook Messenger!
          </p>
          <p className="h5">
            As  simple as it sounds.
          </p>
        </Info2>

        <Holder>
          <hr className="hr" />
        </Holder>

        <Hiw1>

          <h2 className='h3'>This is how it works</h2>

          <HiwList>
            <HiwItem>
              <HiwImg img={'hiw1'} />
              <HiwText>
                <p className="p">Send your question
                  in Messenger</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw6'} />
              <HiwText>
                <p className="p">Get an instant answer
                  from Nerdy Bot</p>
              </HiwText>
            </HiwItem>
            <HiwItem>
              <HiwImg img={'hiw17'} />
              <HiwText>
                <p className="p">Challenge Nerdy Bot every time you have a question</p>
              </HiwText>
            </HiwItem>
          </HiwList>
        </Hiw1>

        <Section3 items={6}>
          <Holder className='holder_1'>
            <h2 className='h1'>How can Nerdy Bot help you?</h2>
            <Slider2>
              <SliderNav
                items={['Solving equations', 'Looking up definitions', 'Plotting graphs', 'Finding info']}
              />
              <SliderCarousel>
                <SliderArrowLeft iconName={'slider-arrow-desk'} iconNameMob={'slider-arrow2-mob'}/>
                <SliderArrowRight iconName={'slider-arrow-desk'} iconNameMob={'slider-arrow2-mob'} />
                <SliderList>
                  <SliderItem img={'1-1-1'} alt={'Nerdify Bot can help you solve equations'} />
                  <SliderItem img={'2-2-2'} alt={'Nerdify Bot can look up definitions'} />
                  <SliderItem img={'3-3-3'} alt={'Nerdify Bot can plot graphs'} />
                  <SliderItem img={'4-4-4'} alt={'Nerdify Bot can help you find the info you are looking for'} />
                </SliderList>
              </SliderCarousel>
            </Slider2>
          </Holder>
        </Section3>

        <Press>
          <p className="p p__title press__title">Press about us</p>

          <PressList>
            <PressItem>
              <PressIcon iconName={'forbes'} />
            </PressItem>
            <PressItem>
              <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'wired'} />
              </a>
            </PressItem>
            <PressItem>
              <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'venturebeat'} />
              </a>
            </PressItem>
            <PressItem>
              <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'tech-co'} />
              </a>
            </PressItem>
            <PressItem>
              <a
                href='http://www.nextmedia-hamburg.de/fileadmin/user_upload/nextMedia.Kompass/Trend_des_Monats_PDF/2016/nextMedia.Kompass_Oktober_2016_fin.pdf' target="_blank" rel="noopener noreferrer">
                <PressIcon iconName={'next-media'} />
              </a>
            </PressItem>
          </PressList>
        </Press>

        <Promo1>
          <Holder className='holder_1'>
            <div className='promo-inner'>
              <h2 className="h1 promo__title">Try Nerdy Bot for FREE!</h2>
              <p className="p p__big promo__desc">Even if you don't need help now, <br /> Nerdy Bot will surely be helpful in future!</p>
              <FacebookButton>Message Nerdy Bot</FacebookButton>
              <i className="propmo__pic" />
            </div>
          </Holder>
        </Promo1>

      </Content>

      <Footer2 />
    </div>
    </LocalesPage>
        }}
      </WithHook>
    )
  }
}
