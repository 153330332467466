import React from 'react';

// /common reviews
export const commonReviews = {
  Trustpilot: [
    {
      name: 'Yusraa K.',
      mark: '5',
      date: 'Mar 11, 2020',
      title: 'HAPPY WITH THE RESULTS',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
    },
    {
      name: 'Wolfy',
      mark: '5',
      date: 'Feb 28, 2020',
      title: 'WOULD RECOMMEND',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
    },
    {
      name: 'Kye N.',
      mark: '5',
      date: 'Feb 20, 2020',
      title: 'SIMPLY GREAT',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
    },
    {
      name: 'B. Martinez',
      mark: '5',
      date: 'Feb 4, 2020',
      title: 'NEVER FAILED ME',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
    },
    {
      name: 'Daniel',
      mark: '5',
      date: 'Jan 30, 2020',
      title: 'TIMELY AND FRIENDLY',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
    },
  ],

  Facebook: [
    {
      name: 'Tom D.',
      avatar: '03@2x.jpg',
      recommends: true,
      date: 'Mar 28, 2020',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
    },
    {
      name: 'Reyansh C.',
      avatar: '04@2x.jpg',
      recommends: true,
      date: 'Mar 25, 2020',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
    },
    {
      name: 'Anita T.',
      avatar: '06@2x.jpg',
      recommends: true,
      date: 'Mar 3, 2020',
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    {
      name: 'Karen G.',
      avatar: '07@2x.jpg',
      recommends: true,
      date: 'Feb 27, 2020',
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    {
      name: 'Pamela T.',
      avatar: '08@2x.jpg',
      recommends: true,
      date: 'Feb 23, 2020',
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
  ],

  Sitejabber: [
    {
      avatar: '1.png',
      mark: '5',
      date: 'Sep 1, 2022',
      name: 'Chin M.',
      title: 'Great Help!',
      review:
        'Life sometimes gets hard, and we need a little help. Nerdify definitely helped me, and with due professionalism and quickness.',
    },
    {
      avatar: '2.png',
      mark: '5',
      date: 'Dec 29, 2021',
      name: 'Kenai F.',
      title: 'Good service',
      review:
        'They have absolutely amazing services, reasonable prices, and good communication. They helped me finish the project on time, no complaints.',
    },
    {
      avatar: '3.png',
      mark: '5',
      date: 'Dec 20, 2021',
      name: 'Janay L.',
      title: 'Such a lifesaver!',
      review:
        'Nerdify is truly a lifesaver. The staff you text with are patient and caring, and they clearly take their work seriously. The Nerd who helped with my task did their job to perfection. I recommend this website to anyone and everyone.',
    },
    {
      avatar: '4.png',
      mark: '5',
      date: 'Dec 15, 2021',
      name: 'Stefanie S.',
      title: '100% satisfied',
      review:
        'Nerdify is such a smart and helpful business! I was extremely surprised by how easy this entire process was. Not just that, but also how quickly they respond and find "Nerds" to work on the tasks! I also liked how they offer features to upgrade the quality of output, which I think is worth it! This website saved me a lot of time and stress. I also perform better because of the help I receive. :) I will recommend this to anyone struggling in any subject!',
    },
    {
      avatar: '5.png',
      mark: '5',
      date: 'Dec 8, 2021',
      name: 'Carrie S.',
      title: '5 stars',
      review:
        'Great experience. Awesome customer service. Very quick response time. We are very satisfied and will be repeat customers. Thank you!',
    },
    {
      avatar: '6.png',
      mark: '5',
      date: 'Dec 2, 2021',
      name: 'Jay C.',
      title: 'Experts in their fields',
      review:
        'Nerdify completed my order in 3-4 days for a cost that was less than a dinner out with my wife. Very thankful, as this class was far beyond my capabilities. I will use them again. If you need help, shoot Nerdify a text, put your efforts towards something you are good at, and let the professionals address the rest.',
    },
    {
      avatar: '7.png',
      mark: '5',
      date: 'Nov 11, 2021',
      name: 'Nikki J.',
      title: 'OMG! You guys rock!',
      review:
        "You all do not understand how happy and satisfied I am with the assistance I received from Nerdify. Not only did they do what was asked, but they also went above and beyond. It was perfect. First off, kudos to my Nerd - I wish I knew their name. Second, the communication is on point. These people quadruple check what you would like, and they also make sure that you are satisfied. I 1000% would recommend Nerdify. The amount of stress taken off my life because I received help from them is beyond belief. Thanks, Nerdify. I can't wait to work with you again. You guys rock!",
    },
    {
      avatar: '8.png',
      mark: '5',
      date: 'Feb 6, 2021',
      name: 'Rosa G.',
      title: 'The best tutors',
      review:
        "I took sessions from different teachers, but only Nerds managed to help me so effectively in my courses so, I got better ratings with no fuss. The best tutors are here, don't even hesitate. THANK YOU!",
    },
  ],
};
