import React, { Component } from 'react';
import classnames from 'classnames';

import InputMask from 'react-input-mask';
import Icon from '../Icons';

import { trackValues } from '../../../../lrtracker';

import * as utils from '../../../../utils';
import { IS_PROD } from '../../../../config';

import './index.css';

export default class FeedbackBlock extends Component {
  constructor(props) {
    super(props);

    this.handleOnChangePhone = this.handleOnChangePhone.bind(this);
    this.handleOnChangeDescription = this.handleOnChangeDescription.bind(this);
    //this.send = this.send.bind(this);
    //this._checkTimePassed = this._checkTimePassed.bind(this);
    //this._sentTextBack = this._sentTextBack.bind(this);
    this._validate = this._validate.bind(this);
    //this.handleFocus = this.handleFocus.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.timerId = undefined;

    this.state = {
      phone: '',
      isPhoneError: false,
      rate: 0,
      isRateError: false,
      description: '',
      isDescriptionError: false,

      FormSubmit: '',
      isFetching: false,
      isFocused: false,
      isValidPhone: false,
      testPhone: '',
    };
  }

  handleOnChangePhone(e) {
    this.setState({
      phone: utils.clearPhone(e.target.value),
      isPhoneError: false,
    });
  }

  handleOnChangeDescription(e) {
    this.setState({
      description: e.target.value,
      isDescriptionError: false,
    });
  }

  handleOnChangeRate(e) {
    this.setState({
      rate: e.target.value,
      isRateError: false,
    });
  }

  _validate() {
    let formError = false;
    if (!this.state.rate) {
      this.setState({ isRateError: true });
      formError = true;
    }
    if (this.state.phone.length !== 12) {
      this.setState({ isPhoneError: true });
      formError = true;
    }
    if (!this.state.description.replace(/\s/g, '')) {
      this.setState({ isDescriptionError: true });
      formError = true;
    }
    //let res = /^(\+1|\+6)[0-9]{3}[0-9]{3}[0-9]{4}$/.test(this.state.phone);
    //if (this.state.rate > 0 && this.state.phone.length === 12 && this.state.description.replace(/\s/g,'')) {
    if (!formError) {
      return true;
    } else {
      this.setState({
        formError: true,
      });
      return false;
    }
  }

  async handleFormSubmit(e) {
    e.preventDefault();

    const isValid = this._validate();

    if (!isValid) {
      return false;
    }

    await trackValues('reviewRating', this.state.rate, {
      context: {
        stage: 'landing-reviews',
        phone: this.state.phone,
        description: this.state.description,
      },
    });

    await fetch(
      IS_PROD
        ? 'https://connect.dsh-agency.com/api/webhooks/feedback/receive'
        : 'https://connect-stage.dsh-agency.com/api/webhooks/feedback/receive',
      {
        method: 'POST',
        body: JSON.stringify({
          phone: this.state.phone,
          feedback: this.state.rate,
          description: this.state.description,
          landing: window.location.origin + window.location.pathname,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    this.setState({ FormSubmit: 'sent' });
  }

  render() {
    return (
      <div className={classnames(this.props.className)}>
        <div className="holder">
          <h2 className="title">
            Please leave your honest feedback about Nerdify!
          </h2>
          <div className="subtitle">
            Our Quality Assurance team will
            <br className="mobile-only" /> review it asap and find a solution
            <br className="mobile-only" /> if you need one!
          </div>
          <form id="feedback-form" className="feedback-form">
            <div className="feedback-form-block">
              <div className="feedback-rate-block">
                <div className="feedback-label rate-label">
                  Rate your experience
                </div>
                <div
                  className={`rate-stars-radio ${
                    this.state.isRateError ? 'error' : ''
                  }`}
                  onChange={this.handleOnChangeRate.bind(this)}
                >
                  <input
                    className="rate-star-input"
                    type="radio"
                    id="rating-5"
                    name="feedback"
                    value="5"
                  />
                  <label className="rate-star-label last" htmlFor="rating-5">
                    <Icon className={`rate-star-img`} iconName="rate-star" />
                  </label>
                  <input
                    className="rate-star-input"
                    type="radio"
                    id="rating-4"
                    name="feedback"
                    value="4"
                  />
                  <label className="rate-star-label" htmlFor="rating-4">
                    <Icon className={`rate-star-img`} iconName="rate-star" />
                  </label>
                  <input
                    className="rate-star-input"
                    type="radio"
                    id="rating-3"
                    name="feedback"
                    value="3"
                  />
                  <label className="rate-star-label" htmlFor="rating-3">
                    <Icon className={`rate-star-img`} iconName="rate-star" />
                  </label>
                  <input
                    className="rate-star-input"
                    type="radio"
                    id="rating-2"
                    name="feedback"
                    value="2"
                  />
                  <label className="rate-star-label" htmlFor="rating-2">
                    <Icon className={`rate-star-img`} iconName="rate-star" />
                  </label>
                  <input
                    className="rate-star-input"
                    type="radio"
                    id="rating-1"
                    name="feedback"
                    value="1"
                  />
                  <label className="rate-star-label" htmlFor="rating-1">
                    <Icon className={`rate-star-img`} iconName="rate-star" />
                  </label>
                </div>
              </div>
              <div className="feedback-phone-block">
                <div className="feedback-label phone-label">Your phone</div>
                <div className="feedback-phone-input-wrapper">
                  {/*
              <input  className="inputmask feedback-phone-input required"
                     type="tel" placeholder="(XXX) XXX-XXXX"
                     value=""/>
*/}
                  <img
                    className="feedback-phone-country-img"
                    src={require('./img/us.svg')}
                    alt=""
                  />
                  <InputMask
                    id="feedback-phone-mask"
                    className={`feedback-phone-input ${
                      this.state.isPhoneError ? 'error' : ''
                    }`}
                    name="phone-mask"
                    type="tel"
                    mask="+1 (999) 999 - 9999"
                    value={this.state.phone} /*{phone}*/
                    maskChar="x"
                    maskPlaceholder={null}
                    //alwaysShowMask
                    placeholder={
                      '+1 (xxx) xxx - xxxx'
                    } /* {"+1 (xxx) xxx - xxxx".replace(/9/g, 'X')} */
                    onChange={this.handleOnChangePhone}
                    //onFocus={this.handleFocus}
                    //onBlur={this.handleFocus}
                  />

                  {/*<InputMask mask="99999-9999" maskChar={null} value={this.state.value} onChange={this.onChange} beforeMaskedValueChange={this.beforeMaskedValueChange} />;*/}
                </div>
                <input
                  className="feedback-input-hidden"
                  id="feedback-phone"
                  name="phone"
                  type="text"
                />
                {/*<input className="feedback-input-hidden" id="feedback-landing" name="landing" value="https://nerdifyit.com/nerdify-reviews"/>*/}
              </div>
            </div>
            <div
              className={`feedback-error-message ${
                this.state.FormSubmit === 'error' ? 'active' : ''
              }`}
            >
              Oops, something went wrong! Please try again.
            </div>
            <textarea
              name="description"
              id="feedback-description"
              className={`description-field ${
                this.state.isDescriptionError ? 'error' : ''
              }`}
              placeholder="Share any thoughts or concerns here"
              onChange={(e) => this.handleOnChangeDescription(e)}
              onFocus={this.handleOnChangeDescription}
            />
            <div className="feedback-submit-btn-wrapper">
              <button
                id="feedback-form-submit"
                className={`button__type17 feedback-submit-btn`}
                disabled={this.state.FormSubmit === 'sent'}
                type="submit"
                onClick={
                  this.state.FormSubmit ? undefined : this.handleFormSubmit
                }
              >
                {this.state.FormSubmit === 'sent'
                  ? 'Feedback Sent'
                  : 'Submit feedback'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
