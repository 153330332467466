import React, {

} from 'react';
import Slider from 'react-slick';
import {
  get
} from 'lodash';

import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

import './index.css';

export const HowSliderImg = ({ img }) => <img src={require(`./img/avatar/${img}.png`)} srcSet={require(`./img/avatar/${img}.png`) + ' 1x,' + require(`./img/avatar/${img}@2x.png`) + ' 2x'} alt={img} />

export const HowSliderList = (props) => {
  const { items, children } = props;
  const keys = Object.keys(items);
  const activeItem = get(props, 'pageCfg.activeItem', undefined) || props.activeItem || 'Students';
  const activeIndex = keys.findIndex(item => item === activeItem)
  const itemsKeys = Object.keys(items);

  return (
    <div className="how-slider__list-wrapper">
      <div className="how-slider__list" key="list">
        <Slider
          useTransform={false}
          infinite={false}
          slidesToShow={1}
          dots
          arrows={false}
          initialSlide={activeIndex}
          dotsClass={'how-slider__switch-btn'}
          customPaging={(i) => {
            return (
              <div
                key={itemsKeys[i]}
              >
                {itemsKeys[i]}
              </div>
            )
          }}
        >
          {itemsKeys.map((i) => {
            return (
              <div
                className="how-slider__list-item-holder"
                key={`how-slider__list-item-holder-${i}`}>
                {items[i].map((item, index) => {
                  return (
                    <div className="how-slider__list-item" key={`how-slider__list-item-${index}`}>
                      <div className='svg-wrapper'>
                        <LazyLoad height={74}>
                          {item.img}
                        </LazyLoad>
                      </div>
                      <div className="how-slider__list-item-text">
                        <div className="how-slider__name">{item.name}</div>
                        <div className="how-slider__msg">{item.text}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            )
          })}

        </Slider>
      </div>

      {children}
    </div>
  )
}

const HowSlider = ({
  className, children
}) => {
  return (
    <div className={classnames('how-slider', className)}>
      <div className='holder'>
        {children}
      </div>
    </div>
  )
}


export default HowSlider;
