import React, {Component} from 'react'
import classnames from "classnames";
import Icon from '../Icons';
import {withRouter} from 'react-router-dom';

import './back-link.css'

class BackLink extends Component {
  render () {
    const {className, path, phone} = this.props;

    const onBtnClick = () => {
      if (path && (path.includes('html') || path.includes('students.')) && typeof window !== 'undefined') {
        window.location.assign(path);
      }
      else if (path && phone) {
        try {
          this.props.history.push(new URL(path).pathname, {
            phone,
          })
        } catch(e) {
          this.props.history.goBack();
        }
      } else {
        this.props.history.goBack();
      }
    };

    return (
      <button className={classnames('back-link', className)} type="button" onClick={() => onBtnClick()}>
        <Icon iconName="arrow-left" className="arrow-left-icon" />
      </button>
    )
  }
}

export default withRouter(BackLink);
