import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import './index.css';

export default (props) => {
  const prohibitedTasksAnchorRef = useRef(null);
  useEffect(() => {
    if (prohibitedTasksAnchorRef.current && props.location.hash === '#prohibited-tasks') {
     prohibitedTasksAnchorRef.current.scrollIntoView(true);
    }
  }, []);
  return (
    <LocalesPage {...props}>
      <TextPage>
        <Holder className="holder_1 honor-page">

          <h1 className="h1 h1_1">The Honor Code</h1>

          <Link to="/report-abuse" className="button button__type8 button__top" type="button">report abuse</Link>

          <div className="h6">The Honor Code is a policy promoting Nerdify Platform usage integrity. It articulates our expectations of students, businesses, academia, and freelancers in establishing and maintaining the highest standards in academic and corporate work.</div>

          <div className="textPage-item">
            <h2 className="h2">Nerdify Honor Code</h2>
            <p className="p">Nerdify Platform does not condone and will not be involved in facilitating corporate or academic dishonesty, such as knowingly assisting students to cheat or obtain grades or degrees they have not earned, or assisting businesses and academics commit fraud.</p>
            <h3 className="p">
              <strong>This includes:</strong>
            </h3>
            <ul className="ul">
              <li>Cheating: Requesting or providing unauthorized outside help on an academic assignment;</li>
              <li>Impersonation: Assuming a student’s identity for any purpose;</li>
              <li>Plagiarism: Using the work of another person without proper attribution;</li>
              <li>Corporate fraud: Making false reports, faking the results of a research;</li>
              <li>Academic fraud: Assisting in forgery of academic degrees, falsification, i.e. data manipulation.</li>
            </ul>
            <p className="p">Freelancers shall not take tests nor complete any graded assignments for students. Freelancers shall not manipulate data or fake research results and reports for any purpose. Freelancers may not knowingly help a student, business employee or academia cheat, fake reports, commit an act of fraud, plagiarize, or engage in any action that violates the company or institution policies. Any evidence that a freelancer has knowingly facilitated fraud will be a cause for their immediate removal as a freelancer from the platform.</p>
            <p className="p">Nerdify Platform does not condone and will not be involved in facilitating any corporate dishonesty or corporate policies violation, such as knowingly assisting Users to commit fraud or contribute to any kind of illegal activity.</p>
            <p className="p">Any evidence that a freelancer has knowingly facilitated any kind of illegal activity will be cause for his or her immediate removal as a freelancer from the platform.</p>
          </div>


          <div className="textPage-item">
            <h2 className="h4">To Students</h2>
            <p className="p">Nerdify team understands the pressures and time constraints studying creates. However, the risk students take by violating their school's policies of academic integrity is not worth the reward. Copying solutions or requesting final answers promotes completion without comprehension, which is not supported on Nerdify Platform.</p>
            <ul className="ul">
              <li>Students are not allowed to use the Nerdify Platform for any kind of illegal activity or violations of any college policies.</li>
              <li>Students are not allowed to use any educational materials received from Freelancers as their own.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h2 className="h4">To Businesses</h2>
            <p className="p">At Nerdify we give you an opportunity to get professional help with your tasks from experts in their fields. However, you should remember that violating any corporate policy is prohibited on Nerdify Platform.</p>
            <ul className="ul">
              <li>Business representatives are not allowed to use the Nerdify Platform for any kind of illegal activity.</li>
              <li>Business representatives are not allowed to use freelancers to contribute to any kind of fraud activity.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h2 className="h4">To Academia</h2>
            <p className="p">Nerdify acknowledges that research is a huge contribution to the future of science, and is committed to providing all possible assistance to academics in order to achieve their career goals. However, we are strongly against data manipulation and faking research results.</p>
            <ul className="ul">
              <li>Academics are not allowed to use the Nerdify Platform for any kind of scientific misconduct, including plagiarism, fabrication or falsification of research data/results;</li>
              <li>Academics are not allowed to use freelancers to contribute to publication of fabricated scientific research articles.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h2 className="h4">To Freelancers</h2>
            <p className="p">At Nerdify Platform, we give you a great opportunity and place trust in you representing yourself as an expert to students, businesses, and academia using our platform. As a freelancer operating on Nerdify Platform you're required to uphold the following guidelines:</p>
            <ul className="ul">
              <li>Freelancers are not allowed to create or contribute to circumstances that would violate any college or corporate policies.</li>
              <li>Freelancers are not allowed to help create the aforementioned circumstances.</li>
              <li>Freelancers are not allowed to help students, business representatives or academics to cheat or commit fraud.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h2 className="h4">Contact Information Sharing</h2>
            <p className="p">Any Users of Nerdify Platform are not allowed to ask other Users to share their personal contacts. You should utilize the Nerdify Platform to communicate with other Users.</p>
          </div>


          <div className="textPage-item">
            <h2 className="h4" id="prohibited-tasks" ref={prohibitedTasksAnchorRef}>List Of Prohibited Task Requests</h2>
            <p className="p">Any User of Nerdify Platform that infringes the restrictions specified in our Honor Code will be banned and their account will be deactivated permanently with no option for recovery. Here is the list of requests that violate rules of Nerdify Platform (please be aware that the list is not exhaustive and should be used as a guide):</p>
            <ul className="ul">
              <li>Making fake financial reports;</li>
              <li>Creating CVs with fake job experience;</li>
              <li>Completing college/school homework tasks;</li>
              <li>Impersonating a student’s identity for any purpose;</li>
              <li>Preparing or completing full assignments for students to present as their own work;</li>
              <li>Getting answers for tests, exams, labs when instructed by professor or institution not to use outside help;</li>
              <li>Forging documents of any type;</li>
              <li>Ghostwriting of dissertations, theses and term papers;</li>
              <li>Fabricating data, information, or citations;</li>
              <li>and other tasks that violate any of college or corporate policies.</li>
            </ul>
          </div>

          <div className="textPage-item">
            <h2 className="h4">Honor Code Abuse</h2>
            <p className="p">If you become aware of any case of Nerdify Platform Honor Code abuse, please, immediately contact us to report the abuse.</p>
          </div>

          <div className="textPage-item">
            <Link to="/report-abuse" className="button button__type8 button__bottom button-honnor" type="button">report abuse</Link>
          </div>

        </Holder>
      </TextPage>
    </LocalesPage>
  );
}
