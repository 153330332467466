import React, { Component } from 'react';
import { NavLink, Link } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';

import {
  HeaderClp4,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { HnavMob3 } from '../../blocks/HnavMob';
import { Hnav3 } from "../../blocks/Hnav";
import { HeroClp4 } from '../../../../themes/nerdify/components/Hero';
import { OurPricesClp3 } from '../../../../themes/nerdify/components/OurPrices';
import { PayListClp3 } from '../../../../themes/nerdify/components/PayList';
import Text from '../../../../base_components/Text';
import BetterWay, { BetterWayImg } from '../../../../themes/nerdify/components/BetterWay';
import SnapWatchLearn from '../../../../themes/nerdify/components/SnapWatchLearn';
import SlickSliderClp4 from '../../../../themes/nerdify/components/SlickSlider3/SlickSliderClp4';
import { SectionClp4 } from '../../../../themes/nerdify/components/Section';
import { FooterClp3 } from '../../blocks/Footer'
import Press, { PressList, PressItem, PressIcon } from '../../../../themes/nerdify/components/Press';
import { Faq3, Accordion, AccordionHead, AccordionBody, AccordionItem, AccordionIcon, AccordionItemHolder } from '../../../../themes/nerdify/components/Faq';

import { TextBack6 } from '../../blocks/TextBack';
import {
  NerdsClp3,
  NerdsList,
  NerdsItem,
} from '../../../../themes/nerdify/components/Nerds';
import DemoSubjects from '../../../../themes/nerdify/components/DemoSubjects';
import { useMobile } from '../../../../hooks';

import LocalesPage from '../LocalesPage';

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

class LandingClp4 extends Component {
  render() {
    const { pageCfg } = this.props;

    return (
      <WithHook>
        {({
          isMobile
        }) => {
          return (
            <LocalesPage {...this.props}>
              <div className="landing">

                <HeaderClp4>
                  <Holder>
                    <NavLink className='logo-link logo-link' to='/'>
                      <HeaderIcon iconName='logo-us' />
                    </NavLink>
                    {isMobile ? <HnavMob3 /> : <Hnav3 />}
                  </Holder>
                </HeaderClp4>

                <HeroClp4>
                  <div className="hero-main">
                    <div className="holder">
                      <h1 className="hero__title">{pageCfg.headline || <Text>Stuck on a <br className="mobile-only" />&#123;Math&#125; task?</Text>}</h1>
                      <h5 className="hero__subtitle">{pageCfg.subtitle || <Text>Get a <span className="stroke">video explanation</span> of how to solve it from one of the best Nerds!</Text>}</h5>
                      <TextBack6 {...this.props} subTheme={7} submitBtnTxt={'Text me now'} />
                    </div>
                  </div>
                  <div className="hero-rate">
                    <div className="holder">
                      <div className="hero-rate-msg">76,750 users rated Nerds 4.7/5</div>
                      <div className="hero-rate-icon" />
                    </div>
                  </div>
                </HeroClp4>

                <SnapWatchLearn />

                <Press className={"press__2 press__clp3"}>
                  <div className="press-wrapper">
                    <div className="press__title">Featured on</div>
                    <PressList>
                      <PressItem>
                        <PressIcon iconName={'forbes'} />
                      </PressItem>
                      <PressItem>
                        <a href='https://www.wired.com/story/xai-meeting-ai-chatbot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'wired'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://tech.co/startups-redefining-educational-space-2017-04' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'tech-co'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a
                          href='https://www.nextmedia-hamburg.de/wp-content/uploads/2018/12/nextMedia.Kompass_Trendreport_Oktober_2016.pdf' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'next-media'} />
                        </a>
                      </PressItem>
                      <PressItem>
                        <a href='https://venturebeat.com/2017/05/19/nerdify-announces-the-launch-of-nerdy-bot/' target="_blank" rel="noopener noreferrer">
                          <PressIcon iconName={'venturebeat'} />
                        </a>
                      </PressItem>
                    </PressList>
                  </div>
                </Press>

                <BetterWay className={"better_way__type1"}>
                  <div className="better_way__title">A better way to learn</div>
                  <div className="better_way__list">
                    <div className="better_way__item">

                      <div className="better_way__item_title_line">
                        <BetterWayImg className="img1" img={'faster.svg'} alt={''}/>
                        <div className="better_way__item_title">Faster</div>
                      </div>
                      <div className="better_way__item_msg">It takes seconds to request<br className="desktop-only"/> a step-by-step explanation of your task</div>
                    </div>

                    <div className="better_way__item">
                      <div className="better_way__item_title_line">
                        <BetterWayImg img={'simple.svg'} alt={''}/>
                        <div className="better_way__item_title">Simple</div>
                      </div>
                      <div className="better_way__item_msg">Watch a short video to understand how to solve your problem </div>
                    </div>

                    <div className="better_way__item">
                      <div className="better_way__item_title_line">
                        <BetterWayImg img={'bubble.svg'} alt={''}/>
                        <div className="better_way__item_title">Always with you</div>
                      </div>
                      <div className="better_way__item_msg">Message us or go back to your video explanations anytime, anywhere</div>
                    </div>

                  </div>
                </BetterWay>

                <NerdsClp3 className="nerds_clp3__type2">
                  <div className="holder">
                    <div className='nerds__big_title'>We select the top 2% of Nerds in each field<br className="desktop-only"/> to record video explanations and help you out</div>
                    <div className='nerds__big_subtitle'>Nerds are top university graduates who have passed an extensive<br/> examination process to join Nerdify platform.</div>
                    <NerdsList>
                      <NerdsItem />
                      <NerdsItem />
                      <NerdsItem />
                      <NerdsItem />
                      <NerdsItem />
                    </NerdsList>
                    <div className="nerds_hr"/>
                  </div>
                </NerdsClp3>

                <DemoSubjects
                  className="demoSubjects_clp4"
                  title={<Text>Our Nerds can record video <br className="mobile-only"/> explanations in 35+ subjects!</Text>}
                  oneRow={true} />

                <SlickSliderClp4/>

                <SectionClp4>
                  <div className='section-title'>Stop stressing over the tasks you don't understand</div>
                  <div className='section-subtitle'>Get a video with a step-by-step answer to the problem you are<br className="mobile-only"/> stuck on!</div>
                  <TextBack6 {...this.props} subTheme={9} submitBtnTxt={'Text me now'} />
                </SectionClp4>

                <OurPricesClp3 className="type2"/>

                <PayListClp3 />

                <Faq3>
                  <Holder>
                    <h3 className={'faq-title'}>FAQ</h3>
                    <Accordion>
                      <AccordionItemHolder>

                        <AccordionItem index={1}>
                          <AccordionHead>
                            <AccordionIcon icons={['plus2', 'minus2']} />
                            <h6 className={'faq-question'}>What tasks can a Nerd do?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies. They can really do anything! Try it! See <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks.</p>
                          </AccordionBody>
                        </AccordionItem>

                        <AccordionItem index={2}>
                          <AccordionHead>
                            <AccordionIcon icons={['plus2', 'minus2']} />
                            <h6 className={'faq-question'}>How much should I pay?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. We always agree the final cost before any charges are made! Thus, there is nothing to worry about - it's up to you whether accept the price or reject it.</p>
                          </AccordionBody>
                        </AccordionItem>

                        <AccordionItem index={3}>
                        <AccordionHead>
                          <AccordionIcon icons={['plus2', 'minus2']} />
                          <h6 className={'faq-question'}>How do you find the best-fit Nerd?</h6>
                        </AccordionHead>
                        <AccordionBody>
                          <p className={'p'}>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</p>
                        </AccordionBody>
                      </AccordionItem>

                      </AccordionItemHolder>

                      <AccordionItemHolder>

                        <AccordionItem index={4}>
                          <AccordionHead>
                            <AccordionIcon icons={['plus2', 'minus2']} />
                            <h6 className={'faq-question'}>How long is each video?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>It all depends on the time needed for Nerd to fully explain the task and walk you through each step. Most of our step-by-step videos are around 10 minutes long.</p>
                          </AccordionBody>
                        </AccordionItem>

                        <AccordionItem index={5}>
                          <AccordionHead>
                            <AccordionIcon icons={['plus2', 'minus2']} />
                            <h6 className={'faq-question'}>How can I be sure that my money is secure?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Once the order is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information, as well as to your CC data.</p>
                          </AccordionBody>
                        </AccordionItem>

                        <AccordionItem index={6}>
                          <AccordionHead>
                            <AccordionIcon icons={['plus2', 'minus2']} />
                            <h6 className={'faq-question'}>Can I get help on more than one problem?</h6>
                          </AccordionHead>
                          <AccordionBody>
                            <p className={'p'}>Absolutely! You can send us all of the problems you are stuck on and our Nerds will record separate step-by-step video explanations for each one of them.</p>
                          </AccordionBody>
                        </AccordionItem>

                      </AccordionItemHolder>

                    </Accordion>

                  </Holder>
                </Faq3>



                <FooterClp3 />

              </div>
            </LocalesPage>
          )
        }}
      </WithHook>
    )

  }
}

export default LandingClp4;
