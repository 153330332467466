export default[
  {
    "title": "Topic explanation",
    "msg": "Please help me understand Philosophical problem analysis in Religion & Theology! This is the topic of my midterm paper."
  },
  {
    "title": "Writing tips",
    "msg": "I need to format my History essay according to MLA. Can you show me example how to do that?"
  },
  {
    "title": "Help with slides",
    "msg": "I can't decide how to format the presentation for my Ethics class. Can you help me with the framework?"
  },
  {
    "title": "Help find sources",
    "msg": "Can someone advise me a list of literature that I can use to finish writing my Nursing paper?"
  },
  {
    "title": "Checking",
    "msg": "Need someone to check my language and punctuation in a paper for my Sociology class!"
  },
  {
    "title": "Coaching",
    "msg": "I need to create slides for my TED talk on the importance of Philosophy. Do you have someone who can coach me?"
  },
  {
    "title": "Test preparation",
    "msg": "I really need a skilled Nerd to help me get ready for my Business test next week."
  },
  {
    "title": "Tutoring",
    "msg": "Can someone explain to me the concept of confidence interval today?"
  },
  {
    "title": "Internship",
    "msg": "I want someone to check my motivation letter and advise on how to improve it."
  },
  {
    "title": "Tutor in Statistics",
    "msg": "Can't do my homework because I don't understand inferential statistics methods. Can u explain me the methodology?"
  },
  {
    "title": "Find materials",
    "msg": "I need a list of recently published articles on findings in Social Psychology."
  },
  {
    "title": "Tutor in Accounting",
    "msg": "Need an accounting homework help: a tutor to go through my calculations with me to make sure they're correct."
  },
  {
    "title": "Binominal Distribution",
    "msg": "In order to do my homework, I need to understand Binominal Distribution. Can you explain it in simple words?"
  },
  {
    "title": "CV guidance",
    "msg": "I wanna shift to a new company and want someone to help me update my CV."
  },
  {
    "title": "Tutor in Sociology",
    "msg": "Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology."
  },
  {
    "title": "Formatting advice",
    "msg": "I'm almost done writing my research and need someone to check if I formatted it properly."
  },
  {
    "title": "Application form",
    "msg": "I want to ask for help completing an application form for the exchange program."
  },
  {
    "title": "Bibliographic citations",
    "msg": "Can you recommend the right sources that I should read for my essay in English literature?"
  },
  {
    "title": "English Tutor",
    "msg": "I'm very bad in writing; help me find an English tutor to improve my skills, please."
  },
  {
    "title": "Consultation",
    "msg": "Can someone advise me on how to write a Chemistry lab report?"
  },
  {
    "title": "Tutor in Healthcare",
    "msg": "I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology."
  },
  {
    "title": "Expert feedback",
    "msg": "I want your best Nerd to check my English paper and provide feedback. This task is very important for my class!"
  },
  {
    "title": "Business research",
    "msg": "Can you help with a research of the biggest Pulp and Paper Manufactures in USA?"
  },
  {
    "title": "Audio transcription",
    "msg": "I need help writing down marketing conference notes from the audio file. Can I get that done?"
  },
  {
    "title": "Biology Tutor",
    "msg": "I'm stuck with my homework and need help. Do you have an online tutor in Biology?"
  },
  {
    "title": "Speech",
    "msg": "I need help writing a speech for a company-wide meeting in an easy-going manner."
  }
];
