import React from 'react';
import LazyLoad from 'react-lazyload';
import classnames from 'classnames';

import {
  WithClassNameHOC,
} from '../../../../base_components/Hoc';

import './index.css';

const Promo = ({className, ...rest}) => <div {...rest} className={classnames('promo', className)} />;

export const Promo1 = ({ className, ...rest }) => <Promo {...rest} className={classnames('promo_1', className)} />;
export const Promo2 = ({ className, ...rest }) => <Promo {...rest} className={classnames('promo_2', className)} />;
export const Promo3 = ({ className, ...rest }) => <Promo {...rest} className={classnames('promo_3', className)} />;
export const Promo4 = ({ className, ...rest }) => <Promo {...rest} className={classnames('promo_4', className)} />;

export const Promo2_1 = WithClassNameHOC(Promo2, 'promo_2_1');

export const PromoImg = ({ img, ...rest }) => <div className="promo__img_wrapper">
  <LazyLoad>
    <img {...rest} src={require(`./img/${img}`)} alt={''} />
  </LazyLoad>
</div>;
