import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';

import { Header2, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import { HnavMob2 } from '../../blocks/HnavMob';
import { Hnav3 } from "../../blocks/Hnav";
//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import SlickSliderClp4 from '../../../../themes/nerdify/components/SlickSlider3/SlickSliderClp4';
import {Footer2} from '../../blocks/Footer'
//import Icon from '../../../../themes/nerdify/components/Icons';

import { useMobile } from '../../../../hooks';

import LocalesPage from '../LocalesPage';

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class LandingClp4Video extends Component {

  render() {
    //const { pageCfg } = this.props;

    return (
      <WithHook>
        {({
          isMobile
        }) => {
          return (
            <LocalesPage {...this.props}>
              <div className="landing">

                <Header2 key='textpage-header'>
                  <Holder>
                    <NavLink className='logo-link' to='/'>
                      <HeaderIcon iconName='logo-dark' />
                    </NavLink>
                    <div className="header-wrap">
                      {isMobile ? <HnavMob2 /> : <Hnav3 />}
{/*
                      <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
                      <InputSelectLocales
                        className='input-select__short'
                        name='country'
                        isError={false}
                      />
*/}
                    </div>
                  </Holder>
                </Header2>

                <SlickSliderClp4/>

                <Footer2 />

              </div>
            </LocalesPage>
          )
        }}
      </WithHook>
    )

  }
}
