import React from 'react';
import Holder from '../../Holder'

import './cashbackBanner.css';

export const CashbackBanner = () => {
  return (
    <div className="cashback-banner">
      <Holder>
        <img className="cashback-icon" src={require(`./img/ic-cashback.svg`)} alt="" />
        <div className="cashback-msg"><span className="cashback-highlight">Get 40% back</span> if you purchase today!</div>
      </Holder>
    </div>
  );
};

export default CashbackBanner;
