import React from 'react';

import './verified-purchase.css';
import imgIcon from './img/verified-check.png';

const VerifiedPurchase = () => (
  <div className="verifiedPurchase">
    <img
      loading="lazy"
      className="imgIcon"
      src={imgIcon}
      width="11"
      height="11"
      alt=""
    />
    <p className="verifiedPurchaseText">Verified purchase</p>
  </div>
);

export default VerifiedPurchase;
