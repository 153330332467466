export default [
  {
    title: 'Topic explanation',
    msg: 'Please help me understand behavioral economics! This is the topic of my midterm paper.',
  },
  {
    title: 'Editing service',
    msg: 'I really need a skilled editor to help me customize survey templates for my research.',
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?",
  },
  {
    title: 'Find a book',
    msg: 'Can someone make a list of literature that I can use for my research paper in Biology?',
  },
  {
    title: 'Proofreading',
    msg: 'Need someone to check my language and punctuation!',
  },
  {
    title: 'Presentation',
    msg: 'Need help creating 5 PowerPoint slides for my TED talk by Wednesday. Can you help?',
  },
  {
    title: 'Market Research',
    msg: 'Please put together a SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to proofread and edit my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: "Can't do homework because I don't understand inferential statistics methods. Can u explain me the methodology?"
  },
  {
    title: 'Find materials',
    msg: 'I need a list of recently published articles on findings in Social Psychology.',
  },
  {
    title: 'Tutor in Accounting',
    msg: "Need an accounting homework help: a tutor to go though my calculations with me to make sure they're correct.",
  },
  {
    title: 'Binomial Distribution',
    msg: 'In order to do my homework, I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please clarify the concept of Manifest Function for me so that I could do my assignment in sociology.',
  },
  {
    title: 'Formatting',
    msg: 'I am almost done writing my research paper and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I want to ask for help completing an application form for the exchange program.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: "I'm very bad in writing; help me find an English tutor to improve my skills, please.",
  },
  {
    title: 'Sources',
    msg: 'My homework is almost finished, but I am still struggling with sources. Need them in an hour, is it possible for you?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Physiology.',
  },
  {
    title: 'Proofreading',
    msg: 'I want your best Nerd to proofread an English paper for me. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down conference notes from the audio file I made. Can I get that done?',
  },
  {
    title: 'Biology Tutor',
    msg: "I'm stuck with my homework and need help. Do you have an online tutor in Biology?",
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];
