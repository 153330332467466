import React, { Fragment, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  Header5,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { TextBack7 } from '../../blocks/TextBack'
import { Info5, Info6 } from './../../../../themes/nerdify/components/Info';
import DemoCards from '../../blocks/DemoCards';
import { Hero13 } from '../../../../themes/nerdify/components/Hero';
import BackLink from '../../../../themes/nerdify/components/BackLink';
import { Footer2 } from '../../blocks/Footer';
import {
  Faq2,
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon,
  AccordionItemHolder,
} from '../../../../themes/nerdify/components/Faq';
//import Icon from '../../../../themes/nerdify/components/Icons';

import LocalesPage from '../LocalesPage';
import classnames from 'classnames';
import Button from '../../../../base_components/Button';
import { Form } from '../../../../themes/nerdify/components/Form';
import InputError from '../../../../themes/nerdify/components/Form/InputError';

import { updateUser, checkLeadExists } from '../../../../redux/actions';

import regexps from '../../../../utils/regexps';
import { parseQuery } from '../../../../utils';

const ThankYou = (props) => {
  const { pageCfg } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const { isPatching } = useSelector((state) => state.textback);

  const [email, setEmail] = useState('');
  //const [userId, setUserId ] = useState('')
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  let urlParams  = parseQuery(location.search);
  if ('amp_params' in urlParams) {
    let decodedAmpParams = Buffer.from(urlParams['amp_params'], 'base64').toString();
    urlParams  = parseQuery(decodedAmpParams);
  }

  const phone  = props.location.state && props.location.state.phone || _.get(urlParams, 'to', '').split('-').join('') || '';
  const landing_type  = props.location.state && props.location.state.landing_type || _.get(urlParams, 'landing_type', '') || '';
  //const source_url = props.location.state && props.location.state.source_url && decodeURIComponent(props.location.state.source_url) || decodeURIComponent(_.get(urlParams, 'source_url', '')) || '';

  let source_url = '';
  try {
    source_url = decodeURIComponent(props.location.state?.source_url ?? '');
  } catch (error) {
    console.error('Error decoding source_url from state:', error);
  }

  if (!source_url) {
    try {
      source_url = decodeURIComponent(_.get(urlParams, 'source_url', ''));
    } catch (error) {
      console.error('Error decoding source_url from URL params:', error);
    }
  }

  source_url = source_url || '';


  useEffect(() => {
    localStorage.removeItem('leadCreated');
  }, []);

  const validate = () => {
    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
    };

    const isError = Object.keys(errors).some((item) => errors[item]);

    setErrors(errors);

    return isError;
  };

  const getUserIdHelper = async () => {
    let phone;
    if (typeof window !== 'undefined') {
      phone = _.get(parseQuery(window.location.search), 'to', '');
    }

    if (phone) {
      const existsData = await dispatch(checkLeadExists(phone.split('-').join(''))).then(
        (resp) => resp.data
      );
      return existsData._id
    } else {
      return _.get(location, 'state.userId', '');
    }
  };

  const onSubmit = () => {
    const isError = validate();

    if (isError) {
      return false;
    }

    setErrors('');

    const userIdRes = getUserIdHelper();

    userIdRes.then(userId => {
      if (!userId) {
        return false;
      }

      setIsSubmitted(true);

      dispatch(updateUser(userId, email))
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          setErrors({
            server: error.text,
          });
        });
    })
  };

  const onChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const isErrors = errors.server || errors.email;

  return (
    <LocalesPage {...props}>
      <div className="landing">
        <Header5>
          <Holder>
            <NavLink className="logo-link logo-link_1" to="/">
              <HeaderIcon iconName="logo-dark" theme={1} />
            </NavLink>
            {pageCfg.backLink && <BackLink path={source_url} phone={phone} />}
          </Holder>
        </Header5>

        <Hero13 subTheme={1}>
          <div className="hero-main">
            <div className="holder">
              <h1 className="hero__title">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Done! We’ve just texted you again!</>
                  )
                  : (
                    <>We'll text you<br className="mobile-only" /> in a few seconds!</>
                  )
                }
              </h1>
              <h2 className="hero__subtitle top">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Please check your phone number if you still don't receive our message.</>
                  )
                  : (
                    <>If you don't receive our message,<br/>
                      click the button below and we'll text you again.</>
                  )
                }
              </h2>


              <TextBack7 {...props} subTheme={6} submitBtnTxt={'Text me now'} />
              {landing_type === 'undelivered' && (
                <div className="hero__warning">If you put in the correct phone number and still don't get a message from Nerdify, <br className="desktop-only"/>
                contact us via <a className="hero__link" href="mailto:done@nerdifyit.com">done@nerdifyit.com</a></div>
              )}

              {!isSubmitted ? (
                <>
                  <h2 className="hero__subtitle bottom">
                    Enter your email to receive Nerdify discounts and promos
                  </h2>
                  <Form className={'email-form'}>
                    <div className="input-wrapper">
                      <input
                        className={classnames('input', {
                          input__error: isErrors,
                        })}
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        onChange={onChange}
                      />
                      {isErrors && (
                        <InputError>{errors.email || errors.server}</InputError>
                      )}
                    </div>
                    <Button
                      className={classnames('button__type6 submit-btn', {
                        submitted: isSubmitted || isPatching,
                      })}
                      disabled={isSubmitted || isPatching}
                      onClick={onSubmit}
                    >
                      {isPatching ? (
                        <ul className="loading">
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                      ) : (
                        <Fragment>
                          {isSubmitted ? 'Email submited' : 'Submit email'}
                        </Fragment>
                      )}
                    </Button>
                  </Form>
                </>
              ) : (
                <div className="hero__subtitle bottom">Thank you for sharing your email with us! <br className="desktop-only"/>
                  We'll let you know about all Nerdify discounts and promos.</div>
              )}
            </div>
          </div>
        </Hero13>

        <Info6>
          <h2 className="h3 info6-title">
            Meanwhile, you might wanna know that:
          </h2>
          <p className="info6-p">
            All Nerds have successfully passed our strict examination process
            and proven their
            <br className="desktop-only" /> competence. That's why students rate
            Nerds 4.7/5 on average
          </p>
          <div className="info6-rate-img" />
        </Info6>

        <DemoCards titleTag={"h2"} title="Check what our clients say about their Nerdify experience" />

        <Info5>
          <h2 className="h2 info5-title">
            We are only happy if you
            <br className="mobile-only" /> are satisfied
          </h2>
          <p className="info5-p">
            All our clients deserve to get the best Nerdify experience!
            <br />
            We have 100% money-back guarantee in case you think the Nerd we have
            found didn't meet your initial
            <br className="desktop-only" /> requirements.
            <br className="mobile-only" /> For more information please look at
            our{' '}
            <a href="/refunds" className="link">
              Refund Policy
            </a>
          </p>
        </Info5>

        <Faq2>
          <Holder>
            <h2 className={'h3'}>FAQ</h2>
            <Accordion>
              <AccordionItemHolder>
                <AccordionItem index={1}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>How do you find the best-fit Nerd?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      The platform algorithm picks the best-matching Nerd to be
                      assigned to your task by comparing various parameters:
                      specialty, experience, rating, availability, additional
                      skills, etc.
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem index={2}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>Is my payment secure?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      Once the order is made, we send you a payment link to the
                      invoice. You can submit the payment with any Credit/Debit
                      card. When following the link you are automatically taken
                      to 128-bit encrypted HTTPS payment page. You can type your
                      credit card information and initiate a transaction
                      directly from your phone. Nerdify has no access to your
                      personal information, as well as to your CC data.
                    </p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>

              <AccordionItemHolder>
                <AccordionItem index={3}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>How do you guarantee the quality?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      We are proud to stand behind our reputation of
                      reliability, performance, and quality of service. We
                      select only 2% of all applicants to join our Nerdify
                      family. They all pass a long and tough examination process
                      to prove their competence. Moreover, each Nerd passes
                      monthly evaluation tests too. Stay sure that once you
                      trust Nerdify with your request, you will get the
                      assistance that lives up to the highest standar
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem index={4}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h3 className={'h6'}>What is money back guarantee?</h3>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      Your satisfaction is the first thing that matters. We will
                      do our best to provide you will all the necessary
                      assistance upon your request. You can rely on our
                      commitment to solving any problem that might arise
                      regarding your request. If you end up not satisfied with
                      Nerdify service, you can request a refund. Please learn
                      the details on Refund Policy page.
                    </p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>
            </Accordion>
          </Holder>
        </Faq2>

        <Footer2 />
      </div>
    </LocalesPage>
  );
};

export default ThankYou;
