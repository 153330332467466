import React, { Component } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import classnames from 'classnames';
import './videoModal.css';

/*
const videos = [
  { id: 115783408, name: 'Jambinai - Connection' },
  { id: 162959050, name: 'Jambinai - They Keep Silence' },
  { id: 169408731, name: 'Hoody - Like You' },
];
*/

export default class VideoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoIndex: 0,
      volume: 1,
      paused: false,
    };

    this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
    this.handlePlayerPause = this.handlePlayerPause.bind(this);
    this.handlePause = this.handlePause.bind(this);
  }

  handlePlayerPlay() {
    this.setState({ paused: false });
  }

  handlePause(event) {
    this.setState({
      paused: true,
    });
  }

  handlePlayerPause() {
    this.setState({ paused: true });
  }

  handleCloseModal() {
    this.handlePlayerPause();
    //this.setState({ paused: true });
    this.props.onClose();
  }

  render() {
    const { videoIndex, paused, volume } = this.state;
    //const video = videos[videoIndex];
    const { videoId, type } = this.props;

    return (
      <div id="videoModal" className={classnames("video-modal", `type${type}`)}>
        <div className="modal-inner">
          <button id="videoModalClose" className="modal-close" data-video="" onClick={() => this.handleCloseModal()} />

          <Vimeo className={'video-frame'}
            video={videoId}
            autoplay
            volume={volume}
            paused={paused}
            onPause={this.handlePlayerPause}
            onPlay={this.handlePlayerPlay}
          />
        </div>
      </div>
    )
  }
}

