import React from 'react';

import {
  default as DemoCardsTheme,
  DemoCards1 as DemoCardsTheme1,
} from '../../../../themes/nerdify/components/DemoCards';

const records = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 4, 2020',
      name: 'Mark C.',
      photo: '01.jpg',
      photo2x: '01@2x.jpg',
      recommends: true,
      review:
        'I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize it. I contacted them via an Ad I saw on Instagram and they were happy to help. I had some troubles with the instructions followed, as they were not initially full, but they gladly helped me to understand it all within 12 hours from my request. It is perfect. Thank you so much again. You get what you pay for!',
    },
    '02': {
      id: '02',
      date: 'Apr 1, 2020',
      name: 'Eric S.',
      photo: '02.jpg',
      photo2x: '02@2x.jpg',
      recommends: true,
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! The service they provided was of a really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Jan 10, 2020',
      name: 'Mariana F.',
      photo: '10.jpg',
      recommends: true,
      review:
        'Nerdify is the best thing I found during my college years. They are always there to help you and they never disappoint. Even if I have a trouble, they make everything possible to provide a prompt issue resolution. I would recommend it to everyone!',
    },
  },

  trustpilot: {
    '01': {
      date: 'Apr 6, 2020',
      name: 'Julie C.',
      mark: '5',
      review:
        'One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school, job and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
      title: 'Professional service',
    },
    '02': {
      date: 'Mar 27, 2020',
      name: 'Nerd Lover',
      mark: '5',
      review:
        'I have used this company twice now. And both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
      title: '5 out of 5 from me!',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Dec 5, 2019',
      name: 'Isaac N.',
      mark: '5',
      review:
        'I was skeptical at first when I heard about this website but honestly, I could not be happier. All I had to to was send them a screenshot, and they immediately responded. Anyone who’s in a bind and needs some help could seriously use this website and benefit from it.',
      title: 'My best experience so far',
    },
  },
};

export const recordsBusiness = {
  facebook: {
    '01': {
      id: '01',
      date: 'Jul 29, 2021',
      name: 'Dalia K.',
      photo: 'Dalia.jpg',
      photo2x: 'Dalia@2x.jpg',
      recommends: true,
      review:
        'I switched my career path a while ago and got my first job in an IT company as a junior web analyst. Nerdify has helped me a lot whenever I felt overwhelmed and was stressing out. The money I paid for Nerds’ assistance was totally worth it. They helped me master new skills and gave feedback on my projects.',
    },
    '02': {
      id: '02',
      date: 'Aug 10, 2021',
      name: 'Dennis W.',
      photo: 'Dennis.jpg',
      photo2x: 'Dennis@2x.jpg',
      recommends: true,
      review:
        "What I love about Nerdify is that it helps little business owners like myself not lose focus and have peace of mind. It’s a shame I can't afford a full-time marketing manager, but at least I can afford to get help at Nerdify! Nerds managed to pull me out of really tight spots. Thanks to them I now have a working business development strategy that has already proven to be effective.",
    },

    '03': {
      id: '03',
      date: 'Apr 4, 2020',
      name: 'Mark C.',
      photo: '01.jpg',
      photo2x: '01@2x.jpg',
      recommends: true,
      review:
        'I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize it. I contacted them via an Ad I saw on Instagram and they were happy to help. I had some troubles with the instructions followed, as they were not initially full, but they gladly helped me to understand it all within 12 hours from my request. It is perfect. Thank you so much again. You get what you pay for!',
    },
    '04': {
      id: '04',
      date: 'Apr 1, 2020',
      name: 'Eric S.',
      photo: '02.jpg',
      photo2x: '02@2x.jpg',
      recommends: true,
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! The service they provided was of a really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    '05': {
      id: '05',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '06': {
      id: '06',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '07': {
      id: '07',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '08': {
      id: '08',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '09': {
      id: '09',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    10: {
      id: '10',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    11: {
      id: '11',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    12: {
      id: '12',
      date: 'Jan 10, 2020',
      name: 'Mariana F.',
      photo: '10.jpg',
      photo2x: '10@2x.jpg',
      recommends: true,
      review:
        'Nerdify is the best thing I found during my college years. They are always there to help you and they never disappoint. Even if I have a trouble, they make everything possible to provide a prompt issue resolution. I would recommend it to everyone!',
    },
    13: {
      id: '13',
      date: 'Jun 11, 2021',
      name: 'Hector D.',
      photo: 'Hector.jpg',
      photo2x: 'Hector@2x.jpg',
      recommends: true,
      review:
        'I have been working with Nerdify for a year now and my experience has been amazing. Nerds completed each of my requests on time and following the requirements. The quality of their work, attention to detail and the eagerness to go above and beyond to meet your expectations deserve at least 10 stars!',
    },
  },
  trustpilot: {
    '01': {
      date: 'Apr 6, 2020',
      name: 'Julie C.',
      mark: '5',
      review:
        'One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school, job and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
      title: 'Professional service',
    },
    '02': {
      date: 'Mar 27, 2020',
      name: 'Nerd Lover',
      mark: '5',
      review:
        'I have used this company twice now. And both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
      title: '5 out of 5 from me!',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Dec 5, 2019',
      name: 'Isaac N.',
      mark: '5',
      review:
        'I was skeptical at first when I heard about this website but honestly, I could not be happier. All I had to to was send them a screenshot, and they immediately responded. Anyone who’s in a bind and needs some help could seriously use this website and benefit from it.',
      title: 'My best experience so far',
    },
  },
};

export const recordsAcademia = {
  facebook: {
    '01': {
      id: '01',
      date: 'Jul 9, 2021',
      name: 'Isidora D.',
      photo: 'Isidora.jpg',
      photo2x: 'Isidora@2x.jpg',
      recommends: true,
      review:
        "Nerdify is the best research platform I've ever used. I have recently become an awarded tenure and I must admit that Nerdify made my path easier. Thank you very much! I would recommend this platform to anyone who needs extra hands.",
    },
    '02': {
      id: '02',
      date: 'Aug 15, 2021',
      name: 'Thomas M.',
      photo: 'Thomas.jpg',
      photo2x: 'Thomas@2x.jpg',
      recommends: true,
      review:
        'I like how the Nerds work: they are very thorough and detail-oriented. I usually ask my students to conduct the surveys and analyze the results, but Nerds appeared to be better at this. Aside from being affordable, what I love about this platform is that they never give empty promises and deliver on the agreed time.',
    },

    '03': {
      id: '03',
      date: 'Apr 4, 2020',
      name: 'Mark C.',
      photo: '01.jpg',
      photo2x: '01@2x.jpg',
      recommends: true,
      review:
        'I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize it. I contacted them via an Ad I saw on Instagram and they were happy to help. I had some troubles with the instructions followed, as they were not initially full, but they gladly helped me to understand it all within 12 hours from my request. It is perfect. Thank you so much again. You get what you pay for!',
    },
    '04': {
      id: '04',
      date: 'Apr 1, 2020',
      name: 'Eric S.',
      photo: '02.jpg',
      photo2x: '02@2x.jpg',
      recommends: true,
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! The service they provided was of a really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    '05': {
      id: '05',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '06': {
      id: '06',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '07': {
      id: '07',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '08': {
      id: '08',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '09': {
      id: '09',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    10: {
      id: '10',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    11: {
      id: '11',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    12: {
      id: '12',
      date: 'Jan 10, 2020',
      name: 'Mariana F.',
      photo: '10.jpg',
      photo2x: '10@2x.jpg',
      recommends: true,
      review:
        'Nerdify is the best thing I found during my college years. They are always there to help you and they never disappoint. Even if I have a trouble, they make everything possible to provide a prompt issue resolution. I would recommend it to everyone!',
    },
    13: {
      id: '13',
      date: 'Jun 23, 2021',
      name: 'Micaela J.',
      photo: 'Micaela.jpg',
      photo2x: 'Micaela@2x.jpg',
      recommends: true,
      review:
        'Thanks to the Nerds who shared their expertise with me, I was able to complete my research in the expected time frame. I was having a really hard time putting together my findings, but everything became a lot more clear with their help.',
    },
  },
  trustpilot: {
    '01': {
      date: 'Apr 6, 2020',
      name: 'Julie C.',
      mark: '5',
      review:
        'One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school, job and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
      title: 'Professional service',
    },
    '02': {
      date: 'Mar 27, 2020',
      name: 'Nerd Lover',
      mark: '5',
      review:
        'I have used this company twice now. And both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
      title: '5 out of 5 from me!',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Dec 5, 2019',
      name: 'Isaac N.',
      mark: '5',
      review:
        'I was skeptical at first when I heard about this website but honestly, I could not be happier. All I had to to was send them a screenshot, and they immediately responded. Anyone who’s in a bind and needs some help could seriously use this website and benefit from it.',
      title: 'My best experience so far',
    },
  },
};

export const records_students_1 = {
  facebook: {
    '01': {
      id: '01',
      date: 'May 10, 2022',
      name: 'Mark C.',
      photo: '01.jpg',
      photo2x: '01@2x.jpg',
      recommends: true,
      review:
        'I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize my essay. I contacted them via an ad I saw on Instagram and they were happy to help. I had some trouble with the instructions followed, as they were not initially full, but they gladly helped me to understand it all within 12 hours of my request. It is perfect. Thank you so much again. You get what you pay for!',
    },
    '02': {
      id: '02',
      date: 'July 20, 2022',
      name: 'Eric S.',
      photo: '02.jpg',
      photo2x: '02@2x.jpg',
      recommends: true,
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! Writing tips they provided were of really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Apr 14, 2022',
      name: 'Mariana F.',
      photo: '10.jpg',
      photo2x: '10@2x.jpg',
      recommends: true,
      review:
        'Nerdify is the best thing I found during my college years. They are always there to help you with any essay issue. Nerds make everything possible to guide you to a prompt solution and never disappoint. I would recommend it to everyone!',
    },
  },
  trustpilot: {
    '01': {
      date: 'Apr 6, 2022',
      name: 'Julie C.',
      mark: '5',
      review:
        'One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school essays, job, and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
      title: 'Professional service',
    },
    '02': {
      date: 'Jun 12, 2022',
      name: 'Nerd Lover',
      mark: '5',
      review:
        'Nerdify has so far helped me with checking 2 writing tasks and both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
      title: '5 out of 5 from me!',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Sep 18, 2022',
      name: 'Berry G.',
      mark: '5',
      review:
        'I was very skeptical about this site at first but honestly, I could not be happier. I decided to take a chance because I was struggling with an essay and time was passing fast. I found Nerdify and it was a quick and easy tutoring session. All of my questions were answered promptly and the payment process was easy and affordable.',
      title: 'My best experience so far',
    },
  },
};

export const records_students_2 = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 19, 2022',
      name: 'Nick T.',
      photo: 'Nick.png',
      recommends: true,
      review:
        'At first, I was hesitant to use Nerdify but they have been such a great help. Being a first-time college student I was overwhelmed by the amount of work from each class. Nerdify did a great job with every paper question I had and provided high-quality advice. I would highly recommend this service!',
    },
    '02': {
      id: '02',
      date: 'July 20, 2022',
      name: 'Eric S.',
      photo: '02.jpg',
      photo2x: '02@2x.jpg',
      recommends: true,
      review:
        "What an incredible service. Nerdify has gotten me out of two really tight spots! Writing tips they provided were of really high quality, their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Sep 13, 2022',
      name: 'Darius S.',
      photo: 'Darius.png',
      recommends: true,
      review:
        'Nerdify helped me in a real crunch time situation. I told them my problem with the paper I was writing and they basically explained me how to fix it in a short time. I highly recommend people use Nerdify guidance when they are struggling with papers or any other tasks as well.',
    },
  },

  trustpilot: {
    '01': {
      date: 'Apr 6, 2022',
      name: 'Julie C.',
      mark: '5',
      review:
        'One of my good friends who has her DNP recommended Nerdify to me when I was really choking on my school papers, job, and family loads. Their tutors are very professional, honest and the price is right. Their work is of high quality. You can count on them. Nerds make me feel like they are always there to support you with any of your needs. I highly recommend Nerdify to any that might need help studying. My friend knows that I don’t have time for monkey business and I am glad she introduced me to Nerdify.',
      title: 'Professional service',
    },
    '02': {
      date: 'Jun 12, 2022',
      name: 'Nerd Lover',
      mark: '5',
      review:
        'Nerdify has so far helped me with checking 2 writing tasks and both times they exceeded my expectations. I truly value my time and the process from start to finish was extremely personal and easy. The quality was well worth the cost. I would absolutely recommend Nerdify!',
      title: '5 out of 5 from me!',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Sep 27, 2022',
      name: 'Isaac N.',
      mark: '5',
      review:
        'I was skeptical at first when I heard about this website but honestly, I could not be happier. All I had to do was send them a screenshot, and they immediately responded and explained the topic. Anyone who’s in a bind and needs some help could seriously use this website and benefit from it.',
      title: 'My best experience so far',
    },
  },
};

export const records_students_3 = {
  facebook: {
    '01': {
      id: '01',
      date: 'Oct 10, 2022',
      name: 'Brittany D.',
      photo: 'Brittany.png',
      recommends: true,
      review:
        'What I really like about Nerdify is that they are always friendly and do their best to help me out with my homework. They are very understanding and response times are very quick. Prices and affordable as well. Should you be looking for homework guidance - hire the best Nerds here to get reliable help with tasks.',
    },
    '02': {
      id: '02',
      date: 'Apr 20, 2022',
      name: 'Sundus A.',
      photo: 'Sundus.png',
      recommends: true,
      review:
        'Lifesavers! You get to be very specific about the homework topic you need help with! They reply really quickly and do their best to schedule tutoring session asap! Highly would recommend it if you have difficult professors and tasks! Love them!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'May 19, 2022',
      name: 'Dee T.',
      photo: 'Dee.png',
      recommends: true,
      review:
        'Nerdify is truly the best, very helpful fast replies, and always tries to guide you to the solution of your homework task as quickly as they can, it’s definitely a quality experience that you will enjoy and won’t regret!',
    },
  },

  trustpilot: {
    '01': {
      date: 'May 9, 2022',
      name: 'Mari M.',
      mark: '5',
      review:
        'This site was recommended to me but I was still a little hesitant at first. Surprisingly the entire experience was quite lovely. They were very clear and quick responses. All my homework questions were addressed and explained quickly, professionally, and very friendly. I will absolutely recommend this to everyone who needs help with homework!',
      title: 'THEY ARE PROFESSIONALS',
    },
    '02': {
      date: 'Jun 20, 2022',
      name: 'Ali A.',
      mark: '5',
      review:
        'I’ve been using Nerdify a few times and I have no complaints so far! They answer pretty quickly, the price is reasonable and I am definitely going to use their help in the future. If you need someone to clarify how to do presentation, discussion, assignment, article, or any homework, you could rely on Nerdify!',
      title: 'ANY TYPE OF HELP',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Sep 19, 2022',
      name: 'Saida S.',
      mark: '5',
      review:
        'My experience with Nerdify has been great from the jump. They find a tutor to explain your homework task, and are ready to assist in any way needed. It really is a stress reliever to know that I can count on the help of Nerdify. Thank you so much!',
      title: 'MY EXPERIENCE',
    },
  },
};

export const records_students_4 = {
  facebook: {
    '01': {
      id: '01',
      date: 'Nov 14, 2022',
      name: 'Kathy G.',
      photo: 'Kathy.png',
      recommends: true,
      review:
        'I would definitely recommend Nerdify. They were polite and helped me understand my assignment before my deadline. I loved how they incorporated every little detail and requirement I specified in. They were a great help and will be using their services again.',
    },
    '02': {
      id: '02',
      date: 'Sep 21, 2022',
      name: 'Joseph A.',
      photo: 'Joseph A..png',
      recommends: true,
      review:
        'I literally had to do an assignment and was on a deadline. They helped me with data collection and research so that I could focus on the analysis itself. I would recommend them to any struggling student that needs extra help to collect data for their assignments. Thank you so much!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'July 18, 2022',
      name: 'Heather D.',
      photo: 'Heather.png',
      recommends: true,
      review:
        "Had an assignment where I was in a pinch and needed input so I Googled who may be able to help. Nerdify popped up and I went for it! The nerd assigned to my task did a great job explaining and interpreting concepts. I will most definitely use this service again. I'm sure if others are in the same boat they may not be aware of it either, and may even be apprehensive, but you will not regret it!",
    },
  },

  trustpilot: {
    '01': {
      date: 'Apr 24, 2022',
      name: 'Siyanne F.',
      mark: '5',
      review:
        'I am extremely happy with the service I have received. Nerdify was really helpful and the responses to my questions were all answered in the quickest time. Very professional, very efficient, and well organized. After all, I finished my assignment perfectly I’m beyond grateful. Thank you, guys!',
      title: 'SERVICE EXCELLENCE',
    },
    '02': {
      date: 'Jun 20, 2022',
      name: 'Ali A.',
      mark: '5',
      review:
        'I’ve been using Nerdify a few times and I have no complaints so far! They answer pretty quickly, the price is reasonable and I am definitely going to use their help in the future. If you need someone to clarify how to do presentation, discussion, assignment, article, or any homework, you could rely on Nerdify!',
      title: 'ANY TYPE OF HELP',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Jun 8, 2022',
      name: 'Amaris M.',
      mark: '5',
      review:
        'I had one of the largest assignments due that I have ever written. The window to get it done was very short in addition to working full-time with young children I was exhausted. I reached Nerdify for help with preliminary research and data collection to save time. They were very quick in their responsiveness in addition to being very professional. Anytime I reached out via text there was again a quick answer. I recommend anyone who is overwhelmed with tasks.',
      title: 'MY BEST EXPERIENCE',
    },
  },
};

export const records_students_5 = {
  facebook: {
    '01': {
      id: '01',
      date: 'Sep 5, 2022',
      name: 'Jaya L.',
      photo: 'Jaya.png',
      recommends: true,
      review:
        "Nerdify came in very handy. Was really struggling to keep up with my deadlines. I wasn't sure how these guys could help me with my project, but Nerds have greatly assisted me with the choice of research methodology and frameworks for analysis. They were so helpful throughout the whole process. I would recommend it to everyone!",
    },
    '02': {
      id: '02',
      date: 'Mar 26, 2022',
      name: 'Kain B.',
      photo: 'Kain.png',
      recommends: true,
      review:
        'This service has the best customer service I have ever experienced. I feel like I am talking to a friend that knows me and my writing needs. The first time I used Nerdify was when I was struggling with annotated bibliography, and the second time with the thesis statement. I had a million questions and the person answered each of those questions with patience and care. I am so happy that you guys care so much for your customers. Thank you!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'May 14, 2022',
      name: 'Rebeca G.',
      photo: 'Rebecca.png',
      recommends: true,
      review:
        'They’re the best! They respond to you back within minutes. I’m super happy that I received writing tips while summarizing my article. It was hard for me not to write my opinion on the topic but really write a summary. Their nerds are responsible and professional. Definitely will contact them back!',
    },
  },

  trustpilot: {
    '01': {
      date: 'Oct 6, 2022',
      name: 'Nick P.',
      mark: '5',
      review:
        'People if you want trustworthy, fast, and intelligent people to help you improve your writing, then this site you can trust. They really worth your time and they will provide quality work valuing your money spent. Their price is reasonable. I would absolutely recommend Nerdify!',
      title: 'THEY WORTH YOUR TIME',
    },
    '02': {
      date: 'Jun 20, 2022',
      name: 'Ali A.',
      mark: '5',
      review:
        'I’ve been using Nerdify a few times and I have no complaints so far! They answer pretty quickly, the price is reasonable and I am definitely going to use their help in the future. If you need someone to clarify how to do presentation, discussion, assignment, article, or any homework, you could rely on Nerdify!',
      title: 'ANY TYPE OF HELP',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Apr 4, 2022',
      name: 'Elaine C.',
      mark: '5',
      review:
        'This is the first time I’ve ever used this website! Thank you so much for helping me, I was struggling and nervous about how to get my project done. I’m so happy to be able to find this website because Nerds explained everything to me perfectly and helped with initial research!',
      title: 'MY FIRST EXPERIENCE',
    },
  },
};

export const records_math_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Jun 20, 2022',
      name: 'Amanda S.',
      photo: 'Amanda.png',
      recommends: true,
      review:
        'The communication is effective and polite. They texted me back instantly! Nerd has explained me how to solve my math problems. Will definitely use it later in the future when I have more math questions!!',
    },
    '02': {
      id: '02',
      date: 'Nov 11, 2022',
      name: 'Preya T.',
      photo: 'Preya.png',
      recommends: true,
      review:
        'The best site ever! Nerdify saved me from failing my math class. I have been struggling with calculations for the longest and instead of stressing myself out, I went to Nerdify for a consultation with a tutor. The customer service was extremely helpful and considerate as well. I highly recommend them!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Apr 4, 2022',
      name: 'Sandra P.',
      photo: 'Sandra P.png',
      recommends: true,
      review:
        'The second time I hire Nerdify. They have the best Math Nerds to explain Geometry topics and homework. Very professional, always on time, very quick on responding, and best prices available. I would recommend it to everyone!',
    },
  },

  trustpilot: {
    '01': {
      date: 'May 25, 2022',
      name: 'Omar',
      mark: '5',
      review:
        'I’ve never seen such an amazing service when it comes to finding solutions for any subject, especially math. They explain your task and really go into detail. The response was very fast and the communication was very friendly. I would absolutely recommend them!',
      title: 'AMAZING SERVICE',
    },
    '02': {
      date: 'Sep 19, 2022',
      name: 'Angel M.',
      mark: '5',
      review:
        'This is my first time using this service. The customer service was excellent and very thorough. They explained the process and always gave me feedback. My son needed help with a math assignment and got all questions well explained. I had no problem with the process. Super easy and the service overall was great. I will use them again!',
      title: 'GREAT AND EASY SERVICE',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Jun 14, 2022',
      name: 'Jayb N.',
      mark: '5',
      review:
        'They did an excellent job tutoring me in Geometry. I needed help with an assignment and they walked me through it the same day. The person I communicated with was kind, attentive, and professional! I have no complaints! I would highly highly recommend using Nerdify!',
      title: 'EXCELLENT JOB',
    },
  },
};

export const records_statistics_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 12, 2022',
      name: 'Sara M.',
      photo: 'Sara.png',
      recommends: true,
      review:
        'I have had a great experience with Nerdify, I have struggled to revise and check my statistics homework task and Nerdify helped me with that. Definitely recommend it and I’ll need their help again in the future!',
    },
    '02': {
      id: '02',
      date: 'Mar 3, 2022',
      name: 'Nicole S.',
      photo: 'Nicole.png',
      recommends: true,
      review:
        'I had a professional service and I got what I asked. Communication was so quick and breezy. I would recommend it to some people who need help with their statistics assignments. But overall amazing service, will use it again!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Sep 21, 2022',
      name: 'Kliber S.',
      photo: 'Kliber.png',
      recommends: true,
      review:
        'First, they were very quick to reply, very polite, and very professional. I am thankful they were able to help me out with my statistics problem and check calculations. They met and exceeded my expectations. Thank you.',
    },
  },

  trustpilot: {
    '01': {
      date: 'Apr 29, 2022',
      name: 'Hanna M.',
      mark: '5',
      review:
        'Nerdify is seriously one of the best things I’ve ever used! My first time using Nerdify, they tutored me on my statistics excel and answered every question I had. They even included discounts! Highly recommend using Nerdify if you ever get stuck with your statistics!',
      title: 'UNSTUCK WITH NERDIFY',
    },
    '02': {
      date: 'May 12, 2022',
      name: 'Michelle W.',
      mark: '5',
      review:
        "My overall experience with Nerdify was excellent! My Nerd's response was always on time and he explained to me how to solve my statistics problem. The communication was excellent. I would surely remember Nerdify was my friends and family.",
      title: 'MY EXPERIENCE',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Sep 9, 2022',
      name: 'Scott F.',
      mark: '5',
      review:
        'Nerdify is a wonderful website to use if you need extra help. I am very satisfied with the work I have done on my statistics project under their guidance! I refer them to everyone I know who needs some help completing projects in a timely manner!',
      title: 'WONDERFUL SERVICE',
    },
  },
};

export const records_natural_sciences_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 16, 2022',
      name: 'Rasay D.',
      photo: 'Rasay.png',
      recommends: true,
      review:
        'I needed help in my Chemistry class this semester to understand and submit great assignment. I liked their services, they were very prompt and professional. I’d be using them again for sure. I’d definitely recommend them to any student who needs guidance and tips on completing projects, lab reports, etc. Thank you!',
    },
    '02': {
      id: '02',
      date: 'May 23, 2022',
      name: 'Charla P.',
      photo: 'Charla.png',
      recommends: true,
      review:
        'I had such an amazing experience they saved me from the stress of working and working on my Physics assignment. They responded quickly and were so pleasant. They teached me how to use Physics software efficiently so I could speed up my calculations. I would recommend Nerdify to some people who need help with any kind of homework problem!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Sep 19, 2022',
      name: 'Jorge S.',
      photo: 'Jorge.png',
      recommends: true,
      review:
        "I've heard a lot of good things about Nerdify and tried it on my own for Biology class. The quality of their work is university level. You will get help and then can solve any problem by yourself just on time. You got everything (quality, time, and most important chat live 24/7). Thanks, Nerdify Team!",
    },
  },

  trustpilot: {
    '01': {
      date: 'Apr 8, 2022',
      name: 'Lina L.',
      mark: '5',
      review:
        "I was skeptical at first but my friend's classmate said she used it for checking her biology lab reports and they did great so I used it for my microbiology lab reports. I requested to check my assignment and they saved me from 2 mistakes and provided tips on how to fix them! Excellent work, guys! Will use such service in future.",
      title: 'EXCELLENT SERVICE',
    },
    '02': {
      date: 'Aug 5, 2022',
      name: 'Nikki L.',
      mark: '5',
      review:
        'I was struggling with chemistry homework and I have been out of college for a while. I was grateful to have found a Chemistry nerd to help me prepare for my class. They were quick to respond and pricing was extremely reasonable. I absolutely can recommend Nerdify!',
      title: 'BEST HELP',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'May 16, 2022',
      name: 'Lia F.',
      mark: '5',
      review:
        'I have used Nerdify over 3 times and haven’t been disappointed once! They have tutored me through the Science classes that aren’t the main focus of my major and freed up my time to put into my Business classes. So glad I found Nerdify!',
      title: 'SAVE YOUR TIME',
    },
  },
};

export const records_business_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 15, 2022',
      name: 'Jake P.',
      photo: 'Jake.png',
      recommends: true,
      review:
        'Nerdify was effective, efficient, and appropriate with its service. As always, Nerdify delivers excellence through its quick response time and last-minute ability to help. Whether I need them to help with data collection for business research or a short management project, Nerdify never fails!',
    },
    '02': {
      id: '02',
      date: 'Aug 21, 2022',
      name: 'Dana M.',
      photo: 'Dana.png',
      recommends: true,
      review:
        "When I thought I wouldn't make it in my Business classes and starting my new career, I had to contact Nerdify. Nerdify was the best thing that happened to me. I received professional help, and tears of joy came running down my face after the final project. That extra tutoring help changed my worries. Thank you!",
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'May 12, 2022',
      name: 'Justine P.',
      photo: 'Justine.png',
      recommends: true,
      review:
        'Very excellent service! They helped me immediately with no hold-ups or hassles. The framework they suggested for my marketing presentation was just perfect. Great business and fast service! Cooperation with Nerdify is a great decision. You won’t regret it.',
    },
  },

  trustpilot: {
    '01': {
      date: 'Oct 11, 2022',
      name: 'Kirian B.',
      mark: '5',
      review:
        "Very quick and helpful responses, they don’t waste your time and get right on your project asap. I've requested help with checking my management assignment and all went through just perfectly. Very highly recommended service!",
      title: 'QUICK SERVICE',
    },
    '02': {
      date: 'Mar 28, 2022',
      name: 'Alfreta S.',
      mark: '5',
      review:
        "I've been stuck while writing my business report and needed some inspiration, new sources, or something to finish my work. I reached out to Nerdify and they gave me useful tips and suggested new sources. I would highly recommend them in a tight spot which is what I found myself in. Thanks, Nerdify!",
      title: 'BEST HELP',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Jul 6, 2022',
      name: 'Cutie S.',
      mark: '5',
      review:
        'They are very helpful, fast, and diligent with their work. I had an amazing experience with Business nerds and would recommend it to any college student. I wish to be using them again in the near future!',
      title: 'MY EXPERIENCE',
    },
  },
};


export const records_social_sciences_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 19, 2022',
      name: 'Luis W.',
      photo: 'Luis.png',
      recommends: true,
      review:
        'I have used this company twice now - one for an English class, the other for sociology and both results by far exceeded my expectations. The quality of writing tips and advice on paper structuring was well worth the cost. I truly value my privacy and the process from start to finish was extremely confidential, personal, and easy. I would absolutely recommend Nerdify!',
    },
    '02': {
      id: '02',
      date: 'Sep 5, 2022',
      name: 'Diana O.',
      photo: 'Diana.png',
      recommends: true,
      review:
        'Everyone that I talked to was super friendly and helpful. The turor did everything in a timely manner and were open to listening to any concerns, answered all questions that I had with my political science task. Although we only communicated through text it felt like I was talking to a real person. I’m so glad I found this site to help in hard study times. Highly recommend!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'May 16, 2022',
      name: 'Sharon R.',
      photo: 'Sharon.png',
      recommends: true,
      review:
        'Nerdify tutors have always been extremely helpful with my history and psychology tasks. 24/7 customer service is a bonus, especially if you need last-minute help. I would recommend it to everyone!',
    },
  },

  trustpilot: {
    '01': {
      date: 'Aug 7, 2022',
      name: 'Lia V.',
      mark: '5',
      review:
        'I needed last-minute assistance polishing a paper for a sociology class and I was beyond pleased with the recommendations I received, especially in such a short amount of time. The nerd was super friendly with quick replies and was able to answer all of my questions. Very good first experience using this resource! Highly recommend.',
      title: 'USER EXPERIENCE',
    },
    '02': {
      date: 'May 12, 2022',
      name: 'William E.',
      mark: '5',
      review:
        'Seriously, these guys are good! They have helped me pass political science class. I have full trust in their tutors. They have not let me down as of yet. Plus they are super quick to respond and are very engaging and asked all the right questions to prepare for the next session with me. Top-notch!',
      title: 'TRUSTWORTHY SERVICE',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Sep 14, 2022',
      name: 'Alex D.',
      mark: '5',
      review:
        "This has been an absolute lifesaver! They were so prompt in getting back to me and tutor explained me the law task at hand. I fell short on time for an assignment that I had to turn in while working on two other projects and couldn't juggle all three in time for the deadline and they stepped up and helped me out with data collection in a pinch! I will continue to use this service and absolutely recommend this to all of my friends!",
      title: 'My best experience so far',
    },
  },
};

export const records_finance_accounting_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 5, 2022',
      name: 'Laura M.',
      photo: 'Laura.png',
      recommends: true,
      review:
        "I wish I found this website sooner! I'm not a huge fan of accounting and needed help to complete a project. Nerdify made it very easy and helped me a lot. They stay in constant contact with you from the very beginning till the moment you understand everything. I highly recommend this service! Be specific with requesting the nerd you need (economics, accounting, finance, etc.), and include as many details as you can. Take a chance to try Nerdify!",
    },
    '02': {
      id: '02',
      date: 'Sep 26, 2022',
      name: 'Joseph C.',
      photo: 'Joseph C.png',
      recommends: true,
      review:
        'Very good experience, with quick replies, friendly staff, and hasty solutions. Highly recommend it to anyone that is in need of assistance with finance homework or anything of caliber.',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Jun 19, 2022',
      name: 'Thomas C.',
      photo: 'Thomas.png',
      recommends: true,
      review:
        "I was skeptical at first, I've been so busy at work and helping with my family. I barely had time to do my economics homework. But they put the effort into guiding me as long as I'm willing to compensate which seems fair. Their response time is good and their replies are acceptable. I would recommend it to everyone!",
    },
  },

  trustpilot: {
    '01': {
      date: 'Sep 20, 2022',
      name: 'Sarah P.',
      mark: '5',
      review:
        "Nerdify has been nothing short of a complete godsend and lifesaver for me this term with passing my accounting class. The agents who assisted with setting me up with the right nerd to guide me on my task were quick to respond within minutes or less. They were always kind and effective with communication and answered all my questions patiently. I can't recommend their services enough! Just try it!",
      title: 'GODSEND SERVICE',
    },
    '02': {
      date: 'Jul 7, 2022',
      name: 'Frank G.',
      mark: '5',
      review:
        'Excellent customer service, polite, professional. This service has lifted the burden from my shoulders and assisted me a lot with checking my finance task. I have a career and a second job, and along with being a student, my life is too hectic to stress over homework. Thanks a million, times over Nerdify for always being so responsive!',
      title: 'EXCELLENT SERVICE',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'May 16, 2022',
      name: 'Simone G.',
      mark: '5',
      review:
        'The best service ever! A friend recommended Nerdify to me because I was struggling in economics. I texted the number and they immediately responded within 2 minutes. Super polite, professional, and organized. I recommend Nerdify to all college students!',
      title: 'BEST EXPERIENCE',
    },
  },
};

export const records_humanities_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Jul 8, 2022',
      name: 'Medylin K.',
      photo: 'Medylin K.png',
      recommends: true,
      review:
        'Well, I can’t find words to express how happy and satisfied I am with such professionalism, and reasonably Nerdify has been in tutoring me on my nursing task. Honesty and being able to work with their customer is what really impressed me about Nerdify. I will recommend Nerdify to anyone out there seeking study help. I’m pretty sure they are smart in every other subject so feel free to give them the opportunity to assist you. God bless them all!',
    },
    '02': {
      id: '02',
      date: 'May 18, 2022',
      name: 'Stacy P.',
      photo: 'Stacy P.png',
      recommends: true,
      review:
        'Very professional service! I struggled with philosophy assignment and work full time, I did not have the time to polish homework I had due soon. Nerds helped me to check the paper just perfectly, as well as provided special writing tips. I recommend Nerdify 100%!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Mar 19, 2022',
      name: 'Kay W.',
      photo: 'Kay W.png',
      recommends: true,
      review:
        'Excellent! Obviously, I can’t speak for every nerd but the nerd that helped me to prepare for History class did an amazing job. Will work with the Nerds again for sure!',
    },
  },

  trustpilot: {
    '01': {
      date: 'Apr 26, 2022',
      name: 'Grace J.',
      mark: '5',
      review:
        'It was such a pleasure having this experience. Especially along with the study stress I know some college students are going through. Surpassed my expectations and made me even understand the healthcare case study on a different level. Amazing, I highly recommend it!',
      title: 'AMAZING',
    },
    '02': {
      date: 'Jun 7, 2022',
      name: 'Alyssa D.',
      mark: '5',
      review:
        'I have a great experience with Nerdify, I have struggled to revise and check my culture paper and Nerdify helped me with that in a very short time. Definitely recommend it and I’ll need their help again in the future.',
      title: 'GREAT EXPERIENCE',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Sep 29, 2022',
      name: 'Krish F.',
      mark: '5',
      review:
        'It was literally the fastest and most convenient service I have gotten in my entire life. The assistance was perfectly unique and provided details for my ethics topic. Just what I needed. Thank you!',
      title: 'CONVENIENT AND FAST',
    },
  },
};

export const records_english_literature_help = {
  facebook: {
    '01': {
      id: '01',
      date: 'Apr 9, 2022',
      name: 'Marcello L.',
      photo: 'Marcello L..png',
      recommends: true,
      review:
        'I love Nerdify! These people have been nothing but a true help to me as I struggle with English writing. They helped me improve my grammar and develop good attention to detail. Your staff is a hard worker and a good communicator. They come in handy every single time!',
    },
    '02': {
      id: '02',
      date: 'Jul 14, 2022',
      name: 'Jay B.',
      photo: 'Jay B.png',
      recommends: true,
      review:
        'Nerdify is the best! They talk to you personally and make sure you have everything you need. I used them to get some tips for the literature review. They not only help you improve your writing skills they will help you with anything they possibly can provide for any subject. Very highly recommended!',
    },
    '03': {
      id: '03',
      date: 'Mar 28, 2020',
      name: 'Tom D.',
      photo: '03.jpg',
      photo2x: '03@2x.jpg',
      review:
        'I’ve been using Nerdify for 2 years now. Their Nerds are talented and they are always in time for a tutoring session. Their customer service is respectful and they make sure that they have all the necessary information before getting started. I am recommending them to all.',
      recommends: true,
    },
    '04': {
      id: '04',
      date: 'Mar 25, 2020',
      name: 'Reyansh C.',
      photo: '04.jpg',
      photo2x: '04@2x.jpg',
      review:
        'I made a last-minute decision and contacted them. They were able to help me till the very end and ease my stress. I appreciate everything they did! I would recommend them to anyone in need of urgent help!',
      recommends: true,
      response: 'Wow, thanks for your feedback. <3',
    },
    '05': {
      id: '05',
      date: 'Mar 14, 2020',
      name: 'Cody P.',
      photo: '05.jpg',
      photo2x: '05@2x.jpg',
      review:
        'Recommended, but recently had a pretty sad situation with my request due about two weeks out from now and got rejected by them because they did not have the available Nerds to handle it. Other than that they’ve exceeded my expectations with quality, communication, and Nerdify is overall the best one out there.',
      recommends: true,
      response:
        "Happy to help you out, Cody. So sorry we couldn't meet your expectations due to the lack of Nerds. Our Nerds' workload is pretty heavy. This is not a common situation, but it can sometimes happen :( We will do our best to provide you a 5-star user experience further! Hope to see you back.",
    },
    '06': {
      id: '06',
      date: 'Mar 3, 2020',
      name: 'Anita T.',
      photo: '06.jpg',
      photo2x: '06@2x.jpg',
      recommends: true,
      review:
        'No matter what the task is, they will always guide you to the solution. They are very professional and communicate with their customers. I would highly recommend them.',
    },
    '07': {
      id: '07',
      date: 'Feb 27, 2020',
      name: 'Karen G.',
      photo: '07.jpg',
      photo2x: '07@2x.jpg',
      recommends: true,
      review:
        "Nerdify has been a savior for several of my courses. I have been working with Nerds for quite a while now and must admit that they provide the best tutoring service! Cost-effective and almost instant replies — who doesn't want that?!",
    },
    '08': {
      id: '08',
      date: 'Feb 23, 2020',
      name: 'Pamela T.',
      photo: '08.jpg',
      photo2x: '08@2x.jpg',
      recommends: true,
      review:
        "Thank you for helping with my request!!! You guys did a good job for me! I'm very satisfied with the final result and in a timely manner! Thank you so much! You guys are the best Nerds ever! I will come to you with another request next time! THANK YOUU! 💕",
    },
    '09': {
      id: '09',
      date: 'Jan 15, 2020',
      name: 'Abigail R.',
      photo: '09.jpg',
      photo2x: '09@2x.jpg',
      review:
        "I am disappointed with Nerdify and the way they handled my potential task. I have used them in the past and never had a problem until today! The price they wanted to charge me cuz of the short deadline way too high and I couldn't have afforded it even if I wanted to. I really believe that a service should be more understanding and flexible when it comes to loyal users. I would not consider using their services again :(",
      recommends: false,
      response:
        "Dear Abigail, we are so sorry for this situation and all the stress you've been through. Our customer support representative did her best to figure out the best options to offer. The Nerds are professionals and charge for their help accordingly. The price for urgent help within 12 hours is always higher, because in this case your task becomes prioritized for your Nerd. As much as we wanted to offer you even lower price in this case, we were unable to shed extra from the low-cost price of your task. We really appreciate having you in our Nerdify family. Please, give us a second chance!",
    },
    10: {
      id: '10',
      date: 'Sep 29, 2022',
      name: 'Katy W.',
      photo: 'Katy W.png',
      recommends: true,
      review:
        'Got some tutoring help for my English class. They are great! Very patient and helpful. I’m very glad I found them since it’s kind of hard to deal with several classes at the same time. 100% recommend it!',
    },
  },

  trustpilot: {
    '01': {
      date: 'Oct 4, 2022',
      name: 'Quito S.',
      mark: '5',
      review:
        "Nerdify is really amazing! This is my first time to have used it. I had to make a speech outline. English is not really my language. So I'm having a hard time understanding everything. The fact that I have to deliver a speech in front of a lot of people, will make me panic so badly. I’m glad Nerdify was able to help me with practicing my speech. Thank you to my Nerd and to Nerdify!",
      title: 'VERY HELPFUL',
    },
    '02': {
      date: 'Mar 4, 2022',
      name: 'Kelsi D.',
      mark: '5',
      review:
        "I’m honestly the worst at writing a Literature review and I kept putting it off. Finally, I decided to get started and I couldn’t piece it together so I searched online and found Nerdify! I was very impressed with the level of care they have and really loved how they took on board every piece of information I provided. I completed the review in a couple of hours using tutor's advices. It’s such a great service and has told all my friends!",
      title: 'THEY REALLY CARE',
    },
    '03': {
      date: 'Mar 11, 2020',
      name: 'Yusraa K.',
      mark: '5',
      review:
        'It has been the best experience so far! Completely happy with the results of the work along with the punctual timing. I also loved how easy it was to communicate with Nerdify to get qualified help with my task!',
      title: 'Happy with the results',
      response: 'Thank you for taking the time to share your feedback!',
    },
    '04': {
      date: 'Mar 5, 2020',
      name: 'Randi M.',
      mark: '4',
      review:
        'Although I have had some difficulties with Nerdify, they always pull through. I appreciate the hard work, Nerdify! My only suggestion is to re-evaluate pricing when you need some additional time. ',
      title: 'Not ideal, but nice',
      response:
        "Thank you, Randi! Every feedback helps us understand how to make our users' experience even better. We really appreciate your review.",
    },
    '05': {
      date: 'Feb 28, 2020',
      name: 'Wolfy',
      mark: '5',
      review:
        'Thank you so much Nerdify! I had a bunch of tests and exams and absolutely no time but you helped me prepare for them on a short notice! I appreciate your service and I have another test coming up in a week and I’ll definitely ask you guys for another tutoring session! This is much appreciated! I recommend this to anyone.',
      title: 'Would recommend',
    },
    '06': {
      date: 'Feb 20, 2020',
      name: 'Kye N.',
      mark: '5',
      review:
        'I have to say I am very impressed. I texted the number with what I needed and they handled it every step of the way. You’re even able to check on the progress of your request. They were on the ball with answering my questions and communicating on every concern that arose. I needed a quick check of one final paper and they were super considerate and fast to point out a few minor issues for me to improve! If you’re on the fence about this company, I would encourage you to give it a try.',
      title: 'Simply great!',
      response:
        'Thank you so much for your review, Kye! We will do our best to keep you happy! <3',
    },
    '07': {
      date: 'Feb 4, 2020',
      name: 'B. Martinez',
      mark: '5',
      review:
        "I have been using Nerdify now for a couple of years. If it weren't for them I would not know what to do. They are available for me 24/7, always respond fast and very willing and eager to help you. I'm glad I found them.",
      title: 'Never failed me',
    },
    '08': {
      date: 'Jan 30, 2020',
      name: 'Daniel',
      mark: '5',
      review:
        'Used the service of Nerdify. Whilst I was initially skeptical, in retrospect after using them, I am so glad I did use them. Had absolutely no issues whatsoever. Their friendly customer service on Facebook was quick to respond even at 1 am (great for those who are night owls). All was done within the promised time frame. And it exceeded my expectations! So the fact that they went above and beyond as well as the entire experience being stress-free is why I want to put my genuine review out there and let others know about this wonderful service. I would definitely recommend trying them out!',
      title: 'Timely and friendly',
    },
    '09': {
      date: 'Jan 15, 2020',
      name: 'Kalsey C.',
      mark: '3',
      review:
        'I liked it but it’s expensive. As a college student it is hard to pay for everything especially when you need both to work and go to school. Overall I liked experience.',
      title: 'Too expensive(',
      response:
        'Hey there, Kalsey! Have you tried Nerdify referral program? It can earn you some bonuses for referring friends that you can use to shed the price for future help requests :)',
    },
    10: {
      date: 'Dec 18, 2019',
      name: 'Anna S.',
      mark: '2',
      review:
        "I don't get it, really. On the website they claim to text back in like 10 seconds, but noone reached out to me! I've been waiting for 30 min or so and nothing. Do what you promise or don't do it at all. That's not how the service should work.",
      title: 'I was ignored!',
      response:
        'Dear Anna, please accept our sincere apology. We are very sorry to not have contacted you within the promised timeframe and any inconvenience this may have caused you. The error was due to a computer glitch in our system. We thank you for bringing this to our attention. It will help us improve our operations and services. Hope to see you back in future!',
    },
    11: {
      date: 'Apr 8, 2022',
      name: 'Monica P.',
      mark: '5',
      review:
        'I have used this service three times now for help with Literature and I love it! The nerds are amazing at what they do and you can tell that they put so much effort into what they do. I can’t say how much I appreciate them for being here when I needed it and they are so understanding about what I need. Thank you so much!',
      title: 'LOVE NERDIFY',
    },
  },
};

const DemoCards = (props) => {
  return (
    <DemoCardsTheme
      title={props.title}
      subTheme={props.subTheme}
      indent={props.indent}
      records={props.records ? props.records : records}
      activeItem={props.activeItem}
      titleTag={props.titleTag}
    />
  );
};

const DemoCards1 = (props) => {
  return (
    <DemoCardsTheme1
      title={props.title}
      subTheme={props.subTheme}
      indent={props.indent}
      records={props.records ? props.records : records}
      activeItem={props.activeItem}
      titleTag={props.titleTag}
    />
  );
};

export { DemoCards as default, DemoCards1 };
