import React from 'react';

export const lp1block2 = {
  title: <>Elevate Your Child's <br className="mobile-only"/><span className="highlight">Math Skills.</span><br/>Anytime. Anywhere.</>,
  items: [
    {
      img: 'teacher',
      title: 'Expert Tutors',
      text: 'All of the tutors are  pre-screened and vetted educators based in the US.'
    },
    {
      img: 'clock',
      title: 'Flexible scheduling',
      text: 'No commuting, no hassle. Our Math lessons will fit seamlessly into your child’s schedule.'
    },
    {
      img: 'smile',
      title: 'No HW for Parents',
      text: 'From matching your child with top US tutors to sending you progress reports, we\'ve got it all covered.'
    },
    {
      img: 'bubble',
      title: 'Always Connected',
      text: 'With our 24/7 text-based concierge service, you\'re always in the loop without disrupting your day.'
    },
  ]
};
