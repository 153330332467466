import React, { Component } from 'react';

import imgRefresh from './img/refresh.png';

import Item from './Item';

class CardsMobile extends Component {
  constructor(props) {
    super(props);

    const { source, records } = this.props;

    const items = Object.keys(records[source]).sort();

    this.state = {
      show: 3,
      source,
      records,
      items,
    };

    this.increase = this.increase.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.source !== prevProps.source) {
      const { source, records } = this.props;
      const items = Object.keys(records[source]).sort();

      this.setState({
        source,
        items,
      });
    }
  }

  increase() {
    this.setState((state) => ({ show: (state.show += 3) }));
  }

  renderItems() {
    const { show, source, items } = this.state;
    const { records } = this.props;

    const result = [];
    for (let i = 0; i < show; i++) {
      const value = records[source][items[i]];
      if (!value) {
        break;
      }
      result.push(<Item key={i} value={value} source={source} />);
    }
    return result;
  }

  renderLoadMore() {
    const { show, items } = this.state;
    if (show >= items.length) {
      return null;
    }

    return (
      <div className="loadMore">
        <div className="loadButton" onClick={this.increase}>
          <img
            loading="lazy"
            src={imgRefresh}
            className="imgRefresh"
            width="38"
            height="32"
            alt=""
          />
          <span className="textRefresh">Show more reviews</span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="cards cards_1">
        {this.renderItems()}
        {this.renderLoadMore()}
      </div>
    );
  }
}

export default CardsMobile;
