import React from 'react';
import {NavLink} from 'react-router-dom';
import Holder from './../../../../themes/nerdify/components/Holder';
import { Header2, HeaderIcon } from './../../../../themes/nerdify/components/Header';
import { Footer2 } from '../../blocks/Footer'
import Content from './../../../../themes/nerdify/components/Content';
import TextPage from './../../../../themes/nerdify/components/TextPage';

import { HnavMob2 } from '../../blocks/HnavMob';
import { Hnav3 } from "../../blocks/Hnav";
//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import {useMobile} from "../../../../hooks";

const TextPage1 = ({ className, children, ...rest }) => {
  const isMobile = useMobile();

  return (
    <TextPage>
      <Header2 key='textpage-header'>
        <Holder>
          <NavLink className='logo-link' to='/'>
            <HeaderIcon iconName='logo-dark' />
          </NavLink>
          {isMobile ? <HnavMob2 /> : <Hnav3 />}

{/*
          <div className="header-wrap">
            <NavLink className="button button__type9 hero__go-home" to='/'>Go to Home page</NavLink>
            <HnavMob2 />
            <InputSelectLocales
              className='input-select__short'
              name='country'
              isError={false}
            />
          </div>
*/}
        </Holder>
      </Header2>
      <Content className={className} key='textpage-content'>
        {!rest.noWrap ? <div className="textPage">{children}</div> : children}
      </Content>
      <Footer2 />
    </TextPage>
  )
};

export default TextPage1
