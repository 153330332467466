import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder'

import './index.css'

export default ({ children }) => {
    return (
        <div className="hero hero--3">
            <Holder className="hero__holder">
                {children}
            </Holder>
        </div>
    )
}