import React, { useState, useEffect } from 'react';
import classnames from "classnames";

import Hiw, {
  HiwList,
  HiwItem,
  HiwImg as HiwImgBase,
  HiwText,
} from '../../../../base_components/Hiw';

import './index.css';

export const Hiw1 = (props) => {
  return <Hiw {...props} className={'hiw_1'} />
};

export const Hiw2 = (props) => {
  return <Hiw {...props} className={classnames('hiw_2', props.indent)} />
};

export const Hiw3 = (props) => {
  return <Hiw {...props} className={'hiw_3'} />
};

export const Hiw4 = (props) => {
  return <Hiw {...props} className={'hiw_4'} />
};

export const Hiw5 = (props) => {
  return <Hiw {...props} className={'hiw_5'} />
};

export const Hiw6 = (props) => {
  return <Hiw {...props} className={'hiw_6'} />
};

export const Hiw7 = ({ children, subTheme, indent }) => <div
  className={classnames('hiw_7', {[`hiw_7_${subTheme}`]: subTheme}, indent)}>
  { children }
</div>;

export const HiwClp3 = ({ children }) => <div
  className={classnames('hiwClp3')}>
  { children }
</div>;

const HiwImg = ({ img, className, ext, ...rest }) => {
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const imgSrc = require(`./img/${img}.${ext ? ext : 'svg'}`);
    const image = new Image();
    image.src = imgSrc;

    // После загрузки изображения получаем его ширину и высоту
    image.onload = () => {
      setImageSize({ width: image.width, height: image.height });
    };
  }, [img, ext]);

  return (
    <HiwImgBase {...rest}>
      <img
        loading="lazy"
        className={className}
        src={require(`./img/${img}.${ext ? ext : 'svg'}`)}
        width={imageSize.width}
        height={imageSize.height}
        alt=""
      />
    </HiwImgBase>
  );
};

HiwImg.displayName = 'HiwImg';

const HiwList2 = ({items}) =>{
  return (
    <ul className='hiw__list'>
      {items.map((item, index, key) => {
        return (
          <li className='hiw__item' key={`hiwItem${key}`}>
            <div className={`hiw__img hiw__img_${index + 1}`}>
              <img src={require(`./img/${item.img}`)} alt={''}/>
            </div>
            <div className='hiw__text-holder p'>
              {item.message}
            </div>
          </li>
        );
      })}
    </ul>
  )
};

export {
  HiwList,
  HiwList2,
  HiwImg,
  HiwItem,
  HiwText,
}
