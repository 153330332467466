import React, { useState } from 'react';
import Holder from '../../../../themes/nerdify/components/Holder'
import Services, { ServicesIcon, ServicesImg, DocumentDownloadLink } from '../../../../themes/nerdify/components/Services';
import { ModalDocumentPreview } from './../../../../themes/nerdify/components/Modal';

const ServicesBlock = (props) => {
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewImg, setPreviewImg] = useState('');
  const [previewDoc, setPreviewDoc] = useState('');

  function onOpenPreviewModal(e) {
    const { dataset } = e.currentTarget;
    const { document, img } = dataset;
    setPreviewOpen(true);
    setPreviewImg(img);
    setPreviewDoc(document);
  }

  function onClosePreviewModal() {
    setPreviewOpen(false);
  }

  return (
    <Services className="services-block__type2">
      <Holder className="holder_1" indent={"pt40d"}>
        <div className="services__title">Upgrade your task<br className="mobile-only" /> with most popular<br className="mobile-only" /> services from just $10</div>
        <div className="services-list">

          <div className="services-list-item">
            <ServicesImg className="services-list-img img1" img={'micro-1.svg'} />
            <div className="services-list-block">
              <div className="services-list-title">Credible sources<br className="mobile-only" /> for your task</div>
              <div className="screenshot-wrapper">
                <ServicesImg className="preview-img" img={'preview1.png'} />
                <button className="screenshot-zoom"
                        type="button"
                        data-document={'Sample_Sources.pdf'}
                        data-img={'Sample_Sources.png'}
                        onClick={onOpenPreviewModal}
                >
                  <ServicesIcon iconName='zoom-icon' />
                </button>
              </div>
              <div className="services-list-label">$10 per 5 sources</div>
              <div className="services-list-info">Get five relevant sources in MS Word document formatted accordingly.</div>
              <DocumentDownloadLink
                className="download-preview-link"
                link={'Sample_Sources.pdf'}
                download={'Sample_Sources.pdf'}>
                <ServicesIcon iconName='pdf-icon' />
                <span className="download-preview-link-msg">Download preview</span>
              </DocumentDownloadLink>
              <button className="show-example-btn"
                      type="button"
                      data-document={'Sample_Sources.pdf'}
                      data-img={'Sample_Sources.png'}
                      onClick={onOpenPreviewModal}>
                Show example
              </button>
            </div>
          </div>

          <div className="services-list-item">
            <ServicesImg className="services-list-img img2" img={'micro-2.svg'} />
            <div className="services-list-block">
              <div className="services-list-title">Impeccable task formatting</div>
              <div className="screenshot-wrapper">
                <ServicesImg className="preview-img" img={'preview4.png'} />
                <button className="screenshot-zoom"
                        type="button"
                        data-document={'Formatting.pdf'}
                        data-img={'before_after_cut.png'}
                        onClick={onOpenPreviewModal}
                >
                  <ServicesIcon iconName='zoom-icon' />
                </button>
              </div>
              <div className="services-list-label">$10 per 1200 words</div>
              <div className="services-list-info">Get your task formatted according to a chosen style: MLA, APA, Chicago, etc.</div>
              <DocumentDownloadLink
                className="download-preview-link"
                link={'Formatting.pdf'}
                download={'Formatting.pdf'}>
                <ServicesIcon iconName='pdf-icon' />
                <span className="download-preview-link-msg">Download preview</span>
              </DocumentDownloadLink>
              <button className="show-example-btn"
                      type="button"
                      data-document={'Formatting.pdf'}
                      data-img={'before_after_cut.png'}
                      onClick={onOpenPreviewModal}>
                Show example
              </button>
            </div>
          </div>

          <div className="services-list-item">
            <ServicesImg className="services-list-img img3" img={'micro-3.svg'} />
            <div className="services-list-block">
              <div className="services-list-title">Combo: proofreading<br/> & formatting</div>
              <div className="screenshot-wrapper">
                <ServicesImg className="preview-img" img={'preview4.png'} />
                <button className="screenshot-zoom"
                        type="button"
                        data-document={'Combo Proofreading and Formatting.pdf'}
                        data-img={'before_after_1.png'}
                        onClick={onOpenPreviewModal}
                >
                  <ServicesIcon iconName='zoom-icon' />
                </button>
              </div>
              <div className="services-list-label">$20 per 1200 words</div>
              <div className="services-list-info">Get spelling, grammar and punctuation errors fixed & text properly formatted.</div>
              <DocumentDownloadLink
                className="download-preview-link"
                link={'Combo Proofreading and Formatting.pdf'}
                download={'Combo Proofreading and Formatting.pdf'}>
                <ServicesIcon iconName='pdf-icon' />
                <span className="download-preview-link-msg">Download preview</span>
              </DocumentDownloadLink>
              <button className="show-example-btn"
                      type="button"
                      data-document={'Combo Proofreading and Formatting.pdf'}
                      data-img={'before_after_1.png'}
                      onClick={onOpenPreviewModal}>
                Show example
              </button>
            </div>
          </div>


        </div>
      </Holder>

      <ModalDocumentPreview
        className='modal-document-preview'
        isOpen={isPreviewOpen}
        onClose={onClosePreviewModal}
        previewImg={previewImg}
        previewDoc={previewDoc}
      />
    </Services>
  )
};

export default ServicesBlock
