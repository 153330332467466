import React, { Component } from 'react';
import VideoModal from '../VideoModal';
import classnames from 'classnames';
import './snapWatchLearn.css';

export default class snapWatchLearn extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVideoModalOpen: false,
      step: 1,
      //stepItemHeight: 0,
    };

    this.handleStepChange = this.handleStepChange.bind(this);
    this.openVideoModal = this.openVideoModal.bind(this);
    this.closeVideoModal = this.closeVideoModal.bind(this);
  }

/*
  componentDidMount() {
    const stepItemHeight = document.getElementsByClassName("snap-step-item")[0].clientHeight;
    this.setState({ stepItemHeight });
  }
*/

  handleStepChange(step) {
    this.setState({ step });
  }
  openVideoModal() {
    this.setState({ isVideoModalOpen: true });
  }
  closeVideoModal() {
    this.setState({ isVideoModalOpen: false });
    this.handleStepChange(4);
  }

  render() {
    //const { pageCfg } = this.props;
    const { step, isVideoModalOpen } = this.state;

    return (
      <div className="snap-watch-learn">
        <div className="holder">
          <div className="snap-title">Snap. Watch. Learn.</div>
          <div className="snap-subtitle">Here is how it works</div>
          <div className="snap-block">
            <div className="snap-step-list">

              <button className={classnames('snap-step-item', step === 1 ? 'active' : '')} onClick={() => this.handleStepChange(1)}>
                <div className="snap-step-img-wrapper">
                  <img className="snap-step-img" src={require("./img/step-1-icon.svg")} alt=""/>
                </div>
                <div className="snap-step-item-body">
                  <div className="step-num">Step 1</div>
                  <div className="step-title">Snap a photo</div>
                  <div className="step-msg">Simply take a picture of your task or tell us what you need help with</div>
                </div>
              </button>

              <button className={classnames('snap-step-item', step === 2 ? 'active' : '')} onClick={() => this.handleStepChange(2)}>
                <div className="snap-step-img-wrapper">
                  <img className="snap-step-img" src={require("./img/step-2-icon.svg")} alt=""/>
                </div>
                <div className="snap-step-item-body">
                  <div className="step-num">Step 2</div>
                  <div className="step-title">Get a Nerd</div>
                  <div className="step-msg">Expert Nerd will come to your rescue and record an on-demand video explanation </div>
                </div>
              </button>

              <button className={classnames('snap-step-item', step === 3 ? 'active' : '')} onClick={() => this.handleStepChange(3)}>
                <div className="snap-step-img-wrapper">
                  <img className="snap-step-img" src={require("./img/step-3-icon.svg")} alt=""/>
                </div>
                <div className="snap-step-item-body">
                  <div className="step-num">Step 3</div>
                  <div className="step-title">Watch a video</div>
                  <div className="step-msg">Get a video with a step-by-step explanation of how to solve your problem </div>
                </div>
              </button>

              <button className={classnames('snap-step-item', step === 4 ? 'active' : '')} onClick={() => this.handleStepChange(4)}>
                <div className="snap-step-img-wrapper">
                  <img className="snap-step-img" src={require("./img/step-4-icon.svg")} alt=""/>
                </div>
                <div className="snap-step-item-body">
                  <div className="step-num">Step 4</div>
                  <div className="step-title">Ask away</div>
                  <div className="step-msg">Our Nerd is happy to answer any of your follow-up questions </div>
                </div>
              </button>

              <div className={classnames('highlight', `step${step}`)}/>

            </div>

            <div className={classnames('snap-cell-wrapper', `step${step}`)}>
              <div className="snap-cell shot1">
                <img className="snap-cell-img" src={require("./img/step1.png")} alt=""/>
                <div className="tap-field" onClick={() => this.handleStepChange(2)}>
                  <div className="tap-label bottom-pointer">Tap here!</div>
                  <div className="tap-figure" />
                </div>
              </div>
              <div className="snap-cell shot2">
                <img className="snap-cell-img" src={require("./img/step2.png")} alt=""/>
                <div className="tap-field" onClick={() => this.handleStepChange(3)}>
                  <div className="tap-label top-pointer">Tap here!</div>
                </div>
              </div>
              <div className="snap-cell shot3">
                <img className="snap-cell-img" src={require("./img/step3.png")} alt=""/>
                <div className="tap-field" onClick={() => this.openVideoModal()}>
                  <div className="tap-label left-pointer">Tap here!</div>
                </div>
              </div>
              <div className="snap-cell shot4">
                <img className="snap-cell-img" src={require("./img/step4.png")} alt=""/>
                <div className="tap-field" onClick={() => this.openVideoModal()} />
              </div>
            </div>

          </div>
        </div>

        {isVideoModalOpen &&
        <VideoModal type='2' videoId='483970768' onClose={() => this.closeVideoModal()} />
        }

      </div>
    )
  }
}

