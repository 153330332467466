const facebook = {
  facebook: [
    {
      "name": "Eva Dean",
      recommends: true,
      "date": "January 19, 2024",
      "review": "The Nerdify team is amazing, words can't describe their punctuality and reliability. I recommend to everyone this great app. Thanks for everything and keep up the great work!",
      "nerdify": "Thank you for your kind words, Eva! We’ll keep doing our best to help you!"
    },
    {
      "name": "Alexandra Ryan",
      recommends: true,
      "date": "January 17, 2024",
      "review": "Their communication is excellent, the service is always timely and organized. They definitely put customers first and go above and beyond to be helpful. Highly recommended!",
      "nerdify": "Thank you, Alexandra! The Nerdify team is here to help when you need it😊🧡"
    },
    {
      "name": "Stephen Jackson",
      recommends: true,
      "date": "January 15, 2024",
      "review": "Best app ever for study help! They helped me with the classes I was sruggling with. Thanks to Nerdify, my grades have significantly improved! 💯🔥",
      "nerdify": "Thanks, Stephen! We’re always here to help 😉"
    },
    {
      "name": "Lilly Horton",
      recommends: true,
      "date": "January 10, 2024",
      "review": "Nerdify has been literally a lifesaver! I have used them multiple times and every time they do amazing work. When I was first introduced to Nerdify, I was skeptical about it, but I learned very quickly it can be trusted. I've recommended Nerdify to friends and family and they all have had positive reviews.",
      "nerdify": "Thank you, Lilly, for taking the time to share your feedback! Your support means a lot to us 🥰❤️"
    },
    {
      "name": "Charles Hamby",
      recommends: true,
      "date": "January 7, 2024",
      "review": "My speech was brilliant after my Nerd James reviewed it and gave me some tips. I highly recommend it!",
      "nerdify": "Thank you for your great review, Charles! 🤗"
    },
    {
      "name": "Terry Oliver",
      recommends: true,
      "date": "January 4, 2024",
      "review": "I'll definitely recommend this service to all my mates. They saved me so much time on preparing for my classes.",
      "nerdify": "We’re so happy you’re satisfied with our services, Terry! Invite your friends via your Nerdify referral link and get bonuses 😉🧡"
    },
    {
      "name": "Fraser Sheppard",
      recommends: true,
      "date": "December 29, 2023",
      "review": "They have a very friendly and attentive customer support, and the prices are affordable. I'd recommend Nerdify to those who need help with their studies.",
      "nerdify": "Thank you, Fraser! We’re always here to help 😉🧡"
    },
    {
      "name": "Sara Stewar",
      recommends: true,
      "date": "December 28, 2023",
      "review": "With their support, I prepared my history research on a high level and got one of the top grades.",
      "nerdify": "Sara, we’re over the moon to hear that you’re happy with your Nerdify experience 🥰"
    },
    {
      "name": "Michael Walton",
      recommends: true,
      "date": "December 26, 2023",
      "review": "Thanks to Nerdify I not only survived but excelled in my Physics class.",
      "nerdify": "We're thrilled to know that we played a part in your academic success, Michael! If you ever need assistance again, don't hesitate to reach out 😊💛"
    },
    {
      "name": "Alina Watson",
      recommends: true,
      "date": "December 21, 2023",
      "review": "Nerdify helped me with my project. They reviewed my reports and made essential suggestions. It was exactly what I needed! I'm grateful to have found such reliable support for my studies.",
      "nerdify": "Thanks for your feedback, Alina💚"
    },

    {
      name: "Ester Joan",
      recommends: true,
      date: "December 2, 2023",
      review: "Nerdify's support team is simply outstanding. They've been there for me every step of the way, providing excellent tutoring service and making my learning journey enjoyable.",
      nerdify: "Thanks, Ester, we're happy to help 🤗"
    },
    {
      name: "Nicole Benneti",
      recommends: true,
      date: "December 1, 2023",
      review: "Nerdify's support is like having a friend by your side. Their excellent tutoring service and great study assistance have been invaluable in my during my college life.",
      nerdify: "Thank you so much, Nicole! 😍"
    },
    {
      name: "Sarah Jones",
      recommends: true,
      date: "December 1, 2023",
      review: "My experience with Nerdify was great. Nerds are knowledgeable and friendly and helped me to prepare for my Chem classes on a high level. I would definitely recommend them to anyone looking for study help.",
      nerdify: "Thank you, Sarah!"
    },
    {
      name: "Elham Joy",
      recommends: true,
      date: "November 27, 2023",
      review: "Nerdify's customer support is incredibly helpful. They are available 24/7, always ready to provide answers and support, and their nerds are making the learning process smoother.",
      nerdify: "Thank you, Elham!"
    },
    {
      name: "Maya Michael",
      recommends: true,
      date: "November 26, 2023",
      review: "The Nerds are some of the most skilled tutors I have ever worked with. They were able to help me with everything from math to literature, and made learning fun and engaging.",
      nerdify: "Thank you, Maya! 😍"
    },
    {
      name: "Ricky Ponting",
      recommends: true,
      date: "November 24, 2023",
      review: "I can't thank Nerdify enough for their exceptional platform. Their assistance in finding books for my Sociology class and compiling sources for my project was a game-changer.",
      nerdify: "Thanks, Ricky! 💚"
    },
    {
      name: "Katherine Fox",
      recommends: true,
      date: "November 23, 2023",
      review: "Nerdify is a true friend to students. Their excellent team of Nerds and attentive customer support have been a constant source of help throughout my studies.",
      nerdify: "Thank you so much, Katherine ❤️"
    },
    {
      name: "Braulia Bradnam",
      recommends: true,
      date: "November 18, 2023",
      review: "I'm so glad I found Nerdify. Their tutors are friendly and patient and helped me feel confident in my studies.",
      nerdify: "Thanks, Braulia!"
    },
    {
      name: "Jem John",
      recommends: true,
      date: "November 14, 2023",
      review: "I had a great experience with Nerdify. My Nerd Jane was always available to help me with my questions, and her guidance was always thorough and on time.",
      nerdify: "Thank you so much, Jem! 🤩"
    },
    {
      name: "Simon Sinni",
      recommends: true,
      date: "November 11, 2023",
      review: "Shoutout to Mariam for her exceptional assistance and support during my studies. She always was there for me and demonstrated a remarkable understanding of the topics. Her help made a significant difference, and I'm incredibly grateful for her valuable guidance. Thank you, Mariam, for going above and beyond to help me succeed!",
      nerdify: "Thank you, Simon, we're over the moon to hear that you loved your Nerdify experience! We'll tell your thanks to Mariam too ❤"
    },
    {
      name: "Steffen Robert",
      recommends: true,
      date: "September 29, 2023",
      review: "Nerdify is the best tutoring service I've ever used. Their tutors are top-notch and their customer service is second to none.",
      nerdify: "Steffen, thank you for taking time to share your Nerdify experience with us! 💚"
    },
    {
      name: "Jennifer Taylor",
      recommends: true,
      date: "September 16, 2023",
      review: "Nerdify is an excellent resource for those who need extra help. Their customer support is patient and supportive. I decided to start a blog and had issues with the first article I was writing. Kate was the Nerd who helped me to fix and improve it. Now, I'm sure I'll succeed with such great support!",
      nerdify: "Thank you, Jennifer 💙💙💙"
    },

    {
      name: "Imrana Yasmin Urmi",
      recommends: true,
      date: "November 21, 2022",
      review: "Nerdify representatives responded to me so quickly and politely. Their service is cooperative and timely. I absolutely recommend Nerdify.",
      nerdify: "Thanks for your kind words, Imrana!"
    },
    {
      name: "Jacquice Long",
      recommends: true,
      date: "October 31, 2022",
      review: "I am very pleased with the service provided by Nerdify. If I could give 10 stars I would!!",
      nerdify: "Thank you, Jacquice! We’re over the moon to hear that you’re happy!"
    },
    {
      name: "Heather DS",
      recommends: true,
      date: "July 19, 2022",
      review: "I had a task where I was in a pinch and needed coaching, so I Googled who may be able to help. Nerdify popped up, and I went for it! The customer service responses were amazingly fast. The Nerd assigned to my request did a great job explaining and interpreting concepts. I will most definitely use Nerdify's service again. I didn't even know such a service existed before, so I'm sure if others are in the same boat, they may not be aware of Nerdify and may even be apprehensive, but they will not regret using it!",
      nerdify: "Thank you so much, Heather!"
    },

    {
      name: "Stacy Antunez Moreno",
      recommends: true,
      date: "June 20, 2022",
      review: "They were great. Helped out when my grandson was in need. I asked for their help, and within a few hours we received what we requested. They're very professional, easy to work with, and responsive throughout.",
      nerdify: "Thanks, Stacy! We are happy to be helpful 💞"
    },


    {
      name: "Temitope Kolawole Oluwasuyi",
      recommends: true,
      date: "February 28, 2022",
      review: "Nerdify is so helpful! I'm very satisfied. 😍😍😍",
      nerdify: "We are glad we could help!"
    },
    {
      name: "Benetta Gamble",
      recommends: true,
      date: "November 4, 2021",
      review: "Nerdify takes into consideration my needs and concerns, as well as gives me the option to work with the same Nerd.",
      nerdify: "Thanks for your nice feedback, Benetta!"
    },
    {
      name: "Salsabeel Samhouri",
      recommends: true,
      date: "August 30, 2021",
      review: "I used Nerds to help me understand concepts as I worked on my homework. They are so good! Thank you so much!",
      nerdify: "Thank you for sharing such nice feedback!"
    },
    {
      name: "Kimberlina Omeir",
      recommends: true,
      date: "April 26, 2021",
      review: "Nerdify is the best. I highly recommend them because the Nerds are truly amazing.",
      nerdify: "Thanks!"
    },
    {
      name: "Sundus Al-Sallami",
      recommends: true,
      date: "April 20, 2021",
      review: "Lifesavers! You get to be very specific on what you need! They respond quickly, and their work is professional! I would highly recommend this if you have difficult tasks! Love them!",
      nerdify: "We love you too! "
    },
    {
      name: "Crush Crush",
      recommends: true,
      date: "February 27, 2021",
      review: "This website does an amazing job, especially, when you are short it time. The best service ever!",
      nerdify: "Thank you so much for your feedback!"
    },
    {
      name: "Wasi Chow",
      recommends: true,
      date: "March 14, 2021",
      review: "The work was very professional, I am glad to be doing business with them.",
      nerdify: "Thanks for your feedback!"
    },
    {
      name: "Sara Kaplan",
      recommends: true,
      date: "March 11, 2021",
      review: "Nerdify is the quickest and most efficient way to get help if you are short in time.",
      nerdify: "Thank you, Sara!"
    },
    {
      name: "Victoria Obando",
      recommends: true,
      date: "March 2, 2021",
      review: "What I really like about Nerdify is that they are always friendly and do their best to help me out.",
      nerdify: "Thanks, Victoria!"
    },
    {
      name: "Jen Fox",
      recommends: true,
      date: "February 21, 2021",
      review: "I've always struggled in Geometry and Algebra since I’ve been out of high school for more than 10 years! After endless days of struggling with my class, I decided to hire a Nerd! I'm in medical school and still had a little of my high school to do. This took a lot of weight off my shoulders. The tutoring process was quick and stress-free. The price was great and worth it! I would definitely recommend Nerdify if you have something that you need some extra help on! They offer help in many subjects. 10/10!",
      nerdify: "Thank you so much, Jen!"
    },
    {
      name: "Rebeca Galvez",
      recommends: true,
      date: "February 15, 2021",
      review: "They’re the best!!! They respond to you back within minutes. I’m super happy with the service I received. I will definitely contact them again!",
      nerdify: "Thank you so much, Rebeca!"
    },
    {
      name: "Darius Scott",
      recommends: true,
      date: "February 10, 2021",
      review: "Nerdify helped me in a real crunch time situation. I told them about my problem and they made everything to help me out within the time I needed. I highly recommend this to people who are struggling.",
      nerdify: "Thank you, Darius!"
    },
    {
      name: "Auzin Mohammadzadeh",
      recommends: true,
      date: "February 6, 2021",
      review: "Wonderful service, always satisfied!"
    },
    {
      name: "Annie Nguyen",
      recommends: true,
      date: "January 28, 2021",
      review: "Great service! Extremely efficient with what they promise. The customer service was extremely helpful and considerate as well.",
      nerdify: "We're so happy you are satisfied, Annie!"
    },
    {
      name: "Kathy Gonzalez",
      recommends: true,
      date: "January 26, 2021",
      review: "I would highly recommend this service. They were polite and helped me understand my course. I loved how detailed they were. They were of great help and I will be using their services again.",
      nerdify: "Thanks, Kathy!"
    },
    {
      name: "Angie López",
      recommends: true,
      date: "January 21, 2021",
      review: "Excellent work! Amazing service!",
      nerdify: "Thank you, Angie!"
    },
    {
      name: "Julie Freiss",
      recommends: true,
      date: "December 27, 2020",
      review: "Nerdify is excellent! Great fast help at an affordable price! Would definitely recommend it!",
      nerdify: "Thanks, Julie!"
    },
    {
      name: "Marie Castor",
      recommends: true,
      date: "December 14, 2020",
      review: "Awesome company,  prompt and polite service. You will be very satisfied.",
      nerdify: "Thanks, Marie!"
    },
    {
      name: "Anthony Reid",
      recommends: true,
      date: "December 7, 2020",
      review: "This website is amazing, I wish that I had found it before I started my doctoral program.",
      nerdify: "Can't wait to help you further! "
    },
    {
      name: "Ali Hersi",
      recommends: true,
      date: "December 5, 2020",
      review: "Very good service and timely delivery!",
      nerdify: "Thank you!"
    },
    {
      name: "Crystal Truax",
      recommends: true,
      date: "December 3, 2020",
      review: "Great service! Very helpful and friendly!",
      nerdify: "Thanks, Crystal!"
    },
    {
      name: "Sandy Prieto",
      recommends: true,
      date: "December 1, 2020",
      review: "Great service, highly recommend it!",
      nerdify: "Thanks, Sandy!"
    },
    {
      name: "Samuel Arteaga",
      recommends: true,
      date: "November 27, 2020",
      review: "Their service is excellent and their work is always spot-on!",
      nerdify: "Thanks, Samuel!"
    },
    {
      name: "Kristina K",
      recommends: false,
      date: "November 8, 2020",
      review: "I don’t recommend their service to anyone! Don’t bother trying to negotiate with them!",
      nerdify: "Hey there. Kristina! Thanks for reaching out to us. To be honest, I am confused about your feedback. From what I see in our client management system, we have initiated a full refund to your card for one task and notified you about it. Yet, you've placed another task a day ago and seemed totally fine with the price. Would you please be a little more specific about what you didn't like about our service?"
    },
    {
      name: "Ashley Bruce",
      recommends: true,
      date: "November 20, 2020",
      review: "Good customer service. Very useful and helpful. Recommended it to friends.",
      nerdify: "Thanks, Ashley!"
    },
    {
      name: "Jason Theodore",
      recommends: true,
      date: "November 18, 2020",
      review: "Nerdify came to be very handy. I was really struggling to keep up with my deadlines and I wasn't sure how these guys would help me complete my work. But after they assigned a Nerd and I collaborated with him I was just surprised! They are doing a really good job!",
      nerdify: "Can't wait to help you again, Jason!"
    },
    {
      name: "Ismayel Camarena",
      recommends: true,
      date: "November 13, 2020",
      review: "I highly recommend Nerdify. The first time I contacted them I was a little skeptical, but now I think they are lifesavers!!! I’ll definitely be contacting them again.",
      nerdify: "Thank you so much for your feedback! Hope to see you back in the nearest future!"
    },
    {
      name: "Sarah Martinez",
      recommends: true,
      date: "November 10, 2020",
      review: "Highly recommended. Nerdify was able to answer and assist me with my last minute questions.",
      nerdify: "Thanks for leaving your feedback!"
    },
    {
      name: "Yoeliss Placencia",
      recommends: true,
      date: "November 6, 2020",
      review: "They are great to help you with any task that you have.",
      nerdify: "Thanks for your feedback! "
    },
    {
      name: "Jay Larue",
      recommends: true,
      date: "October 31, 2020",
      review: "They helped me with my tasks a lot. They are my champions and experts!",
      nerdify: "Thanks, Jay!"
    },
    {
      name: "Chris Rock",
      recommends: true,
      date: "October 29, 2020",
      review: "Nerdify was very reliable and convenient. Customer service was great, responding within a matter of minutes pairing me up with the right Nerds. This was my first time dealing with a service like this and as of now, it won’t be my last!",
      nerdify: "Thank you so much for your feedback, Chris!"
    },
    {
      name: "Chase Gorgan",
      recommends: true,
      date: "October 20, 2020",
      review: "Anyone who is hesitant to try them, don't be. Just be sure to let them know about a task you're struggling with right away. They always prepare material on time or even earlier.",
      nerdify: "Thank you for your kind feedback!"
    },
    {
      name: "Marie DL",
      recommends: true,
      date: "October 17, 2020",
      review: "Very helpful and very affordable. Would totally recommend it!",
      nerdify: "Happy you loved your experience!"
    },
    {
      name: "Thalia Melendez",
      recommends: true,
      date: "October 9, 2020",
      review: "Excellent job! The Nerds followed my instructions as indicated each time. Would highly recommend it!",
      nerdify: "Thanks, Thalia!"
    },
    {
      name: "Bettina Louise",
      recommends: true,
      date: "October 6, 2020",
      review: "The Nerdify crew has been nothing but amazing. I have been up against a wall many times and they have saved me. Thank you guys for always coming through and being so nice.",
      nerdify: "Thank you so much, Bettina!"
    },
    {
      name: "盛一鸣",
      recommends: true,
      date: "September 30, 2020",
      review: "They are very professional. Love everything about the service.",
      nerdify: "Thanks!"
    },
    {
      name: "R R",
      recommends: false,
      date: "September 28, 2020",
      review: "The worst experience: did not want to help me with my project.",
      nerdify: "Hey there, thank you for your feedback. Unfortunately due to the heavy workload of our Nerds, we were unable to find an available expert to help you out before due date. Hope you give us a second chance in the future! "
    },
    {
      name: "Ruby Ochoa",
      recommends: true,
      date: "September 23, 2020",
      review: "By far the best experience! Super helpful and convenient. They literally help you with any subject you may be struggling with! 10/10",
      nerdify: "Thank you so much, Ruby!"
    },
    {
      name: "Mike King",
      recommends: true,
      date: "September 20, 2020",
      review: "Great services! They get you the best tutor right on time.",
      nerdify: "Thanks, Mike!"
    },
    {
      name: "Joseph Mingo III",
      recommends: true,
      date: "August 30, 2020",
      review: "I love Nerdify! When I hire them I don’t have to worry at all!",
      nerdify: "Luv u 2! "
    },
    {
      name: "Bianca Skinrb",
      recommends: true,
      review: "Excellent service, I wasn't sure what to expect but I'm glad I found them.",
      nerdify: "",
      date: "August 6, 2020"
    },
    {
      name: "Kelpme Kelper",
      recommends: true,
      review: "I would recommend this website because it helps me a lot whenever I'm busy or have too many things to do! I wish they had discounts for regular or frequent costumers, but I believe they will make those in the future :)",
      nerdify: "",
      date: "July 8, 2020"
    },
    {
      name: "Herwar Khoshnow",
      recommends: true,
      review: "Really helpful tutors available anytime! Thank you!",
      nerdify: "",
      date: "June 15, 2020"
    },
    {
      name: "Kasey Neellik",
      recommends: true,
      review: "Wonderful communication! They were able to help me in less time than expected!",
      nerdify: "",
      date: "June 8, 2020"
    },
    {
      name: "Salman Mushtaque",
      recommends: false,
      review: "Just do the job the first time.",
      nerdify: "Dear Salman, we have carefully reviewed your case and I totally understand why you ended up not completely satisfied with your experience. The Nerd did a good job but still was close enough to not meeting our high standards. I am so sorry we failed your expectations. We decided to initiate a 50% refund for your task and hope you give us a second chance! Your money will appear on your bank account in 3-5 business days.",
      date: "May 1, 2020"
    },
    {
      name: "Ziyad Pida",
      recommends: true,
      review: "Best service ever!!! I was being crushed by my workload and Nerdify came to my rescue. They work professionally. Definitely would recommend to anyone needing any help at all.",
      nerdify: "",
      date: "May 1, 2020"
    },
    {
      name: "April Sin",
      recommends: true,
      review: "Best services ever! Nerdify has helped me a lot with the overwhelming works that I have had so far. Both the quality of service and timely manner are remarkable. Nice job! Keep up with the excellent work, guys 😘",
      nerdify: "",
      date: "April 29, 2020"
    },
    {
      name: "Dan Manfred Worden",
      recommends: true,
      review: "Nerdify was very prompt to respond and helped with some very difficult tasks. I recommend these guys 110%. Amazing helpers and great customer service!",
      nerdify: "",
      date: "April 11, 2020"
    },
    {
      name: "Jermain Clark",
      recommends: true,
      review: "This semester I can honestly say it was pretty hard. With Nerdify's assistance, I was able to study and put better efforts towards passing my classes. Thank you guys for all the help!",
      nerdify: "",
      date: "March 14, 2020"
    },
    {
      name: "Lavia Lelek",
      recommends: true,
      review: "I would definitely recommend Nerdify. Being the procrastinator that I am, I had all the information gathered but didn’t understand how to organize it. I contacted them via an Ad I saw on Instagram and they were happy to help. I had some trouble with the instructions followed, as they were not initially full, but they gladly helped me to understand it all with less than 12 hours from my request. It is perfect. Thank you so much again. You get what you pay for!",
      nerdify: "",
      date: "March 3, 2020"
    },
    {
      name: "Veronica V. Cotto",
      recommends: true,
      review: "Very helpful. Definitely has been my go-to so far this semester.",
      nerdify: "",
      date: "February 12, 2020"
    },
    {
      name: "Kimberly Takata",
      recommends: true,
      review: "Thank you Nerdify!! You all are so awesome!! Anybody needs help with tasks? Text Nerdify, they will guide you on anything you are struggling with!! They are the best!!",
      nerdify: "",
      date: "October 8, 2019"
    },
    {
      name: "Caecilia Willhelmina",
      recommends: true,
      review: "Thank you for helping me with my task!!! You guys are doing the great job! Thank you so much! You guys have the best Nerds ever! THANK YOUU!! 💕",
      nerdify: "",
      date: "August 14, 2019"
    },
    {
      name: "Erik Schroeder",
      recommends: true,
      review: "What an incredible service. Nerdify has gotten me out of two really tight spots. Their customer support is outstanding and they make working with them a breeze and an absolute pleasure. If I wasn't graduating this semester I'd use them again! Very highly recommended service!",
      nerdify: "",
      date: "May 4, 2019"
    },
    {
      name: "Afton McKnight",
      recommends: true,
      review: "I've been using their services throughout the years and they have always promptly provided me any needed materials and always met my expectations!!! Highly recommend. I once had to ask if I kept getting the same person because we were chatting like friends. Love them!!!",
      nerdify: "",
      date: "April 1, 2019"
    },
    {
      name: "Steven Anker",
      recommends: true,
      review: "Love Nerdify! Very helpful. They are so kind and communicate very well.",
      nerdify: "",
      date: "February 27, 2019"
    },
    {
      name: "Savanna Grey",
      recommends: true,
      review: "I made a last minute decision and contacted them on a late notice. They were able to help me till the very end and ease my stress. I appreciate everything they did. I would recommend them to anyone in need of educational help!",
      nerdify: "",
      date: "February 18, 2019"
    },
  ],
};

export default facebook;
