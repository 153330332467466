import React from 'react';

import classnames from "classnames";
import './rating.css';
import Holder from './../Holder';
import { useMobile } from '../../../../hooks';

export default (props) => {
  const isMobile = useMobile();
  return <div className={classnames("rating",props.className)}>
    <Holder>
      <div className="rating-title">Trusted by more than<br className="mobile-only"/> 100k students since 2015</div>

      <div className="rating-list">
        <div className="rating-item">
          {isMobile ?
            <img className="rating-img trustpilot"
                 src={require(`./img/trustpilot-mob@2x.png`)}
                 //srcSet={`${require(`./img/trustpilot-mob.png`)} 1x, ${require(`./img/trustpilot-mob@2x.png`)} 2x, ${require(`./img/trustpilot-mob@3x.png`)} 3x`}
                 alt=''
            />
            :
            <img className="rating-img trustpilot"
                 src={require(`./img/trustpilot@2x.png`)}
                 //srcSet={`${require(`./img/trustpilot.png`)} 1x, ${require(`./img/trustpilot@2x.png`)} 2x, ${require(`./img/trustpilot@3x.png`)} 3x`}
                 alt=''
            />
          }
        </div>
{/*
        <div className="rating-item">
          {isMobile ?
            <img className="rating-img google"
                 src={require(`./img/google-mob@2x.png`)}
                 //srcSet={`${require(`./img/google-mob.png`)} 1x, ${require(`./img/google-mob@2x.png`)} 2x, ${require(`./img/google-mob@3x.png`)} 3x`}
                 alt=''
            />
            :
            <img className="rating-img google"
                 src={require(`./img/google@2x.png`)}
                 //srcSet={`${require(`./img/google.png`)} 1x, ${require(`./img/google@2x.png`)} 2x, ${require(`./img/google@3x.png`)} 3x`}
                 alt=''
            />
          }
        </div>
*/}

        {!isMobile &&
          <div className="rating-item">
            <img className="rating-img fb"
                 src={require(`./img/fb@2x.png`)}
                 //srcSet={`${require(`./img/fb.png`)} 1x, ${require(`./img/fb@2x.png`)} 2x, ${require(`./img/fb@3x.png`)} 3x`}
                 alt=''
            />
        </div>
        }
          <div className="rating-item">
            {isMobile ?
              <img className="rating-img sitejabber"
                   src={require(`./img/sitejabber-mob@2x.png`)}
                   alt=''
              />
              :
              <img className="rating-img sitejabber"
                   src={require(`./img/sitejabber@2x.png`)}
                //srcSet={`${require(`./img/sitejabber.png`)} 1x, ${require(`./img/sitejabber@2x.png`)} 2x, ${require(`./img/sitejabber@3x.png`)} 3x`}
                   alt=''
              />
            }
          </div>

      </div>
    </Holder>
  </div>
}
