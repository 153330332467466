import React, { Component } from 'react';
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

import './index.css';

export class PartnersItem extends Component {
  render () {
    const {index} = this.props;

    return (
      <LazyLoad>
        <div
          className={`partners__list-item partners__list-item_${index}`}
          key={`partners__list-item-${index}`} />
      </LazyLoad>
    )
  }
}

export class PartnersList extends Component {
  render () {
    const {children} = this.props;

    return (
      <div className='partners__list'>
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            index,
          });
        })}
      </div>
    )
  }
}

export default class Partners extends Component {
  render () {
    const {className, children} = this.props;

    return (
      <div className={classnames('partners', className)}>
        <div className='holder'>
          {children}

        </div>
      </div>
    )
  }
}
