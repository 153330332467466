import React from 'react';
//import Text from "../../../../base_components/Text";

export const SliderCombinedItems = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Explaining complex
          <br /> topics
        </>
      ),
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: (
        <>
          Checking &<br /> pointing out errors
        </>
      ),
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: (
        <>
          Advising on
          <br /> presentation framework
        </>
      ),
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with
          <br /> data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_students_5 = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Explaining discussion
          <br /> topics
        </>
      ),
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: (
        <>
          Advise <br /> on a bibliography
        </>
      ),
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: (
        <>
          Help with a <br /> presentation
        </>
      ),
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with <br /> data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_students_6 = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: (
        <h4>
          Explaining discussion
          <br /> topics
        </h4>
      ),
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: (
        <h4>
          Advise <br /> on a bibliography
        </h4>
      ),
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: (
        <h4>
          Help with a <br /> presentation
        </h4>
      ),
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with <br /> data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_math_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: <>Math tutoring</>,
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Checking Calculus</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Explaining Algebra topics</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with
          <br /> data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_statistics_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: <>Statistics tutoring</>,
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Checking Stats calculations</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Explaining Stats topics</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with <br /> data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

// /natural-sciences-help
export const sliderCombinedItems_natural_sciences_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Explaining complex
          <br /> topics
        </>
      ),
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Checking calculations</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Advising on formatting lab reports</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with
          <br />data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

// /tr-natural-sciences-help
export const sliderCombinedItems_tr_natural_sciences_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Explaining complex
          <br /> topics
        </>
      ),
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Checking calculations</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Formatting lab reports</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with
          <br />data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_business_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: <>Explaining Business topics</>,
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Cover letter writing tips</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Advising on Marketing presentations</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with
          <br />data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_social_sciences_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: <>Explaining Sociology topics</>,
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Cover letter writing tips</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Advising on Law presentations</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with
          <br />data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_finance_accounting_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Explaining complex
          <br /> topics
        </>
      ),
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Checking calculations</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: (
        <>
          Advising on<br /> presentations
        </>
      ),
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with
          <br />data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_humanities_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: <>Explaining Philosophy topics</>,
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>Cover letter writing tips</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Advising on Nursing presentations</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with <br /> data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};

export const sliderCombinedItems_english_literature_help = {
  Academia: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Gathering raw data
          <br /> for your research
        </>
      ),
      img: 'academia-img1',
    },
    {
      tag: 'Request from $15',
      title: (
        <>
          Helping create
          <br /> an unbiased survey
        </>
      ),
      img: 'academia-img2',
    },
    {
      tag: 'Request from $30',
      title: (
        <>
          Making a summary
          <br /> of survey results
        </>
      ),
      img: 'academia-img3',
    },
  ],
  Students: [
    {
      tag: 'Request from $25',
      title: <>Explaining Literature topics</>,
      img: 'students-img1',
    },
    {
      tag: 'Request from $20',
      title: <>English grammar checking</>,
      img: 'students-img2',
    },
    {
      tag: 'Request from $20',
      title: <>Creative writing tips</>,
      img: 'students-img3',
    },
  ],
  Businesses: [
    {
      tag: 'Request from $25',
      title: (
        <>
          Help with <br /> data analysis
        </>
      ),
      img: 'business-img1',
    },
    {
      tag: 'Request from $50',
      title: (
        <>
          Conducting market
          <br /> research
        </>
      ),
      img: 'business-img2',
    },
    {
      tag: 'Request from $40',
      title: (
        <>
          Making an online
          <br /> reputation audit
        </>
      ),
      img: 'business-img3',
    },
  ],
};
