import React from 'react';
import { Link } from 'react-router-dom';
//import classnames from 'classnames';
import Holder from "../Holder";
import './index.css';

const MathonLpHeader = ({}) => {
  return (
    <div className="mathonlp-header">
      <Holder type={1}>
        <Link to={'/mathon'}>
          <img src={require('../img/logo_math.svg')} width="166" height="48" alt="" />
        </Link>
      </Holder>
    </div>  );
};

export default MathonLpHeader;
