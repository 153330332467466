import React from 'react';
import Slider from "react-slick";
import LazyLoad from 'react-lazyload';
import Text from "../../../../base_components/Text";
//import Icons from './../../../../base_components/Icons';
//import { NavLink, Link } from 'react-router-dom';
//import Icon from './../Icons';
//import { isMobile } from "../../../../utils";
//import classnames from "classnames";

import {
  useMobile
} from '../../../../hooks';

import './slickSlider2.css';

export default (props) => {
  const isMobile = useMobile();

  const renderItems = () => {
    return [
      <div className="slick-item" key="1">
        <div className="item-tag">Get for $10</div>
        <div className="item-title">5 credible sources to help<br /> you nail your task</div>
        <LazyLoad height={200}><div className="item-img popular-task1" /></LazyLoad>
      </div>,
      <div className="slick-item" key="2">
      <div className="item-tag">Get for $10</div>
        <div className="item-title">Impeccable formatting of your<br />
          text in any format</div>
        <LazyLoad height={200}><div className="item-img popular-task2" /></LazyLoad>
      </div>,
      <div className="slick-item" key="3">
      <div className="item-tag">Get for $20</div>
        <div className="item-title">Proofreading & formatting<br />
          of your document</div>
        <LazyLoad height={200}><div className="item-img popular-task3" /></LazyLoad>
      </div>
    ]
  }

  return <div className="slick-slider2">
    <div className="holder">
      <h3 className="h3 slick-title">{props.title || <Text>Start with most popular tasks from just $10</Text>}</h3>
      <div className="slick-wrapper">
        {isMobile ? (
          <Slider variableWidth
            useTransform={false}
            centerMode
            infinite={false}
            slidesToShow={1}
            dots={true}
            arrows={false}
          >
            {renderItems()}
          </Slider>
        ) : (
            <div className="slick-static">
              {renderItems()}
            </div>
          )}
      </div>
    </div>
  </div>
}


