import React from 'react';
import LazyLoad from 'react-lazyload';
//import savetimeIcons from './img/icons.svg';
//import Icons from './../../../../base_components/Icons';
import Holder from './../Holder';

import './payList.css';
import classnames from "classnames";

export default (props) => {
  return <div className={classnames("pay-list-block",props.className)}>
    <Holder>
      <ul className="pay-list top">
        <li className="pay-list-item">
          <img
            loading="lazy"
            className="pay-list-img applepay-img"
            src={require(`./img/applepay.png`)}
            srcSet={`${require(`./img/applepay@2x.png`)} 2x, ${require(`./img/applepay.png`)} 1x`}
            width="70"
            height="45"
            alt=""
          />
        </li>
        <li className="pay-list-item">
          <img
            loading="lazy"
            className="pay-list-img google-pay-img"
            src={require(`./img/google-pay.png`)}
            srcSet={`${require(`./img/google-pay@2x.png`)} 2x, ${require(`./img/google-pay.png`)} 1x`}
            width="70"
            height="45"
            alt=""
          />
        </li>
      </ul>
      <ul className="pay-list bottom">
        <li className="pay-list-item">
          <img
            loading="lazy"
            className="pay-list-img visa-img"
            src={require(`./img/visa.png`)}
            srcSet={`${require(`./img/visa@2x.png`)} 2x, ${require(`./img/visa.png`)} 1x`}
            width="39"
            height="25"
            alt=""
          />
        </li>
        <li className="pay-list-item">
          <img
            loading="lazy"
            className="pay-list-img mastercard-img"
            src={require(`./img/mastercard.png`)}
            srcSet={`${require(`./img/mastercard@2x.png`)} 2x, ${require(`./img/mastercard.png`)} 1x`}
            width="39"
            height="25"
            alt=""
          />
        </li>
        <li className="pay-list-item">
          <img
            loading="lazy"
            className="pay-list-img ae-img"
            src={require(`./img/ae.png`)}
            srcSet={`${require(`./img/ae@2x.png`)} 2x, ${require(`./img/ae.png`)} 1x`}
            width="39"
            height="25"
            alt=""
          />
        </li>
        <li className="pay-list-item">
          <img
            loading="lazy"
            className="pay-list-img discover-img"
            src={require(`./img/discover.png`)}
            srcSet={`${require(`./img/discover@2x.png`)} 2x, ${require(`./img/discover.png`)} 1x`}
            width="39"
            height="25"
            alt=""
          />
        </li>
{/*
        <li className="pay-list-item">
          <LazyLoad height={25}>
            <img className="pay-list-img pp-img" src={require(`./img/pp.png`)} srcSet={`${require(`./img/pp@2x.png`)} 2x, ${require(`./img/pp.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
*/}
      </ul>
    </Holder>
  </div>
}

export const PayListClp3 = (props) => <div className={classnames("paylist_clp3",props.className)}>
    <Holder>
      <ul className="pay-list">
        <li className="pay-list-item">
          <LazyLoad height={30}>
            <img className="pay-list-img applepay-img" src={require(`./img/applepay.png`)} srcSet={`${require(`./img/applepay@2x.png`)} 2x, ${require(`./img/applepay.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
        <li className="pay-list-item">
          <LazyLoad height={30}>
            <img className="pay-list-img google-pay-img" src={require(`./img/google-pay.png`)} srcSet={`${require(`./img/google-pay@2x.png`)} 2x, ${require(`./img/google-pay.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
        <li className="pay-list-item">
          <LazyLoad height={30}>
            <img className="pay-list-img visa-img" src={require(`./img/visa.png`)} srcSet={`${require(`./img/visa@2x.png`)} 2x, ${require(`./img/visa.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
        <li className="pay-list-item">
          <LazyLoad height={30}>
            <img className="pay-list-img mastercard-img" src={require(`./img/mastercard.png`)} srcSet={`${require(`./img/mastercard@2x.png`)} 2x, ${require(`./img/mastercard.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
        <li className="pay-list-item">
          <LazyLoad height={30}>
            <img className="pay-list-img ae-img" src={require(`./img/ae.png`)} srcSet={`${require(`./img/ae@2x.png`)} 2x, ${require(`./img/ae.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
        <li className="pay-list-item">
          <LazyLoad height={30}>
            <img className="pay-list-img discover-img" src={require(`./img/discover.png`)} srcSet={`${require(`./img/discover@2x.png`)} 2x, ${require(`./img/discover.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
{/*
        <li className="pay-list-item">
          <LazyLoad height={30}>
            <img className="pay-list-img pp-img" src={require(`./img/pp.png`)} srcSet={`${require(`./img/pp@2x.png`)} 2x, ${require(`./img/pp.png`)} 1x`} alt={''} />
          </LazyLoad>
        </li>
*/}
      </ul>
    </Holder>
  </div>

