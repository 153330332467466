import React, { Component } from 'react';
import classnames from 'classnames';

export const WithClassNameHOC = (WrappedComponent, newClassName) => {
  class WithClassNameHOC extends Component {
    render() {
      const { className, ...rest } = this.props;
      return <WrappedComponent
        {...rest}
        className={classnames(className, newClassName)}
      />
    }
  }

  WithClassNameHOC.displayName = `WithClassNameHOC(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithClassNameHOC;
}
