import React from 'react';
import trustpilot from './trustpilot';
import sitejabber from './sitejabber';
import facebook from './facebook';
import google from './google';
import ReviewBlock from '../../../../themes/nerdify/components/ReviewBlock';

const items = { ...trustpilot, ...sitejabber, ...facebook, ...google };

const Reviews = () => {
  return <ReviewBlock items={items} />;
};

export default Reviews;
