import React, { Component } from 'react';
import Holder from './../../../../themes/nerdify/components/Holder'
import Hero1 from './Hero1'
import Hero2 from './Hero2'
import Hero3 from './Hero3'
//import Hero4 from './Hero4'
import Hero5 from './Hero5'
import Hero6 from './Hero6'
//import Hero8 from './Hero8'
import Hero9 from './Hero9'
//import HeroStorytelling from './Hero-Storytelling'

import './index.css';
import classnames from "classnames";

class Hero extends Component {
  render() {
    const { className, children } = this.props;
    return (
      <div className={classnames('hero', className)}>
        <Holder className="hero__holder">
          {children}
        </Holder>
      </div>
    )
  }
}

const Hero7 = ({ children }) => <div className="hero hero_7">
  {children}
</div>;

const Hero10 = ({ children }) => <div className="hero hero_10">
  {children}
</div>;

const Hero11 = ({ children, subTheme }) => <div
  className={classnames('hero hero_11', {[`hero_11_${subTheme}`]: subTheme})}>
  { children }
</div>;

const Hero12 = ({ children, subTheme }) => <div
  className={classnames('hero hero_12', {[`hero_12_${subTheme}`]: subTheme})}>
  { children }
</div>;

const Hero13 = ({ children, subTheme }) => <div
  className={classnames('hero hero_13', {[`hero_13_${subTheme}`]: subTheme})}>
  { children }
</div>;

const Hero14 = ({ children, subTheme }) => <div
  className={classnames('hero hero_14', {[`hero_14_${subTheme}`]: subTheme})}>
  { children }
</div>;

const Hero15 = ({ children, subTheme }) => <div
  className={classnames('hero hero_15', {[`hero_15_${subTheme}`]: subTheme})}>
  { children }
</div>;

const Hero16 = ({ children, subTheme }) => <div
  className={classnames('hero hero_16', {[`hero_16_${subTheme}`]: subTheme})}>
  { children }
</div>;

const HeroClp3 = ({ children, subTheme }) => <div
  className={classnames('hero heroClp3', {[`heroClp3_${subTheme}`]: subTheme})}>
  { children }
</div>;

const HeroClp4 = ({ children, subTheme }) => <div
  className={classnames('hero heroClp4', {[`heroClp4_${subTheme}`]: subTheme})}>
  { children }
</div>;

const HeroBusiness = ({ children, subTheme }) => <div
  className={classnames('hero heroBusiness', {[`heroBusiness_${subTheme}`]: subTheme})}>
  { children }
</div>;

const HeroAcademia = ({ children, subTheme }) => <div
  className={classnames('hero heroAcademia', {[`heroAcademia_${subTheme}`]: subTheme})}>
  { children }
</div>;

const HeroNew = ({ children, subTheme }) => <div
  className={classnames('hero heroNew', {[`heroNew_${subTheme}`]: subTheme})}>
  { children }
</div>;

const HeroNew2 = ({ children, subTheme }) => <div
  className={classnames('hero heroNew2', {[`heroNew2_${subTheme}`]: subTheme})}>
  { children }
</div>;


const HeroIcon = ({ className, img, alt }) =>
  <img
    className={className}
    src={require(`./img/${img}`)}
    alt={alt ? alt : ''}
  />;

const HeroImg = ({ className, img, img2x, img3x, alt }) => {
  const scrSet = (img2x ? `${require(`./img/${img2x}`)} 2x` : '') + (img3x ? `, ${require(`./img/${img3x}`)} 3x` : '');
  return(
    <img
      className={className}
      src={require(`./img/${img}`)}
      srcSet={scrSet === '' ? false : scrSet}
      alt={alt ? alt : false}
    />
  )
};

export {
  Hero,
  Hero1,
  Hero2,
  Hero3,
  //Hero4,
  Hero5,
  Hero6,
  Hero7,
  //Hero8,
  Hero9,
  //HeroStorytelling,
  Hero10,
  //Hero11,
  Hero12,
  Hero13,
  Hero14,
  Hero15,
  Hero16,
  HeroIcon,
  HeroImg,
  HeroClp3,
  HeroClp4,
  HeroBusiness,
  HeroAcademia,
  HeroNew,
  HeroNew2,
}
