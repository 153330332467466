import React, { Component } from 'react'
import classnames from 'classnames';
import LazyLoad from 'react-lazyload';

import './index.css'

export class HowDots extends Component {
  render() {
    const { keys, activeIndex } = this.props;

    return (
      <div className="how__dots">
        {keys.map(item => {
          return (
            <div className={classnames('how__dots-item', {
              'how__dots-item_active': item === activeIndex
            })} key={`how__dots-item_${item}`} />
          )
        })}
      </div>
    )
  }
}

export class HowList extends Component {
  constructor(props) {
    super(props);

    this.onHandleClick = this.onHandleClick.bind(this);

    const activeItem = (props.pageCfg && props.pageCfg.activeItem) || props.activeItem || 'Students';

    this.state = {
      activeIndex: activeItem,
    };
  }

  onHandleClick(e) {
    this.setState({ activeIndex: e.target.dataset.id });
  }

  render() {
    const { activeIndex } = this.state;
    const { items, children } = this.props;
    const keys = Object.keys(items);
    const isBtn = keys.length > 1;
    const activeItems = items[activeIndex];

    return <div className={classnames('how__list-wrapper', {
      'how__list-wrapper_is-no-btn': !isBtn
    })}>
      {isBtn && (
        <div className="how__switch-btn" key="btn">

          {keys.map((item) => {
            return (
              <div
                className={classnames('how__switch-btn-item', {
                  'how__switch-btn-item_active': activeIndex === item
                })}
                data-id={item}
                key={item}
                onClick={(e) => this.onHandleClick(e)}
              >
                {item}
              </div>
            )
          })}
        </div>
      )}

      <ul className="how__list" key="list">
        {activeItems.map((item, index) => {
          const idxIcon = index + 1;

          return (
            <li key={`how__list-item-${idxIcon}`}>
              <div className='svg-wrapper'>
                <LazyLoad height={74}>
                  {item.img}
                </LazyLoad>
              </div>
              <div className="how__list-item-text">{item.text}</div>
            </li>
          )
        })}
      </ul>

      {React.Children.map(children, (child) => {
        switch (child.type) {
          case HowDots:
            return React.cloneElement(child, {
              activeIndex,
              keys,
            });
          default:
            return React.cloneElement(child);
        }
      })}
    </div>
  }
}

export default class How extends Component {
  render() {
    const { className, children } = this.props;

    return (
      <div className={classnames('how', className)}>
        <div className='holder'>
          {children}
        </div>
      </div>
    )
  }
}
