export default [
  {
    title: 'Market research',
    msg: 'I need a market research of metallurgy industry in Mexico.'
  },
  {
    title: 'Competitor analysis',
    msg: 'Could you please conduct a competitor analysis for my online shop? I need a full info on assortment, shipping, price, etc.'
  },
  {
    title: 'Tutoring',
    msg: 'Please help me understand standard normal distribution! I need to find the probability using Z-score.'
  },
  {
    title: 'Help with slides',
    msg: "I can't decide how to format the presentation for my Business class. Can you help me with the framework?"
  },
  {
    title: 'Expert feedback',
    msg: 'I want your best Nerd to check my research materials and provide feedback. This task is very important for my class!'
  },
  {
    title: 'Formatting advice',
    msg: "I'm almost done writing my research and need someone to check if I formatted it properly."
  }
];
