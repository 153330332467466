import React, {} from 'react';
import Slider from 'react-slick';
import { get } from 'lodash';

import classnames from 'classnames';
import Text from "../../../../base_components/Text";
import { useMobile } from '../../../../hooks';

import './sliderCombined.css';

const SliderCombinedDesktop = (props) => {
  const { keysTag, items } = props;
  const KeysTag = keysTag ? keysTag : 'h3';
  const keys = Object.keys(items);
  const activeItem = get(props, 'pageCfg.activeItem', undefined) || props.activeItem || 'Students';
  const activeIndex = keys.findIndex(item => item === activeItem);
  const itemsKeys = Object.keys(items);

  return (
    <div className="how-slider__list-wrapper">
      <div className="how-slider__list" key="list">
        <Slider
          useTransform={false}
          infinite={false}
          slidesToShow={1}
          dots
          arrows={false}
          initialSlide={activeIndex}
          dotsClass={'how-slider__switch-btn'}
          customPaging={(i) => {
            return (
              <KeysTag key={itemsKeys[i]}>
                {itemsKeys[i]}
              </KeysTag>
            )
          }}
        >
          {itemsKeys.map((i) => {
            return (
              <div
                className="how-slider__list-item-holder"
                key={`how-slider__list-item-holder-${i}`}>
                {items[i].map((item, index) => {
                  return (
                    <div className={classnames("slick-item", `slick-item_${i.toLowerCase()}`)} key={`slider-combined-item${index}`}>
                      <div className="item-tag">{item.tag}</div>
                      <div className="item-title">{item.title}</div>
                      <img
                        loading="lazy"
                        className={`item-img ${item.img}`}
                        src={require(`./img/${item.img}.svg`)}
                        width="auto"
                        height="auto"
                        alt=""/>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  )
};


const SliderCombinedMobile = (props) => {
  const { keysTag, items } = props;
  const KeysTag = keysTag ? keysTag : 'h3';
  const keys = Object.keys(items);
  const activeItem = get(props, 'pageCfg.activeItem', undefined) || props.activeItem || 'Students';
  const activeIndex = keys.findIndex(item => item === activeItem);
  const itemsKeys = Object.keys(items);

  return (
    <div className="how-slider__list-wrapper">
      <div className="how-slider__list" key="list">
        <Slider
          useTransform={false}
          infinite={false}
          slidesToShow={1}
          swipe={false}
          dots
          arrows={false}
          initialSlide={activeIndex}
          dotsClass={'how-slider__switch-btn'}
          customPaging={(i) => {
            return (
              <KeysTag key={itemsKeys[i]}>
                {itemsKeys[i]}
              </KeysTag>
            )
          }}
        >
            {itemsKeys.map((i) => {
              return (
                <div className="how-slider__list-item-holder" key={`how-slider__list-item-holder-${i}`}>
                  <Slider variableWidth
                          useTransform={false}
                          centerMode
                          infinite={false}
                          slidesToShow={1}
                          dots={false}
                          arrows={false}
                  >
                      {items[i].map((item, index) => {
                        return (
                          <div className={classnames("slick-item", `slick-item_${i.toLowerCase()}`)} key={`slider-combined-mobile-item${index}`}>
                            <div className="item-tag">{item.tag}</div>
                            <div className="item-title">{item.title}</div>
                            <img
                              loading="lazy"
                              className={`item-img ${item.img}`}
                              src={require(`./img/${item.img}.svg`)}
                              width="auto"
                              height="auto"
                              alt=""
                            />
                          </div>
                        )
                      })}
                  </Slider>
                </div>
              )
            })}
        </Slider>
      </div>

    </div>
  )
};


const SliderCombined = (props) => {
  const isMobile = useMobile();
  const { titleTag, title, theme, indent } = props;
  const TitleTag = titleTag ? titleTag : 'div';
  return (
    <div className={classnames('slider-combined', {[`slider-combined_${theme}`]: theme}, indent)}>
      <div className='holder'>
        <TitleTag className="h3 slick-title">{title || <Text>Start with most popular tasks from just $10</Text>}</TitleTag>
        <div className="slick-wrapper">
          {isMobile ? (
            <>
              {SliderCombinedMobile(props)}
            </>
          ) : (
            <div className="slick-static">
              {SliderCombinedDesktop(props)}
            </div>
          )}
        </div>
      </div>
    </div>
  )
};

export default SliderCombined;
