import React, { Component } from 'react';
import classnames from 'classnames';
import { NavLink } from 'react-router-dom';

import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';
import Holder from './../../../../themes/nerdify/components/Holder';
import { Section2 } from '../../../../themes/nerdify/components/Section';
import Attach from './../../../../themes/nerdify/components/Attach';
import InputError from './../../../../themes/nerdify/components/Form/InputError';
import { ModalReportAbuse } from './../../../../themes/nerdify/components/Modal';
import { Intro1 } from '../../../../themes/nerdify/components/Intro';
import { Form1 } from '../../../../themes/nerdify/components/Form';

import regexps from './../../../../utils/regexps';

import './report-abuse.css';

const reportAbuseItems = [
  {
    title: 'Academic cheating and dishonesty',
    description: 'We do not support requesting or providing pre-written or custom written academic assignments. Or assuming a student’s identity for any purpose including test or exam taking.',
  },
  {
    title: 'Plagiarism',
    description: 'We prohibit using the work of another person without proper attribution.',
  },
  {
    title: 'Nudity',
    description: 'We don’t allow the sharing or publishing of content depicting nudity or other sexually explicit material by Freelance Nerds.',
  },
  {
    title: 'Illegal or unethical activities',
    description: 'Freelance Nerds may not engage in activities that violate the law or corporate policies. We have zero tolerance policy for facilitating drug use, weapons and explosives sale or resale, bidding or gambling.',
  },
  {
    title: 'Hatred or violence',
    description: 'Freelance Nerds may not share public content that promotes hatred or violence, threatens, harasses or bullies other people.',
  },
  {
    title: 'Private and confidential information',
    description: 'We do not allow Freelance Nerds to ask for your confidential information. Examples include: passwords, credit card details, bank account numbers or similar types of private information.',
  },
  {
    title: 'Other',
    description: 'If you want to report something that doesn\'t fall under the aforementioned policies, please select this option.',
  },
];

const ReportAbuseItem = ({ title, description, index, onChange, activeItem, ...rest }) => {
  return (
    <div className="reportAbuse-radio">
      <input
        id={`reportAbuse-${index}`}
        className="reprotAbuse-radio"
        type="radio"
        name="radio"
        value={title}
        onChange={onChange}
        checked={reportAbuseItems[index]['title'] === activeItem}
      />
      <label
        className="reportAbuse-label"
        htmlFor={`reportAbuse-${index}`}
      >
        { title }
        <p className="p p__small p__darker">{ description }</p>
      </label>
    </div>
  )
};

class ReportAbuse extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      radio: '',
      email: '',
      orderNumber: '',
      comment: '',
      errors: {},
      isHasClick: false,
      isDisabled: false,
      isSuccess: false,
      isClear: false,
    }

    this.state = this.initialState;

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(e) {
    this.setState({ isHasClick: true });

    const isError = this.validate();
    if (!isError) {
      this.setState({ isSuccess: true });
    }
  }

  onClose() {
    this.setState({
      isClear: true,
    }, () => {
      this.setState({...this.initialState});
    });
  }

  onChangeInput(e) {
    const { name, value } = e.target;
    const { isHasClick } = this.state;

    this.setState({
      [name]: value
    }, () => {
      if (isHasClick) {
        this.validate();
      }
    });
  }

  validate() {
    const {
      email,
      orderNumber,
      comment,
      radio,
    } = this.state;

    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
      orderNumber: !orderNumber.trim().length ? 'Required field' : null,
      comment: !comment.trim().length ? 'Required field' : null,
      radio: !radio.trim().length ? 'Select the abuse type' : null,
    };

    const isError = Object.keys(errors).some(item => errors[item]);

    this.setState({
      errors,
      isDisabled: isError,
    });

    return isError;
  }

  render() {
    const {
      radio,
      email,
      orderNumber,
      comment,
      errors,
      isDisabled,
      isSuccess,
      isClear,
    } = this.state;

    return (
      <LocalesPage {...this.props}>
        <TextPage className="reportAbuse" noWrap>
        <Intro1>
          <Holder className="holder_1">
            <h1 className="h1">Report Abuse</h1>
            <p className="h6">At Nerdify, we believe in freelance Nerds providing high-quality help while delivering excellent services. We also believe that help they provide should be ethical. If you believe that the Nerd assigned to your task agreed to provide unethical help, please, report it for abuse.</p>
          </Holder>
        </Intro1>
        <Holder className="holder_1 mobile-only">
          <hr className="hr" />
        </Holder>
        <Section2>
          <Holder className="holder_1">
            <h2 className="h2">Select the abuse type you want to report</h2>
            <Form1 className="form">
              <fieldset className="fieldset">
                {reportAbuseItems.map((item, index) => {
                  return (
                    <ReportAbuseItem
                      key={`ReportAbuseItem-${index}`}
                      index={index}
                      activeItem={radio}
                      {...item}
                      onChange={this.onChangeInput}
                    />
                  );
                })}
                {!!errors.radio &&
                <InputError>{ errors.radio }</InputError>
                }
              </fieldset>
              <fieldset className="fieldset">
                <div className="fieldset-item">
                  <label className="label">Enter your email</label>
                  <div className="input-wrapper">
                    <input
                      className={classnames("input", {"input__error": errors.email })}
                      type="email"
                      name="email"
                      value={email}
                      placeholder=""
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="email"
                      onChange={this.onChangeInput}
                    />
                    {!!errors.email &&
                    <InputError>{ errors.email }</InputError>
                    }
                  </div>
                </div>
                <div className="fieldset-item">
                  <label className="label">Enter your request</label>
                  <div className="input-wrapper">
                    <input
                      className={classnames("input", {"input__error": errors.orderNumber })}
                      type="text"
                      name="orderNumber"
                      value={orderNumber}
                      placeholder=""
                      autoCapitalize="off"
                      autoCorrect="off"
                      autoComplete="off"
                      onChange={this.onChangeInput}
                    />
                    {!!errors.orderNumber &&
                    <InputError>{ errors.orderNumber }</InputError>
                    }
                  </div>
                </div>
              </fieldset>
              <fieldset className="fieldset">
                <label className="label">Comment</label>
                <div className="input-wrapper">
                  <textarea
                    className={classnames("textarea", {"textarea__error": errors.comment })}
                    name="comment"
                    value={comment}
                    placeholder="Let us know some additional info"
                    onChange={this.onChangeInput}
                  />
                  {!!errors.comment &&
                  <InputError>{ errors.comment }</InputError>
                  }
                </div>
              </fieldset>
              <fieldset className="fieldset">
                <Attach id="report-abuse-file" clear={isClear}>Attach file</Attach>
              </fieldset>
              <fieldset className="fieldset">
                <p className="p p__tiny">Please note that we won't let the Nerd know who reported them. Moreover, we will immediately stop working with the Nerd, if they do not not comply with the above-mentioned policies, <NavLink className="a__underline" to="/terms-and-conditions">Terms and Conditions</NavLink> or <NavLink className="a__underline" to="/privacy-policy">Privacy Policy</NavLink> of our service.</p>
              </fieldset>
              <div className="fieldset">
                <button type="button" className="button__type6 form__btn" onClick={this.onSubmit} disabled={isDisabled}>Send report</button>
              </div>
            </Form1>
          </Holder>
        </Section2>

        {isSuccess &&
        <ModalReportAbuse isOpen={isSuccess} onClose={this.onClose} />
        }

      </TextPage>
      </LocalesPage>
    )
  }
}

export default ReportAbuse;
