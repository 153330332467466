import React, { useState, Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import {
  TextBackTheme1,
  TextBackTheme2,
  TextBackTheme3,
  TextBackTheme4,
  TextBackForm,
} from '../../../../themes/nerdify/components/TextBack';

import { InputSelectLocales } from '../../../../themes/nerdify/components/Form/InputSelect';
import { Facebook2 } from '../../blocks/Facebook';

const AgreeItems = () => {
  return (
    <div className="textback__agree-items">
      <div className="textback__agree-item">Instant reply</div>
      <div className="textback__agree-item">100% Confidential </div>
      <div className="textback__agree-item">No Spam</div>
    </div>
  );
};

const AgreeLinks1 = () => {
  return (
    <p className="p__tiny textback__agree">
      By clicking the button you agree to our <br className="mobile-only" />{' '}
      <NavLink className="link a__underline" to="/terms-and-conditions">
        Terms
      </NavLink>{' '}
      and{' '}
      <NavLink className="link a__underline" to="/privacy-policy">
        Privacy Policy
      </NavLink>
    </p>
  );
};

const AgreeLinks2 = () => {
  return (
    <p className="p__tiny textback__agree">
      By clicking these buttons you agree to our <br className="mobile-only" />{' '}
      <NavLink className="link a__underline" to="/terms-and-conditions">
        Terms
      </NavLink>{' '}
      and{' '}
      <NavLink className="link a__underline" to="/privacy-policy">
        Privacy Policy
      </NavLink>
    </p>
  );
};

const AgreeLinks3 = () => {
  return (
    <p className="p__tiny textback__agree">
      By leaving your phone number you agree to our{' '}
      <br className="mobile-only" />{' '}
      <NavLink className="link a__underline" to="/terms-and-conditions">
        Terms
      </NavLink>{' '}
      and{' '}
      <NavLink className="link a__underline" to="/privacy-policy">
        Privacy Policy
      </NavLink>
    </p>
  );
};

const TextBackContent = (props) => (
  <Fragment>
    <p className="p textback__label">
      Just type your number and we'll text you back
    </p>
    <TextBackForm {...props} />
    <p className="p__tiny textback__agree">
      By clicking the button you agree to our <br />{' '}
      <NavLink className="link a__underline" to="/terms-and-conditions">
        Terms of Use
      </NavLink>
      ,{' '}
      <NavLink className="link a__underline" to="/privacy-policy">
        Privacy
      </NavLink>{' '}
      and{' '}
      <NavLink className="link a__underline" to="/cookies">
        Cookie Policies
      </NavLink>
      .
    </p>
  </Fragment>
);

export const TextBackWithLocales = (props) => {
  return (
    <TextBackForm
      {...props}
      renderSelectLocales={() => (
        <InputSelectLocales className="input-select__short" name="country" />
      )}
    />
  );
};

const TextBackContent2 = (props) => (
  <Fragment>
    <p className="p textback__label">We'll text you back in 10 sec</p>
    <div className="promoBanner2-wrapper">
      {TextBackWithLocales(props)}
      {props.promoBanner2 &&
        <img
          loading="lazy"
          className="promoBanner2-img"
          src={require('./img/Discount_badge.svg')}
          width="122"
          height="124"
          alt=""
        />
      }
    </div>
    {AgreeItems()}
    {AgreeLinks1()}
  </Fragment>
);

const TextBackContent3 = (props) => (
  <Fragment>
    {TextBackWithLocales(props)}
    <Facebook2 subTheme={1} />
    {AgreeItems()}
    {AgreeLinks2()}
  </Fragment>
);

const TextBackContent4 = (props) => (
  <Fragment>
    <p className="textback__label">We'll text you back in 10 sec</p>
    <TextBackForm {...props} />
    {AgreeItems()}
    {AgreeLinks1()}
  </Fragment>
);

const TextBackContent5 = (props) => (
  <Fragment>
    <p className="textback__label">
      Simply type in your phone number to get started
    </p>
    {TextBackWithLocales(props)}
    {AgreeItems()}
    {AgreeLinks3()}
  </Fragment>
);

export const TextBack1 = (props) => (
  <TextBackTheme1 {...props}>
    <TextBackContent {...props} />
  </TextBackTheme1>
);

export const TextBack2 = (props) => (
  <TextBackTheme2 {...props}>
    <TextBackContent {...props} />
  </TextBackTheme2>
);

export const TextBack3 = (props) => (
  <TextBackTheme3 {...props}>
    <TextBackContent2 {...props} />
  </TextBackTheme3>
);

export const TextBack3a = (props) => {
  const [isBox1Checked, setIsBox1Checked] = useState(false);
  const [isBox1Error, setIsBox1Error] = useState(false);
  const [isBox2Checked, setIsBox2Checked] = useState(false);
  const [isBox2Error, setIsBox2Error] = useState(false);
  const [isBox3Checked, setIsBox3Checked] = useState(false);
  const [isBox3Error, setIsBox3Error] = useState(false);
  //const [isAuthorizationChecked, setIsAuthorizationChecked] = useState(false);
  //const [isAcceptAllChecked, setAcceptAllChecked] = useState(false);

  /*
  React.useEffect(() => {
    if (isAcceptAllChecked) {
      setIsTermsChecked(true);
      setIsCheckError(false);
      setIsUpdatesChecked(true);
      setIsAuthorizationChecked(true);
    }
  }, [isAcceptAllChecked]);

  React.useEffect(() => {
    if (!(isTermsChecked && isAuthorizationChecked && isUpdatesChecked)) {
      setAcceptAllChecked(false);
    }
  }, [isTermsChecked, isUpdatesChecked, isAuthorizationChecked]);
*/

  function handleBox1Click() {
    setIsBox1Checked(!isBox1Checked);
    setIsBox1Error(false);
  }
  function handleBox2Click() {
    setIsBox2Checked(!isBox2Checked);
    setIsBox2Error(false);
  }
  function handleBox3Click() {
    setIsBox3Checked(!isBox3Checked);
    setIsBox3Error(false);
  }
  /*
  function handleAcceptAllClick() {
    setAcceptAllChecked(!isAcceptAllChecked);
  }
*/
  function handleTextbackSubmit() {
    if (!isBox1Checked) {
      setIsBox1Error(true);
    }
    if (!isBox2Checked) {
      setIsBox2Error(true);
    }
    if (!isBox3Checked) {
      setIsBox3Error(true);
    }
  }

  return (
    <TextBackTheme3 {...props}>
      <p className="p textback__label">We'll text you back in 10 sec</p>
      <TextBackWithLocales
        {...props}
        isTermsNotAccepted={!isBox1Checked || !isBox2Checked || !isBox3Checked}
        handleTextbackSubmit={() => handleTextbackSubmit()}
      />
      {AgreeItems()}
      <div className="p__tiny textback__checkbox-block">
        <div className="textback__checkbox-list">
          <div
            className={`textback__checkbox-item ${isBox1Error ? 'error' : ''}`}
          >
            <input
              className="textback__checkbox"
              type="checkbox"
              id="terms"
              name="terms"
              checked={isBox1Checked}
              onChange={() => handleBox1Click()}
            />
            <label className="textback__checkbox-label" htmlFor="terms">
              <span className="textback__checkbox-message">
                I accept nerdifyit.com{' '}
                <NavLink
                  className="link a__underline"
                  to="/terms-and-conditions"
                >
                  Terms
                </NavLink>{' '}
                and{' '}
                <NavLink className="link a__underline" to="/privacy-policy">
                  Privacy Policy
                </NavLink>
              </span>
            </label>
          </div>
          <div
            className={`textback__checkbox-item ${isBox2Error ? 'error' : ''}`}
          >
            <input
              className="textback__checkbox"
              type="checkbox"
              id="updates"
              name="updates"
              checked={isBox2Checked}
              onChange={() => handleBox2Click()}
            />
            <label className="textback__checkbox-label" htmlFor="updates">
              I want to receive order updates via SMS
            </label>
          </div>
          <div
            className={`textback__checkbox-item ${isBox3Error ? 'error' : ''}`}
          >
            <input
              className="textback__checkbox"
              type="checkbox"
              id="info"
              name="info"
              checked={isBox3Checked}
              onChange={() => handleBox3Click()}
            />
            <label className="textback__checkbox-label" htmlFor="info">
              I want to receive information regarding product and services via
              SMS
            </label>
          </div>
          {/*
          <div className="textback__checkbox-item">
            <input className="textback__checkbox" type="checkbox" id="authorization" name="authorization" checked={isAuthorizationChecked} onChange={() => setIsAuthorizationChecked(!isAuthorizationChecked)}/>
            <label className="textback__checkbox-label" htmlFor="authorization">I agree to receive authorization codes.</label>
          </div>
          <div className="textback__checkbox-item">
            <input className="textback__checkbox" type="checkbox" id="all" name="all" checked={isAcceptAllChecked} onChange={() => handleAcceptAllClick()}/>
            <label className="textback__checkbox-label" htmlFor="all">Select all.</label>
          </div>
*/}
        </div>
        SMS messages are sent by Nerdifyit. All messages frequency varies.
        <br className="desktop-only" /> Message and data rates may apply. Reply
        HELP for help and STOP to opt-out.
      </div>
    </TextBackTheme3>
  );
};

export const TextBack4 = (props) => (
  <TextBackTheme3 {...props}>
    <TextBackContent3 {...props} />
  </TextBackTheme3>
);

export const TextBack5 = (props) => (
  <TextBackTheme4 {...props}>
    <TextBackContent4 {...props} />
  </TextBackTheme4>
);

export const TextBack6 = (props) => (
  <TextBackTheme3 {...props}>
    <TextBackContent5 {...props} />
  </TextBackTheme3>
);

export const TextBack7 = (props) => (
  <TextBackTheme3 {...props}>{TextBackWithLocales(props)}</TextBackTheme3>
);

export const TextBack8 = (props) => (
  <TextBackTheme3 {...props}>
    <p className="p textback__label" />
    {TextBackWithLocales(props)}
    {AgreeItems()}
    {AgreeLinks1()}
  </TextBackTheme3>
);
